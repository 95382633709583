import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Dropdown, Layout, Menu, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import operations from 'apollo/operations';
import { User } from 'apollo/types';
import { concessionaireLogoUrl } from 'apollo/cache';
import { getNameInitials, isUserLogged } from 'utils/checkUserStatus';
import utils from 'utils/apiService/utils';
import styles from './TopBar.module.scss';

const { Header } = Layout;
const { logout } = utils;

const TopBar = () => {
  const logoUrl = useReactiveVar(concessionaireLogoUrl);

  const { pathname, hash } = useLocation();
  const [selectedOption, setSelectedOption] = useState<string[]>([pathname + hash]);

  const [getLoggedUser, { data: userData }] = useLazyQuery<{ getUser: User }>(
    operations.GET_USER,
    {
      variables: {
        cognitoId: localStorage.getItem('AccessToken') || '',
      },
    }
  );

  const dropwdownMenu = (
    <Menu className={styles.NavItems} selectedKeys={selectedOption}>
      <Menu.Item className={styles.NavItem} key="listPurchaseIntent">
        <Link to="/">
          <span>Mis solicitudes</span>
        </Link>
      </Menu.Item>
      <Menu.Item className={styles.NavItem} key="logout">
        <span tabIndex={0} role="link" onKeyPress={logout} onClick={logout}>
          Cerrar sesión
        </span>
      </Menu.Item>
    </Menu>
  );

  const navbarItems = () => (
    <div className={styles.NavBarItems}>
      {isUserLogged() && (
        <Dropdown overlay={dropwdownMenu}>
          {userData?.getUser ? (
            <Row align="middle" className={styles.UserContainer}>
              <div className={styles.UserLogoContainer}>
                <span className={styles.Initials}>
                  {userData?.getUser.name && getNameInitials(userData.getUser.name)}
                </span>
              </div>
            </Row>
          ) : (
            <Row align="middle" className={styles.UserContainer}>
              <div className={styles.UserLogoContainer}>
                <span className={styles.Initials}>
                  <LoadingOutlined />
                </span>
              </div>
            </Row>
          )}
        </Dropdown>
      )}
    </div>
  );

  useEffect(() => {
    if (isUserLogged()) {
      getLoggedUser();
    }
  }, []);

  useEffect(() => {
    setSelectedOption([pathname + hash]);
  }, [pathname, hash]);

  useEffect(() => {
    if (userData && userData.getUser) {
      localStorage.setItem('dealer', userData.getUser.dealer);
    }
  }, [userData]);

  return (
    <Header className={styles.Container}>
      {logoUrl && (
        <Row>
          <Link to="/">
            <img src={logoUrl} alt="logo" className={styles.Logo} />
          </Link>
        </Row>
      )}
      {navbarItems()}
    </Header>
  );
};

TopBar.defaultProps = {
  logoUrl: undefined,
};

export default TopBar;
