const months = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

const dateFormatter = (date: string): string => {
  const d = new Date(date);
  const formattedDay = `0${d.getDate()}`.slice(-2);
  return `${formattedDay} ${months[d.getMonth()]} ${d.getFullYear()}`;
};

export default dateFormatter;
