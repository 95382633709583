import React, { useState, useEffect } from 'react';
import { Row, Tabs } from 'antd';
import { useLazyQuery } from '@apollo/client';
import operations from 'apollo/operations';
import { GuestUser, User } from 'apollo/types';
import { InvitationTable, UsersTable } from 'components/user';
import { Button, PageHeader } from '@cloudcar-app/cloudcar-components';
import { useHistory } from 'react-router-dom';
import styles from './Users.module.scss';

type UsersTabs = 'createdUsers' | 'invitation';
const { TabPane } = Tabs;

const UserList: React.FC = () => {
  const history = useHistory();
  const [userList, setUserList] = useState<User[]>([]);
  const [guestUserList, setGuestUserList] = useState<GuestUser[]>([]);
  const [activeTab, setActiveTab] = useState<UsersTabs>('createdUsers');

  const [getAllUsers, { data, loading, refetch }] = useLazyQuery<{ listUser: User[] }>(
    operations.GET_ALL_USERS,
    { notifyOnNetworkStatusChange: true }
  );

  const [
    getAllGuestUsers,
    { data: guestUsersData, loading: guestUsersLoading, refetch: refetchGuestUsers },
  ] = useLazyQuery<{ listGuestUser: GuestUser[] }>(operations.GET_ALL_GUEST_USERS);

  const queryUsers = () => {
    const { host } = window.location;
    const concessionaire = host.split('.')[0];

    getAllUsers();
    getAllGuestUsers({
      variables: {
        concessionaireName: concessionaire === 'financing' ? 'CloudCar' : 'Tanner',
      },
    });
  };

  const settingGuestUserList = () => {
    if (guestUsersData && guestUsersData.listGuestUser) {
      setGuestUserList(guestUsersData.listGuestUser);
    }
  };

  useEffect(() => {
    queryUsers();
  }, []);

  useEffect(() => {
    if (data && data.listUser) {
      setUserList(data.listUser);
    }
  }, [data]);

  useEffect(() => {
    settingGuestUserList();
  }, [guestUsersData]);

  return (
    <>
      <Row justify="space-between" align="middle">
        <PageHeader title="Usuarios" />
        <Row>
          <Button onClick={() => history.push('/inviteUser')}>Invitar usuario</Button>
        </Row>
      </Row>
      <Tabs
        className={styles.Tabs}
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key as UsersTabs)}
        size="large"
      >
        <TabPane tab="Usuarios creados" key="createdUsers">
          <UsersTable loading={loading} data={userList} refetchUsers={refetch} />
        </TabPane>
        <TabPane tab="Invitaciones" key="invitation">
          <InvitationTable
            loading={guestUsersLoading}
            data={guestUserList}
            refetchUsers={refetchGuestUsers}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default UserList;
