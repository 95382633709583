import { gql } from '@apollo/client';

const POST_PERSONAL_INFORMATION = gql`
  mutation createCustomer($customerInput: CustomerInput) {
    createCustomer(customerInput: $customerInput) {
      email
      customerId
      createdAt
      financingInfo {
        income {
          Type
          Value
          Old
        }
        profile {
          Gender
          RelationStatus
          Studies
          ContractType
          IncomeType
          Profession
        }
      }
    }
  }
`;

const GET_CUSTOMER_INFO = gql`
  query customer {
    customer {
      createdAt
      name
      firstSurname
      secondSurname
      rut
      phone
      email
      birthday
      address
      addressNumber
      commune
      region
      country
      financingInfo {
        income {
          Type
          Value
          Old
        }
        profile {
          Gender
          RelationStatus
          Studies
          ContractType
          IncomeType
          Profession
        }
      }
    }
  }
`;

const GET_REGISTRY_REGIONS = gql`
  query registryRegions {
    registryRegions {
      id
      name
    }
  }
`;

const GET_REGISTRY_COMMUNES = gql`
  query registryCommunes($regionId: String!) {
    registryCommunes(regionId: $regionId) {
      id
      name
    }
  }
`;

const customerOperations = {
  GET_REGISTRY_REGIONS,
  GET_REGISTRY_COMMUNES,
  GET_CUSTOMER_INFO,
  POST_PERSONAL_INFORMATION,
};

export default customerOperations;
