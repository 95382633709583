import { gql } from '@apollo/client';

const GET_CAR = gql`
  query car {
    car {
      carId
      carMinInfoId
      createdAt
      brand
      model
      year
      version
      color
      price
      cit
      vehicleType
    }
  }
`;

const GET_REGISTRY_BRANDS = gql`
  query registryBrands {
    registryBrands {
      id
      name
    }
  }
`;

const GET_REGISTRY_MODELS = gql`
  query registryModels($brandId: String!) {
    registryModels(brandId: $brandId) {
      id
      name
    }
  }
`;

const GET_REGISTRY_VERSIONS = gql`
  query registryVersions($modelId: String!) {
    registryVersions(modelId: $modelId) {
      id
      name
    }
  }
`;
const CREATE_CAR = gql`
  mutation createCar($createCarInput: CreateCarInput) {
    createCar(createCarInput: $createCarInput) {
      carMinInfoId
      brand
      model
      year
      version
      color
      price
      cit
      carId
      createdAt
      vehicleType
    }
  }
`;

const carOperations = {
  GET_CAR,
  CREATE_CAR,
  GET_REGISTRY_BRANDS,
  GET_REGISTRY_MODELS,
  GET_REGISTRY_VERSIONS,
};

export default carOperations;
