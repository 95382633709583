export const isUserLogged = (): boolean => {
  if (localStorage.getItem('AccessToken') !== null) return true;
  return false;
};

export const getNameInitials = (name: string) => {
  if (name.split(' ').length > 1) {
    return `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}`;
  }
  return name[0].toUpperCase();
};
