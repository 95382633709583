import React, { FC } from 'react';
import { Modal, Row } from 'antd';
import {
  Scrollable,
  useMaxBreakpoint,
  Breakpoints,
} from '@cloudcar-app/cloudcar-components';
import styles from './CreditInsuranceDetailsModal.module.scss';

interface CreditInsuranceDetailModalProps {
  visible: boolean;
  handleOk: () => void;
}
const CreditInsuranceDetailModal: FC<CreditInsuranceDetailModalProps> = (
  props: CreditInsuranceDetailModalProps
) => {
  const { visible, handleOk } = props;
  const maxCurrentScreen = useMaxBreakpoint();
  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleOk}
      footer={null}
      width={maxCurrentScreen < Breakpoints.LG ? '80%' : '60%'}
    >
      <Scrollable scrollY className="CloudCarModal">
        <Row justify="center">
          <h1 className={styles.ModalTitle}>Seguros de tu financiamiento</h1>
        </Row>
        <Row justify="start" className={styles.ModalText}>
          <h2>Hospitalización</h2>
        </Row>
        <p className={styles.ModalParagraph}>
          Seguro que pagará hasta 8 cuotas del crédito en caso de que el asegurado ingrese
          a un servicio de salud, quedando hospitalizado desde 10 hasta 120 días.
        </p>
        <Row justify="start" className={styles.ModalText}>
          <h2>MultiAsistencia</h2>
        </Row>
        <p className={styles.ModalParagraph}>
          Producto pensado en cubrir diversas necesidades de nuestro cliente con servicios
          para el Cliente, Servicios Médicos, asistencia para el Hogar y asistencia para
          el Vehículo.
        </p>
      </Scrollable>
    </Modal>
  );
};

export default CreditInsuranceDetailModal;
