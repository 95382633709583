import { Maybe, Scalars, AllowedCurrency } from './generated/types';

export * from './generated/types';

type Nullable<T> = T | null;

export interface CustomSubStep {
  viewTitle?: string;
  title: string;
  subTitle?: string;
}

export interface StepInfo {
  title: string;
  dynamicTitle?: string;
  subtitle: string;
  type: string;
  route: string;
  queryName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>;
  subSteps?: Nullable<CustomSubStep[]>;
}

export type Price = {
  __typename?: 'Price';
  currency: AllowedCurrency;
  localCurrency: AllowedCurrency;
  localPrice: Scalars['Int'];
  price: Scalars['Int'];
};

export interface CarInfo {
  carMinInfoId: string;
  brand: string;
  model: string;
  version: string;
  year: number;
  color: string;
  description?: Maybe<string>;
  imageUrls?: Maybe<string[]>;
  technicalSpecSheetUrl?: Maybe<string>;
  hexColor?: Maybe<string>;
}

export interface CustomerForm {
  name: string;
  firstSurname: string;
  secondSurname?: string;
  rut: string;
  documentIdFront?: File;
  documentIdBack?: File;
  email: string;
  phone: string;
  birthday: string;
  address: string;
  addressNumber: string;
  commune: string;
  region: string;
  country: string;

  // opcionales para la solicitud del crédito
  income?: string;
  incomeType?: string;
  workSeniority?: string;
  gender?: string;
  maritalStatus?: string;
  studies?: string;
  contractType?: string;
  incomeVariability?: string;
  profession?: string;
}

export interface RegionsAndCommunesData {
  region: string;
  comunas: string[];
}

export interface CodeMessage {
  code: string;
  message?: string;
}

export interface CustomError {
  type: string;
  name: string;
  message?: string;
  codeMessage?: CodeMessage;
}

export interface ErrorQuery {
  errorData: CustomError;
  showErrorModal: boolean;
}

export enum CustomerDeliveryInformationType {
  CustomerBilling = 'BILLING',
  CustomerRegistration = 'REGISTRATION',
  CustomerDelivery = 'DELIVERY',
}

export interface LocationState {
  from?: { pathname?: string };
}

export enum PurchaseIntentTypes {
  Reservation = 'RESERVATION',
  Car = 'CAR',
  ContinueReservation = 'CONTINUERESERVATION',
  UsedCar = 'USEDCAR',
}

export enum CreditStates {
  Draft = 'DRAFT',
  Loading = 'LOADING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export interface PurchaseIntentFormValues {
  brand: string;
  model?: string;
  year: number;
  version?: string;
  color?: string;
  price: number;
  cit?: string;
  rut: string;
  vehicleType?: string;
}
