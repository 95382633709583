/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useState } from 'react';
import { Col, Form, FormInstance, Select as BaseSelect, Row, Popover } from 'antd';
import { Input, Select, useMaxBreakpoint } from '@cloudcar-app/cloudcar-components';
import currencyFormatter from 'utils/currencyFormatter';
import currencyValueValidator from 'utils/currencyValueValidator';
import MessageError from 'utils/message.errors';
import getScreenSpacings from 'utils/getScreenSpacings';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import styles from './FinancialInformationForm.module.scss';

const { Option } = BaseSelect;

interface SubFormProps {
  form: FormInstance;
}

const FinancialInformationForm: FC<SubFormProps> = (props: SubFormProps) => {
  const { form } = props;
  const maxScreen = useMaxBreakpoint();
  const screenSpacings = getScreenSpacings(maxScreen);
  const [selectedIncomeType, setSelectedIncomeType] = useState('1');

  const incomeTypeOptions = [
    { label: 'Dependiente', value: '1' },
    { label: 'Independiente', value: '2' },
  ];

  const contractTypeOptions = [
    { label: 'Indefinido', value: '1' },
    { label: 'Fijo', value: '2' },
  ];

  const incomeVariabilityOptions = [
    { label: 'Fijo', value: '1' },
    { label: 'Variable', value: '2' },
  ];

  const handleChangeIncome = (income: string): string => {
    const unformattedValue = income.replace(/\$\s?|(\.*)/g, '');
    if (!Number.isNaN(unformattedValue)) {
      const formatted: string = currencyFormatter.format(Number(unformattedValue));
      form.setFieldsValue({ income: formatted });
      return unformattedValue;
    }
    form.setFieldsValue({
      income: unformattedValue,
    });
    return unformattedValue;
  };

  return (
    <Row justify="start" gutter={[screenSpacings.L, screenSpacings.L]}>
      <Col xs={24} sm={8}>
        <Form.Item
          name="incomeType"
          className={styles.FormInput}
          rules={[
            {
              required: true,
              message: MessageError.emptyFieldsMessages.incomeType,
            },
          ]}
        >
          <Select
            label={
              (
                <Row>
                  <Col>Fuente de ingresos </Col>
                  <Popover
                    overlayClassName={styles.Popover}
                    content="Dependiente, Independiente."
                    className={styles.Popover}
                  >
                    <QuestionCircleTwoTone className={styles.InfoCircleTwoTone} />
                  </Popover>
                </Row>
              ) as any
            }
            placeholder="Fuente de ingresos"
            required
            // compact
            onChange={(value: string) => setSelectedIncomeType(value)}
          >
            {incomeTypeOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {selectedIncomeType === '2' && (
        <Col xs={24} sm={8}>
          <Form.Item
            name="profession"
            className={styles.FormInput}
            rules={[
              {
                required: true,
                message: MessageError.emptyFieldsMessages.incomeType,
              },
            ]}
          >
            <Select
              label={
                (
                  <Row>
                    <Col>Tipo de trabajador </Col>
                    <Popover
                      overlayClassName={styles.Popover}
                      content="Seleccione el tipo de trabajador. Las últimas 4 opciones corresponden a tipo transportista."
                      className={styles.Popover}
                    >
                      <QuestionCircleTwoTone className={styles.InfoCircleTwoTone} />
                    </Popover>
                  </Row>
                ) as any
              }
              placeholder="Tipo de trabajador"
              required
              // compact
            >
              {[
                {
                  label: 'Honorario',
                  value: '1',
                },
                {
                  label: 'Comerciante',
                  value: '2',
                },
                {
                  label: 'T_PRIVADO_C_CONTRATO',
                  value: '3',
                },
                {
                  label: 'T_PRIVADO_S_CONTRATO',
                  value: '4',
                },
                {
                  label: 'T_PUBLICO_INTER',
                  value: '5',
                },
                {
                  label: 'T_PUBLICO_URBANO',
                  value: '6',
                },
              ].map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      <Col xs={24} sm={8}>
        <Form.Item
          name="incomeVariability"
          className={styles.FormInput}
          rules={[
            {
              required: true,
              message: MessageError.emptyFieldsMessages.incomeVariability,
            },
          ]}
        >
          <Select
            label={
              (
                <Row>
                  <Col>Tipo de ingresos </Col>
                  <Popover
                    overlayClassName={styles.Popover}
                    content="Fijo o Variable."
                    className={styles.Popover}
                  >
                    <QuestionCircleTwoTone className={styles.InfoCircleTwoTone} />
                  </Popover>
                </Row>
              ) as any
            }
            placeholder="Tipo de ingresos"
            required
            // compact
          >
            {incomeVariabilityOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={8}>
        <Form.Item
          name="contractType"
          className={styles.FormInput}
          rules={[
            {
              required: true,
              message: MessageError.emptyFieldsMessages.contractType,
            },
          ]}
        >
          <Select
            label={
              (
                <Row>
                  <Col>Tipo de contrato </Col>
                  <Popover
                    overlayClassName={styles.Popover}
                    content="Indefinido o Contrato a plazo fijo."
                    className={styles.Popover}
                  >
                    <QuestionCircleTwoTone className={styles.InfoCircleTwoTone} />
                  </Popover>
                </Row>
              ) as any
            }
            placeholder="Tipo de contrato"
            required
            // compact
          >
            {contractTypeOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={8}>
        <Form.Item
          name="income"
          className={styles.FormInput}
          rules={[
            {
              required: true,
              message: MessageError.emptyFieldsMessages.income,
            },
            {
              validator: currencyValueValidator,
            },
          ]}
        >
          <Input
            label={
              (
                <Row>
                  <Col>Renta líquida </Col>
                  <Popover
                    overlayClassName={styles.Popover}
                    content="Ingresos promedio recibido en liquidación de sueldo ultimo 3 meses. En caso de crédito instantáneo (independiente), ingresar 1."
                    className={styles.Popover}
                  >
                    <QuestionCircleTwoTone className={styles.InfoCircleTwoTone} />
                  </Popover>
                </Row>
              ) as any
            }
            placeholder="Renta líquida"
            onChange={(e) => handleChangeIncome(e.target.value)}
            required
            // compact
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default FinancialInformationForm;
