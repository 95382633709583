import React, { FC, useEffect, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { InputNumber, Slider, useMaxBreakpoint } from '@cloudcar-app/cloudcar-components';
import { ProductInsurance, FinancingProducts } from 'apollo/types';
import { CreditInsuranceDetailsModal } from 'components/credit';
import { CheckWhite } from 'assets/icons';
import getScreenSpacings from 'utils/getScreenSpacings';
import styles from './CreditForm.module.scss';

interface CreditFormProps {
  downPayment: number;
  setDownPayment: React.Dispatch<React.SetStateAction<number>>;
  paymentsNumber: number;
  setPaymentsNumber: React.Dispatch<React.SetStateAction<number>>;
  price: number;
  selectedInsurances?: ProductInsurance[];
  setSelectedInsurances: React.Dispatch<
    React.SetStateAction<ProductInsurance[] | undefined>
  >;
  insuranceOptions: ProductInsurance[];
  selectedProduct: FinancingProducts;
}

const CreditForm: FC<CreditFormProps> = (props: CreditFormProps) => {
  const {
    downPayment,
    setDownPayment,
    paymentsNumber,
    setPaymentsNumber,
    selectedInsurances,
    setSelectedInsurances,
    price,
    insuranceOptions,
    selectedProduct,
  } = props;
  const maxScreen = useMaxBreakpoint();
  const screenSpacings = getScreenSpacings(maxScreen);

  const { downpaymentPercentages, paymentsNumber: paymentsNumberRange } = selectedProduct;

  const getPaymentsNumberOptionsArray = () => {
    const getMinPaymentsNumber = () => {
      if (paymentsNumberRange[0] < 12) {
        return 12;
      }
      return paymentsNumberRange[0] - (paymentsNumberRange[0] % 12) + 12;
    };
    const minPaymentsNumber = getMinPaymentsNumber();
    let paymentsNumberOptionsArray = Array.from(
      { length: (paymentsNumberRange[1] - minPaymentsNumber) / 12 + 1 },
      (_, i) => i * 12 + minPaymentsNumber
    );
    if (minPaymentsNumber > paymentsNumberRange[0]) {
      paymentsNumberOptionsArray = [
        paymentsNumberRange[0],
        ...paymentsNumberOptionsArray,
      ];
    }
    return paymentsNumberOptionsArray;
  };

  const [paymentsNumberOptions, setPaymentsNumberOptions] = useState<number[]>(
    getPaymentsNumberOptionsArray()
  );
  const minDownPaymentPercentage: number = downpaymentPercentages[0];
  const maxDownPaymentPercentage: number = downpaymentPercentages[1];

  const [creditInsuranceDetailModalVisible, setCreditInsuranceDetailModalVisible] =
    useState(false);

  const handleSelectFinancingInsurances = (insuranceOption: ProductInsurance) => {
    const currentSelectedInsurances = [...(selectedInsurances || [])];
    const insuranceIndex = currentSelectedInsurances.findIndex(
      (insurance) => insurance.id === insuranceOption.id
    );
    const isInsuranceSelected = insuranceIndex > -1;

    const isInsuranceRequired = insuranceOption.required;

    if (isInsuranceSelected && !isInsuranceRequired) {
      const newSelectedInsurances = currentSelectedInsurances.filter(
        (insurance) => insurance.id !== insuranceOption.id
      );
      setSelectedInsurances(newSelectedInsurances);
    } else if (!isInsuranceRequired) {
      setSelectedInsurances([insuranceOption, ...currentSelectedInsurances]);
    }
  };

  useEffect(() => {
    if (price > 0) {
      setPaymentsNumberOptions(getPaymentsNumberOptionsArray());
      setPaymentsNumber(getPaymentsNumberOptionsArray()[0]);
      setDownPayment(Math.round((minDownPaymentPercentage / 100) * price * 1.1));
    }
  }, [price, selectedProduct]);

  return (
    <div className={styles.FullWidth}>
      <CreditInsuranceDetailsModal
        visible={creditInsuranceDetailModalVisible}
        handleOk={() => setCreditInsuranceDetailModalVisible(false)}
      />
      <Row justify="start" gutter={screenSpacings.XL} className={styles.InputContainer}>
        <Col xs={24} md={12}>
          <Row justify="start">
            <h2 className={styles.InputLabel}>Monto del pie de pago</h2>
          </Row>
          <Row
            justify="start"
            className={price <= 0 ? styles.LoadingPaymentInputContainer : ''}
          >
            {price <= 0 && (
              <Spin indicator={<LoadingOutlined className={styles.Spin} spin />} />
            )}
            <Col span={24}>
              <div className={styles.PaymentInputContainer}>
                <InputNumber
                  min={Number.parseInt(
                    ((minDownPaymentPercentage / 100) * price).toFixed(0),
                    10
                  )}
                  max={Number.parseInt(
                    ((maxDownPaymentPercentage / 100) * price).toFixed(0),
                    10
                  )}
                  formatter={(value) =>
                    `$ ${value || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                  }
                  parser={(value) => (value || '0').replace(/\$\s?|(\.*)/g, '')}
                  value={downPayment}
                  onChange={(newValue: number | string | null) =>
                    setDownPayment(Number.parseInt(newValue as string, 10))
                  }
                  required
                  compact
                />
              </div>
              <Slider
                step={1}
                marks={{
                  [`${Number.parseInt(
                    ((minDownPaymentPercentage / 100) * price).toFixed(0),
                    10
                  )}`]: {
                    label: (
                      <>
                        <Row>{minDownPaymentPercentage}%</Row>
                        <Row>mín.</Row>
                      </>
                    ),
                  },
                  [`${Number.parseInt(
                    ((maxDownPaymentPercentage / 100) * price).toFixed(0),
                    10
                  )}`]: {
                    label: (
                      <>
                        <Row>{maxDownPaymentPercentage}%</Row>
                        <Row>máx.</Row>
                      </>
                    ),
                  },
                }}
                min={
                  minDownPaymentPercentage >= 5
                    ? Number.parseInt(
                        (((minDownPaymentPercentage - 5) / 100) * price).toFixed(0),
                        10
                      )
                    : Number.parseInt(
                        ((minDownPaymentPercentage / 100) * price).toFixed(0),
                        10
                      )
                }
                max={
                  maxDownPaymentPercentage <= 95
                    ? Number.parseInt(
                        (((maxDownPaymentPercentage + 5) / 100) * price).toFixed(0),
                        10
                      )
                    : Number.parseInt(
                        ((maxDownPaymentPercentage / 100) * price).toFixed(0),
                        10
                      )
                }
                value={downPayment}
                onChange={(newValue: number | string) => {
                  if (
                    typeof newValue === 'number' &&
                    newValue >= (minDownPaymentPercentage / 100) * price &&
                    newValue <= (maxDownPaymentPercentage / 100) * price
                  ) {
                    setDownPayment(newValue);
                  } else if (
                    typeof newValue === 'string' &&
                    Number.parseInt(newValue, 10) >=
                      (minDownPaymentPercentage / 100) * price &&
                    Number.parseInt(newValue, 10) <=
                      (maxDownPaymentPercentage / 100) * price
                  ) {
                    setDownPayment(Number.parseInt(newValue, 10));
                  }
                }}
                tipFormatter={null}
                className={styles.Slider}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={12}>
          <Row justify="start">
            <h2 className={styles.InputLabel}>Número de cuotas</h2>
          </Row>
          <Row justify="start" gutter={[screenSpacings.L, screenSpacings.L]}>
            {paymentsNumberOptions.map((option) => (
              <Col span={6} key={option} onClick={() => setPaymentsNumber(option)}>
                <div
                  className={[
                    styles.PaymentsNumberOption,
                    paymentsNumber === option ? styles.PaymentsNumberOptionActive : '',
                  ].join(' ')}
                >
                  {option}
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      {/* <Row justify="start">
        <Col xs={24}>
          <h2 className={styles.InputLabel}>
            Seguros para el financiamiento{' '}
            <Button
              className={styles.InfoButton}
              mode="text"
              icon={<QuestionCircleTwoTone className={styles.InfoCircleTwoTone} />}
              onClick={() => setCreditInsuranceDetailModalVisible(true)}
            >
              ¿Qué significa cada seguro?
            </Button>
          </h2>
        </Col>
      </Row> */}
      <Row
        justify="start"
        gutter={[screenSpacings.L, screenSpacings.L]}
        className={styles.InsuranceContainer}
      >
        {Array.from(new Set(insuranceOptions)).map((insuranceOption, index) =>
          insuranceOption && insuranceOption.show ? (
            <Col xs={12} lg={8} xxl={6} key={insuranceOption.id}>
              <div
                className={[
                  styles.RadioButton,
                  insuranceOption.required ? styles.RequiredInsurance : '',
                  selectedInsurances &&
                  selectedInsurances.findIndex(
                    (insurance) => insurance.id === insuranceOption.id
                  ) > -1
                    ? styles.ActiveRadioButton
                    : '',
                ].join(' ')}
                onClick={() => handleSelectFinancingInsurances(insuranceOption)}
                onKeyPress={() => handleSelectFinancingInsurances(insuranceOption)}
                role="button"
                tabIndex={0}
              >
                <Row justify="space-between" align="middle">
                  <Col>
                    <p className={styles.InsuranceName}>
                      {insuranceOptions[index].name
                        .replace('Seguro de', '')
                        .replace('Seguro', '')}
                    </p>
                  </Col>
                  <Col>
                    <div
                      className={[
                        styles.Circle,
                        selectedInsurances &&
                        selectedInsurances
                          .map((selectedInsurance) => selectedInsurance.id)
                          .includes(insuranceOption.id)
                          ? styles.ActiveCircle
                          : '',
                        insuranceOption.required ? styles.DisabledCircle : '',
                      ].join(' ')}
                    >
                      <img src={CheckWhite} alt="active" className={styles.ActiveIcon} />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          ) : null
        )}
      </Row>
    </div>
  );
};

CreditForm.defaultProps = {
  selectedInsurances: undefined,
};

export default CreditForm;
