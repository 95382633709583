import React, { FC, useEffect } from 'react';
import { Col, Form, FormInstance, Row, Select as BaseSelect } from 'antd';
import {
  Input,
  InputNumber,
  PriceInput,
  useMaxBreakpoint,
  Select,
  // Switch,
} from '@cloudcar-app/cloudcar-components';
import { NextButton } from 'components/ui';
import getScreenSpacings from 'utils/getScreenSpacings';
import MessageError from 'utils/message.errors';
import rutFormValidator from 'utils/rutFormValidator';
import { useLazyQuery, useQuery } from '@apollo/client';
import { RegistryRow } from 'apollo/types';
import operations from 'apollo/operations';
import styles from '../PurchaseIntentForm.module.scss';

interface SpecificFormProps {
  createPurchaseIntentLoading: boolean;
  handleChangeRut: (rut: string) => string;
  form: FormInstance<unknown>;
  // legalEntity: boolean;
  // setLegalEntity: (value: boolean) => void;
}

const TannerForm: FC<SpecificFormProps> = (props: SpecificFormProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    createPurchaseIntentLoading,
    handleChangeRut,
    form,
    // legalEntity,
    // setLegalEntity,
  } = props;
  const [selectedBrand, setSelectedBrand] = React.useState<null | number>(null);
  const [selectedModel, setSelectedModel] = React.useState<null | number>(null);
  const [registryBrands, setRegistryBrands] = React.useState<RegistryRow[]>([]);
  const [registryModels, setRegistryModels] = React.useState<RegistryRow[]>([]);
  const [registryVersions, setRegistryVersions] = React.useState<RegistryRow[]>([]);

  const { Option } = BaseSelect;

  const maxScreen = useMaxBreakpoint();
  const screenSpacings = getScreenSpacings(maxScreen);

  const { data, loading } = useQuery<{ registryBrands: RegistryRow[] }>(
    operations.GET_REGISTRY_BRANDS,
    {
      fetchPolicy: 'network-only',
    }
  );

  const [getRegistryModels, { data: modelData, loading: modelLoading }] = useLazyQuery<
    { registryModels: RegistryRow[] },
    { brandId: string }
  >(operations.GET_REGISTRY_MODELS);

  const [getRegistryVersions, { data: versionData, loading: versionLoading }] =
    useLazyQuery<{ registryVersions: RegistryRow[] }, { modelId: string }>(
      operations.GET_REGISTRY_VERSIONS
    );

  const handleRegistryBrandsData = () => {
    if (data && data.registryBrands) {
      setRegistryBrands(data.registryBrands);
    }
  };

  const handleRegistryModelsData = () => {
    if (modelData && modelData.registryModels) {
      setRegistryModels(modelData.registryModels);
    }
  };

  const handleRegistryVersionsData = () => {
    if (versionData && versionData.registryVersions) {
      setRegistryVersions(versionData.registryVersions);
    }
  };

  const handleRegistryModelsCall = async () => {
    if (selectedBrand) {
      await getRegistryModels({
        variables: {
          brandId: selectedBrand?.toString(),
        },
      });
    }
  };

  const handleRegistryVersionsCall = async () => {
    if (selectedModel) {
      await getRegistryVersions({
        variables: {
          modelId: selectedModel?.toString(),
        },
      });
    }
  };

  const handleSelectedRegistryData = () => {
    if (registryVersions && registryVersions.length) {
      form.setFieldsValue({
        versionId: registryVersions[0].id,
        modelId: selectedModel,
        brandId: selectedBrand,
      });
    }
  };

  useEffect(() => {
    handleSelectedRegistryData();
  }, [registryVersions]);

  useEffect(() => {
    handleRegistryBrandsData();
  }, [data]);

  useEffect(() => {
    handleRegistryModelsData();
  }, [modelData]);

  useEffect(() => {
    handleRegistryVersionsData();
  }, [versionData]);

  useEffect(() => {
    handleRegistryModelsCall();
  }, [selectedBrand]);

  useEffect(() => {
    handleRegistryVersionsCall();
  }, [selectedModel]);

  return (
    <>
      <Row gutter={[screenSpacings.L, screenSpacings.L]}>
        {/* <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Row gutter={12} className={styles.FinancingSwitch}>
                <Col>
                  <p>Persona jurídica</p>
                </Col>
                <Col>
                  <Switch
                    checked={legalEntity}
                    onChange={() => setLegalEntity(!legalEntity)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col> */}
        <Col xs={24} sm={8}>
          <Form.Item
            name="brand"
            className={styles.FormInput}
            rules={[
              {
                required: true,
                message: MessageError.emptyFieldsMessages.brand,
              },
            ]}
          >
            <Select
              disabled={loading}
              showSearch
              loading={loading}
              placeholder="Marca"
              onChange={(value: string) => {
                setSelectedBrand(parseInt(value.split('#')[0], 10));
                form.resetFields(['model', 'version']);
              }}
            >
              {registryBrands.map((vehicleBrand) => (
                <Option
                  key={`${vehicleBrand.id}#${vehicleBrand.name}`}
                  value={`${vehicleBrand.id}#${vehicleBrand.name}`}
                >
                  {vehicleBrand.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            name="model"
            className={styles.FormInput}
            rules={[
              {
                required: true,
                message: MessageError.emptyFieldsMessages.model,
              },
            ]}
          >
            <Select
              showSearch
              disabled={modelLoading}
              loading={modelLoading}
              placeholder="Modelo"
              onChange={(value: string) => {
                setSelectedModel(parseInt(value.split('#')[0], 10));
                form.setFieldsValue({ version: undefined });
              }}
            >
              {registryModels.map((vehicleModel) => (
                <Option
                  key={`${vehicleModel.id}#${vehicleModel.name}`}
                  value={`${vehicleModel.id}#${vehicleModel.name}`}
                >
                  {vehicleModel.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col xs={24} sm={8}>
          <Form.Item
            name="version"
            className={styles.FormInput}
            rules={[
              {
                required: true,
                message: MessageError.emptyFieldsMessages.version,
              },
            ]}
          >
            <Select
              showSearch
              disabled={versionLoading}
              loading={versionLoading}
              placeholder="Versión"
              onChange={(value: string) =>
                setSelectedVersion(parseInt(value.split('#')[0], 10))
              }
            >
              {registryVersions.map((vehicleVersion) => (
                <Option
                  key={`${vehicleVersion.id}#${vehicleVersion.name}`}
                  value={`${vehicleVersion.id}#${vehicleVersion.name}`}
                >
                  {vehicleVersion.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col> */}
        <Col xs={24} sm={8}>
          <Form.Item
            name="year"
            className={styles.FormInput}
            rules={[
              {
                required: true,
                message: MessageError.emptyFieldsMessages.year,
              },
              {
                type: 'number',
                message: MessageError.invalidFormatFieldsMessages.year,
              },
            ]}
          >
            <InputNumber
              label="Año"
              placeholder="Año"
              required
              compact
              className={styles.Input}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            name="price"
            className={styles.FormInput}
            rules={[
              {
                required: true,
                message: MessageError.emptyFieldsMessages.price,
              },
              {
                type: 'number',
                message: MessageError.invalidFormatFieldsMessages.price,
              },
            ]}
          >
            <PriceInput placeholder="Precio  de venta" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            name="rut"
            className={styles.FormInput}
            rules={[
              { required: true, message: MessageError.emptyFieldsMessages.rut },
              {
                validator: rutFormValidator,
              },
            ]}
          >
            <Input
              label="Rut cliente"
              placeholder="Rut cliente"
              extra="*Escribe tu rut sin puntos ni guión."
              onChange={(e) => handleChangeRut(e.target.value)}
              required
              compact
            />
          </Form.Item>
        </Col>
        {/* <Col xs={24} sm={8}>
          <Alert
            showIcon
            icon={
              <div className={styles.AlertIcon}>
                <span className={styles.SuccessIcon}>
                  <InfoCircleOutlined />
                </span>
              </div>
            }
            message="Recuerda que el rut debe corresponder a persona natural."
            className={styles.LoadingModal}
          />
        </Col> */}
      </Row>
      <NextButton
        htmlType="submit"
        loading={createPurchaseIntentLoading}
        disabled={createPurchaseIntentLoading || versionLoading}
      />
    </>
  );
};

export default TannerForm;
