/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import utils from './utils';
import {
  AxiosInstanceResponse,
  LoginRequestData,
  GetCarDataWithCitBody,
  GetCarDataWithCitResponse,
  VehicleData,
  SignUpRequestData,
} from './types';

const { axiosInstance, operationCostAxiosInstance } = utils;

const loginRequest = async (data: LoginRequestData): Promise<AxiosInstanceResponse> => {
  const response = await axiosInstance({
    method: 'post',
    url: '/financing-lite/login',
    data,
    validateStatus: (status) => status >= 200 && status < 400,
  });
  return { response: response.data, status: response.status };
};

const signUpRequest = async (data: SignUpRequestData): Promise<AxiosInstanceResponse> => {
  const response = await axiosInstance({
    method: 'post',
    url: '/financing-lite/signup',
    data,
    validateStatus: (status) => status >= 200 && status < 400,
  });
  return { response: response.data, status: response.status };
};

const forgotPasswordRequest = async (
  username: string
): Promise<AxiosInstanceResponse> => {
  const response = await axiosInstance({
    method: 'post',
    url: '/financing-lite/forgotPassword',
    data: { username },
    validateStatus: (status) => status >= 200 && status < 400,
  });
  return { response: response.data, status: response.status };
};

const confirmForgotPasswordRequest = async ({
  username,
  confirmationCode,
  password,
}: {
  username: string;
  confirmationCode: string;
  password: string;
}): Promise<AxiosInstanceResponse> => {
  const response = await axiosInstance({
    method: 'post',
    url: '/financing-lite/confirmForgotPassword',
    data: { username, confirmationCode, password },
    validateStatus: (status) => status >= 200 && status < 400,
  });
  return { response: response.data, status: response.status };
};

const getCarDataWithCit = async (
  body: GetCarDataWithCitBody
): Promise<GetCarDataWithCitResponse> => {
  const response = await operationCostAxiosInstance({
    method: 'POST',
    url: '/carCitData',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    data: body,
    validateStatus: (status) => status === 200,
  });
  return {
    response: response.data as VehicleData,
    status: response.status,
  };
};

const services = {
  confirmForgotPasswordRequest,
  forgotPasswordRequest,
  signUpRequest,
  loginRequest,
  getCarDataWithCit,
};

export default services;
