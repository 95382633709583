const config = {
  useMocks: process.env.REACT_APP_USE_MOCKS === 'true',
  apiUrl: process.env.REACT_APP_API_URL,
  apiRestUrl: process.env.REACT_APP_REST_API_URL,
  apiOperationCostUrl: process.env.REACT_APP_OPERATION_COST_API_URL,
  webhookUrl: process.env.REACT_APP_SLACK_WEBHOOK_URL,
  logLevel: process.env.REACT_APP_LOG_LEVEL || 'log',
};

export default config;
