import { gql } from '@apollo/client';

const GET_FINANCING_PRODUCTS = gql`
  query requestFinancingProducts($financingProductsInput: FinancingProductsInput!) {
    requestFinancingProducts(financingProductsInput: $financingProductsInput) {
      vfmg
      id
      name
      downpaymentPercentages
      paymentsNumber
      insurance {
        show
        id
        name
        required
      }
      expenses {
        id
        name
      }
    }
  }
`;

const GET_FINANCING_SIMULATION = gql`
  query requestCreditSimulation($financingInput: FinancingInput!) {
    requestCreditSimulation(financingInput: $financingInput) {
      FinancingAmount
      InterestAmount
      OperationalCost
      Cae
      DealerCommission
      InterestRate
      Fees {
        QuoteNumber
        QuoteAmount
        CapitalAmount
        CapitalBalance
        DueDate
        InterestAmount
        VfmgIndicator
      }
      Insurance {
        show
        id
        name
        amount
        required
      }
    }
  }
`;

const REQUEST_FINANCING_PRE_APPROVAL = gql`
  mutation requestPreApprovedCredit {
    requestPreApprovedCredit {
      financing {
        financingStatus
      }
    }
  }
`;

const financingOperations = {
  GET_FINANCING_PRODUCTS,
  GET_FINANCING_SIMULATION,
  REQUEST_FINANCING_PRE_APPROVAL,
};

export default financingOperations;
