import React, { FC, useEffect, useState } from 'react';
import { Layout as LayoutContainer, Col, Row, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ErrorModal } from '@cloudcar-app/cloudcar-components';
import { useQuery, useReactiveVar } from '@apollo/client';
import { ErrorQuery } from 'apollo/types';
import operations from 'apollo/operations';
import {
  concessionaireLogoUrl,
  errorData,
  isAppLoading,
  isStylesConfigurationLoading,
  logged,
  showErrorModal,
} from 'apollo/cache';
import ErrorLogger from 'error/ErrorLogger';
import { FullPageAnimation, TopBar } from 'components/ui';
import { isUserLogged } from 'utils/checkUserStatus';
import CarAnimation from 'assets/animations/53192-car-animated.json';
import Footer from 'assets/images/cloudcar-footer.svg';
import styles from './Layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: FC<LayoutProps> = (props: LayoutProps) => {
  const { children } = props;
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const isLoading = useReactiveVar(isAppLoading);
  const areStylesLoading = useReactiveVar(isStylesConfigurationLoading);
  const logoUrl = useReactiveVar(concessionaireLogoUrl);

  const { data: errorQueryResponse } = useQuery<ErrorQuery>(operations.GET_ERROR);

  Spin.setDefaultIndicator(<LoadingOutlined className={styles.SpinLarge} spin />);

  message.config({ duration: 0.5, maxCount: 1 });

  const sharedLayout = (
    <>
      <ErrorLogger />
      {errorQueryResponse && errorQueryResponse.showErrorModal && (
        <ErrorModal
          visible={errorModalVisible}
          onClose={() => {
            setErrorModalVisible(false);
            showErrorModal(false);
            errorData(null);
          }}
          errorData={{
            ...errorQueryResponse.errorData,
            message: errorQueryResponse.errorData.message || '',
          }}
        />
      )}
    </>
  );

  useEffect(() => {
    if (errorQueryResponse && errorQueryResponse.showErrorModal) {
      setErrorModalVisible(true);
    }
  }, [errorQueryResponse]);

  useEffect(() => {
    if (localStorage.getItem('IdToken') !== null) {
      logged(true);
    } else {
      isAppLoading(false);
    }
  }, []);

  return (
    <div className={styles.Container}>
      {sharedLayout}
      {isLoading || areStylesLoading ? (
        <FullPageAnimation animation={CarAnimation} />
      ) : (
        <LayoutContainer className={styles.LayoutContainer}>
          {logoUrl && (
            <div className={styles.TopBarContainer}>
              <TopBar />
            </div>
          )}
          <Row className={styles.ContainerMainRow}>
            <Col span={24} className={styles.MainColumn}>
              {children}
            </Col>
          </Row>
          {!isUserLogged() && (
            <Row justify="center" className={styles.Footer}>
              <a href="https://cloudcar.cl">
                <img src={Footer} alt="Powered by CloudCar" />
              </a>
            </Row>
          )}
        </LayoutContainer>
      )}
    </div>
  );
};

export default Layout;
