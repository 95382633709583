import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ApolloWrapperProvider from 'apollo/Provider';
import Routes from 'routes';
import StateManager from 'state';

const App: FC = () => (
  <ApolloWrapperProvider>
    <BrowserRouter>
      <StateManager />
      <Routes />
    </BrowserRouter>
  </ApolloWrapperProvider>
);

export default App;
