/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMaxBreakpoint } from '@cloudcar-app/cloudcar-components';
import { Row, Col } from 'antd';
import _ from 'lodash';
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  stepList,
  currentSubStep,
  listPrice,
  selectedPaymentMethod,
  loadingCredit,
  selectedFinancialName,
} from 'apollo/cache';
import {
  PaymentMethodInput,
  AllowedPaymentMethodType,
  AllowedCurrency,
  LoanType,
  CreditStates,
  FinancingProducts,
  ProductInsurance,
  LitePurchaseIntent,
} from 'apollo/types';
import { NextButton } from 'components/ui';
import { CreditPaymentMethod, CreditFinancingConditions } from 'components/credit';
import getStepIndexByPathname from 'utils/getStepIndexByPathname';
import usePurchaseOptionsDataHook from 'dataHooks/usePurchaseOptionsDataHook';
import { CheckWhite } from 'assets/icons';
import getScreenSpacings from 'utils/getScreenSpacings';
import operations from 'apollo/operations';
import styles from './PurchaseOptions.module.scss';

const PurchaseOptions: FC = (): JSX.Element => {
  const history = useHistory();
  const maxScreen = useMaxBreakpoint();
  const screenSpacings = getScreenSpacings(maxScreen);

  const financingName = useReactiveVar(selectedFinancialName);
  const [price, setPrice] = useState<number>(0);
  const [downPayment, setDownPayment] = useState<number>(0);
  const [paymentsNumber, setPaymentsNumber] = useState<number>(12);
  const [creditType, setCreditType] = useState<LoanType | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<FinancingProducts>();
  const [insurnaceOptions, setInsuranceOptions] = useState<ProductInsurance[]>([]);
  const [selectedInsurances, setSelectedInsurances] = useState<ProductInsurance[]>();
  const [showFinancingConditionsModal, setShowFinancingConditionsModal] =
    useState<boolean>(false);

  const steps = useReactiveVar(stepList);
  const currentStepIndex = getStepIndexByPathname(history.location.pathname, steps);
  const currentSubStepIndex = useReactiveVar(currentSubStep);
  const purchaseItemListPrice = useReactiveVar(listPrice);

  const { data: litePurchaseIntentData } = useQuery<{
    litePurchaseIntent: LitePurchaseIntent;
  }>(operations.GET_BASE_LITE_PURCHASE_INTENT);

  const {
    data,
    createPaymentMethod,
    refetchPurchaseItemInfoQuery,
    createPaymentMethodLoading,
  } = usePurchaseOptionsDataHook();

  const next = async () => {
    if (currentSubStepIndex === -1) {
      currentSubStep(currentSubStepIndex + 1);
    } else {
      let paymentMethodInput: PaymentMethodInput = {
        type: AllowedPaymentMethodType.WithFinancing,
        currency: 'CLP' as AllowedCurrency,
      };
      paymentMethodInput = {
        ...paymentMethodInput,
        financing: {
          downPayment,
          paymentsNumber,
          loanType: creditType as LoanType,
          insurance: (selectedInsurances || []).map((insurance) => ({
            id: insurance.id,
            name: insurance.name,
          })),
          expenses: selectedProduct
            ? selectedProduct.expenses.map((expense) => _.omit(expense, '__typename'))
            : [],
          productId: selectedProduct && selectedProduct.id,
          income: 0,
        },
      };

      await createPaymentMethod({
        variables: {
          paymentMethodInput,
        },
        refetchQueries: [
          {
            query: refetchPurchaseItemInfoQuery,
          },
        ],
      });
      currentSubStep(-1);
      loadingCredit(CreditStates.Draft);
    }
  };

  const handleClickPaymentMethodOption = (key: string) => {
    selectedPaymentMethod(key as AllowedPaymentMethodType);
    if ((key as AllowedPaymentMethodType) === AllowedPaymentMethodType.WithFinancing) {
      currentSubStep(-1);
      const newActiveSteps = [...steps];
      newActiveSteps[currentStepIndex] = {
        ...steps[currentStepIndex],
        subSteps: [
          {
            title: 'Configuración del crédito',
          },
        ],
      };
      stepList(newActiveSteps);
    } else if ((key as AllowedPaymentMethodType) === AllowedPaymentMethodType.Cash) {
      currentSubStep(-1);
      const newActiveSteps = [...steps];
      newActiveSteps[currentStepIndex] = {
        ...steps[currentStepIndex],
        subSteps: undefined,
      };
      stepList(newActiveSteps);
    }
  };

  const purchaseOptions: {
    [key: string]: { title: string; component: React.ReactNode };
  } = {
    [AllowedPaymentMethodType.WithFinancing]: {
      title: 'Financiamiento',
      component: (
        <CreditPaymentMethod
          price={price}
          income={0}
          paymentsNumber={paymentsNumber}
          creditType={creditType}
          downPayment={downPayment}
          setPaymentsNumber={setPaymentsNumber}
          setCreditType={setCreditType}
          selectedInsurances={selectedInsurances}
          setSelectedInsurances={setSelectedInsurances}
          setDownPayment={setDownPayment}
          insuranceOptions={insurnaceOptions}
          setInsuranceOptions={setInsuranceOptions}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
      ),
    },
  };

  const nextButtonContent = () => {
    const buttonHeaderContent = (
      <Row>
        <p className={styles.SkipButton}>
          Al continuar estas aceptando los{' '}
          <span
            role="button"
            tabIndex={0}
            onKeyPress={() => setShowFinancingConditionsModal(true)}
            className={styles.Popover}
            onClick={() => setShowFinancingConditionsModal(true)}
          >
            términos y condiciones
          </span>{' '}
          de {financingName}.
        </p>
      </Row>
    );

    return (
      <NextButton
        headerContent={buttonHeaderContent}
        loading={createPaymentMethodLoading}
        onClick={next}
        text="Configurar"
        disabled={createPaymentMethodLoading || !purchaseItemListPrice}
      />
    );
  };

  useEffect(() => {
    if (
      selectedProduct &&
      selectedProduct.insurance &&
      litePurchaseIntentData?.litePurchaseIntent
    ) {
      const { legalEntity } = litePurchaseIntentData.litePurchaseIntent;
      const selectedProductInsurance = selectedProduct.insurance.filter(
        (insurance) => !legalEntity || (legalEntity && insurance.id !== 2)
      );
      setInsuranceOptions(selectedProductInsurance);
    }
  }, [selectedProduct, litePurchaseIntentData]);

  useEffect(() => {
    if (purchaseItemListPrice) {
      setPrice(purchaseItemListPrice);
    }
  }, [purchaseItemListPrice]);

  useEffect(() => {
    if (data && data.createPaymentMethod) {
      history.push({
        pathname: steps[currentStepIndex + 1].route,
        state: {
          from: history.location,
        },
      });
    }
  }, [data]);

  useEffect(() => {
    currentSubStep(-1);
    const newActiveSteps = [...steps];
    newActiveSteps[currentStepIndex] = {
      ...steps[currentStepIndex],
      subSteps: [
        {
          title: 'Configuración del crédito',
        },
      ],
    };
    stepList(newActiveSteps);
  }, []);

  return (
    <div>
      {showFinancingConditionsModal && (
        <Row>
          <CreditFinancingConditions
            visible={showFinancingConditionsModal}
            handleOk={() => setShowFinancingConditionsModal(false)}
          />
        </Row>
      )}

      {currentSubStepIndex === -1 && (
        <Row
          gutter={[screenSpacings.XL, screenSpacings.XL]}
          className={styles.MainSelector}
        >
          {Object.keys(purchaseOptions).map((key) => (
            <Col xs={24} sm={12} md={8} key={key}>
              <Row
                justify="space-between"
                align="middle"
                className={[styles.RadioButton, styles.ActiveRadioButton].join(' ')}
                onClick={() => handleClickPaymentMethodOption(key)}
              >
                <span className={styles.RadioText}>
                  {purchaseOptions[key as AllowedPaymentMethodType].title}
                </span>
                <span className={styles.RadioCircleContainer}>
                  <span className={[styles.Circle, styles.ActiveCircle].join(' ')}>
                    <img src={CheckWhite} alt="active" className={styles.ActiveIcon} />
                  </span>
                </span>
              </Row>
            </Col>
          ))}
        </Row>
      )}
      <Row>
        <Col xs={24}>
          <div className={styles.PanelContent}>
            {purchaseOptions[AllowedPaymentMethodType.WithFinancing].component}
          </div>
        </Col>
      </Row>
      <Row>{nextButtonContent()}</Row>
    </div>
  );
};

export default PurchaseOptions;
