const dealerOptions = [
  // { value: 'CG AUTOS', label: 'CG AUTOS' },
  // { value: 'MECANIX', label: 'MECANIX' },
  // {
  //   value: 'AUTOMOTRIZ LAS CONDES B Y B LTDA.',
  //   label: 'AUTOMOTRIZ LAS CONDES B Y B LTDA.',
  // },
  // {
  //   value: 'AUTOMOTORA SERGIO ANGELO CARMONA FERNANDINI EIRL',
  //   label: 'AUTOMOTORA SERGIO ANGELO CARMONA FERNANDINI EIRL',
  // },
  // { value: 'AUTO-OUTLET SpA', label: 'AUTO-OUTLET SpA' },
  // { value: 'AUTOMOVILES URRUTIA SPA', label: 'AUTOMOVILES URRUTIA SPA' },
  // { value: 'MAS QUE UN AUTO SPA', label: 'MAS QUE UN AUTO SPA' },
  // { value: 'Bridge Motors SPA', label: 'Bridge Motors SPA' },
  // { value: 'WCAR SPA', label: 'WCAR SPA' },
  // { value: 'AUTO LIKE SpA', label: 'AUTO LIKE SpA' },
  // {
  //   value: 'SERVICIOS AUTOMOTRICES OK CAR SPA',
  //   label: 'SERVICIOS AUTOMOTRICES OK CAR SPA',
  // },
  // {
  //   value: 'MGM AUTOMOTORA SPA',
  //   label: 'MGM AUTOMOTORA SPA',
  // },
  // {
  //   value: 'PEDRO CARO GUTIERREZ',
  //   label: 'PEDRO CARO GUTIERREZ',
  // },
  // {
  //   value: 'AUTO-OUTLET SPA',
  //   label: 'AUTO-OUTLET SPA',
  // },
  // {
  //   value: 'AUTOMOTORA CABRERA Y PUENTE',
  //   label: 'AUTOMOTORA CABRERA Y PUENTE',
  // },
  // {
  //   value: 'INVERSIONES FOST SPA',
  //   label: 'INVERSIONES FOST SPA',
  // },
  // {
  //   value: 'AUTOMOTRIZ SANTIAGO AUTOMOVILES LIMITADA',
  //   label: 'AUTOMOTRIZ SANTIAGO AUTOMOVILES LIMITADA',
  // },
  // {
  //   value: 'RUBEN ENRIQUE ZAMORA PEDREROS',
  //   label: 'RUBEN ENRIQUE ZAMORA PEDREROS',
  // },
  // {
  //   value: 'R3 MOTORS SPA',
  //   label: 'R3 MOTORS SPA',
  // },
  // {
  //   value: 'PAULO CESAR PALMA BUSTAMENTE',
  //   label: 'PAULO CESAR PALMA BUSTAMENTE',
  // },
  // // New options added below
  // {
  //   value: 'AUTOM. MAURO PATRICIO BELLIONE YAÑEZ EIRL',
  //   label: 'AUTOM. MAURO PATRICIO BELLIONE YAÑEZ EIRL',
  // },
  // {
  //   value: 'AUTOMOTORA A&T',
  //   label: 'AUTOMOTORA A&T',
  // },
  // {
  //   value: 'AUTOMOTORA VAZQUEZ SPA',
  //   label: 'AUTOMOTORA VAZQUEZ SPA',
  // },
  // {
  //   value: 'AUTOMOTRIZ HANSHING SPA',
  //   label: 'AUTOMOTRIZ HANSHING SPA',
  // },
  // {
  //   value: 'IVONNE TORO SAGUEZ EIRL',
  //   label: 'IVONNE TORO SAGUEZ EIRL',
  // },
  // {
  //   value: 'JJ SOLUCION AUTOMOTRIZ LTDA',
  //   label: 'JJ SOLUCION AUTOMOTRIZ LTDA',
  // },
  // {
  //   value: 'LA ORIENTAL AUTOMOTRIZ LIMITADA',
  //   label: 'LA ORIENTAL AUTOMOTRIZ LIMITADA',
  // },
  // {
  //   value: 'RODOLFO ELIAS MORALES BRIONES SPA',
  //   label: 'RODOLFO ELIAS MORALES BRIONES SPA',
  // },
  // {
  //   value: 'SAMUEL ISRAEL MORALES BRIONES SPA',
  //   label: 'SAMUEL ISRAEL MORALES BRIONES SPA',
  // },
  // {
  //   value: 'SERVICIOS AUTOMOTRIZ ANSAR SPA',
  //   label: 'SERVICIOS AUTOMOTRIZ ANSAR SPA',
  // },
  // {
  //   value: 'SOCIEDAD COMERCIAL WALL SPA',
  //   label: 'SOCIEDAD COMERCIAL WALL SPA',
  // },
  // {
  //   value: 'AMANCAR SPA',
  //   label: 'AMANCAR SPA',
  // },
  // {
  //   value: 'AUTOMOTORA BALMACEDA SPA',
  //   label: 'AUTOMOTORA BALMACEDA SPA',
  // },
  // {
  //   value: 'FULL CAR SPA',
  //   label: 'FULL CAR SPA',
  // },
  // {
  //   value: 'AUTOMOTRIZ FERNANDEZ',
  //   label: 'AUTOMOTRIZ FERNANDEZ',
  // },
  // {
  //   value: 'IVAN COFRE AUTOMOTRIZ SPA',
  //   label: 'IVAN COFRE AUTOMOTRIZ SPA',
  // },
  // {
  //   value: 'RENE AQUEA INVERSIONES Y CIA LIMITADA',
  //   label: 'RENE AQUEA INVERSIONES Y CIA LIMITADA',
  // },
  // {
  //   value: 'LOS MAITENES SPA',
  //   label: 'LOS MAITENES SPA',
  // },
  // {
  //   value: 'INVERSIONES DAVID RODRIGUEZ AGUIRRE SPA',
  //   label: 'INVERSIONES DAVID RODRIGUEZ AGUIRRE SPA',
  // },
  // {
  //   value: 'CAMIONETAS LA SERENA SPA',
  //   label: 'CAMIONETAS LA SERENA SPA',
  // },
  // {
  //   value: 'INVERSIONES VISTA AL VALLE LTDA',
  //   label: 'INVERSIONES VISTA AL VALLE LTDA',
  // },
  // {
  //   value: 'INVERSIONES SAN JORGE SPA',
  //   label: 'INVERSIONES SAN JORGE SPA',
  // },
  // {
  //   value: 'AUTOMOTORA INDEPENDENCIA LTDA',
  //   label: 'AUTOMOTORA INDEPENDENCIA LTDA',
  // },
  // {
  //   value: 'MARIA JOSE JARA OROSTICA',
  //   label: 'MARIA JOSE JARA OROSTICA',
  // },
  // {
  //   value: 'INVERSIONES RECAMIN SPA',
  //   label: 'INVERSIONES RECAMIN SPA',
  // },
  // {
  //   value: 'FARID JACOB',
  //   label: 'FARID JACOB',
  // },
  // {
  //   value: 'COMERCIAL AUTOKARINO LIMITADA',
  //   label: 'COMERCIAL AUTOKARINO LIMITADA',
  // },
  {
    value: 'AUTOMOTORA CABRERA Y PUENTE',
    label: 'AUTOMOTORA CABRERA Y PUENTE',
  },
  {
    value: 'AUTOMOTORA VAZQUEZ SPA',
    label: 'AUTOMOTORA VAZQUEZ SPA',
  },
  {
    value: 'IVONNE TORO SAGUEZ EIRL',
    label: 'IVONNE TORO SAGUEZ EIRL',
  },
  {
    value: 'RODOLFO ELIAS MORALES BRIONES SPA',
    label: 'RODOLFO ELIAS MORALES BRIONES SPA',
  },
  {
    value: 'SAMUEL ISRAEL MORALES BRIONES SPA',
    label: 'SAMUEL ISRAEL MORALES BRIONES SPA',
  },
  {
    value: 'SERVICIOS AUTOMOTRIZ ANSAR SPA',
    label: 'SERVICIOS AUTOMOTRIZ ANSAR SPA',
  },
  {
    value: 'SOCIEDAD COMERCIAL WALL SPA',
    label: 'SOCIEDAD COMERCIAL WALL SPA',
  },
  {
    value: 'AUTOMOTORA SERGIO ANGELO CARMONA FERNANDINI EIRL',
    label: 'AUTOMOTORA SERGIO ANGELO CARMONA FERNANDINI EIRL',
  },
  {
    value: 'CAR CREDIT SPA',
    label: 'CAR CREDIT SPA',
  },
  {
    value: 'INVERSIONES MANSUR LTDA',
    label: 'INVERSIONES MANSUR LTDA',
  },
  {
    value: 'INVERSIONES SANTA MACARENA SPA',
    label: 'INVERSIONES SANTA MACARENA SPA',
  },
  {
    value: 'JUAN RODRIGO ROJAS ROJAS EIRL',
    label: 'JUAN RODRIGO ROJAS ROJAS EIRL',
  },
  {
    value: 'LIBERTAD AUTOMOTRIZ SPA',
    label: 'LIBERTAD AUTOMOTRIZ SPA',
  },
  {
    value: 'C.M.H. MOTORS SPA',
    label: 'C.M.H. MOTORS SPA',
  },
  {
    value: 'MY-CAR',
    label: 'MY-CAR',
  },
  {
    value: 'COMERCIAL EMILIO ROJAS ROJAS EIRL',
    label: 'COMERCIAL EMILIO ROJAS ROJAS EIRL',
  },
  {
    value: 'COMERCIAL AREVALO Y FERNANDEZ LTDA',
    label: 'COMERCIAL AREVALO Y FERNANDEZ LTDA',
  },
  {
    value: 'SOCIEDAD  DE INVERSIONES  RPM SPA',
    label: 'SOCIEDAD  DE INVERSIONES  RPM SPA',
  },
  {
    value: 'RUBEN ENRIQUE ZAMORA PEDREROS',
    label: 'RUBEN ENRIQUE ZAMORA PEDREROS',
  },
  {
    value: 'AUTOMOTORA SAN CARLOS LIMITADA',
    label: 'AUTOMOTORA SAN CARLOS LIMITADA',
  },
  {
    value: 'URSSA MOTORS SPA',
    label: 'URSSA MOTORS SPA',
  },
  {
    value: 'AUTOMOTORA R Y M LTDA',
    label: 'AUTOMOTORA R Y M LTDA',
  },
  {
    value: 'AUTOS SORIANO LTDA',
    label: 'AUTOS SORIANO LTDA',
  },
  {
    value: 'AUTOMOTORA FREDDY VIZANSKIS SPA',
    label: 'AUTOMOTORA FREDDY VIZANSKIS SPA',
  },
  {
    value: 'AUTOMOTRIZ HANSHING SPA',
    label: 'AUTOMOTRIZ HANSHING SPA',
  },
  {
    value: 'AUTOMOTRIZ Y SERVICIOS GYG SPA',
    label: 'AUTOMOTRIZ Y SERVICIOS GYG SPA',
  },
  {
    value: 'AUTO-OUTLET SPA OSSA',
    label: 'AUTO-OUTLET SPA OSSA',
  },
  {
    value: 'AUTO-OUTLET SPA VITACURA',
    label: 'AUTO-OUTLET SPA VITACURA',
  },
  {
    value: 'BLCARS SPA',
    label: 'BLCARS SPA',
  },
  {
    value: 'CASTEX Y POBLETE LIMITADA',
    label: 'CASTEX Y POBLETE LIMITADA',
  },
  {
    value: 'CG AUTOS',
    label: 'CG AUTOS',
  },
  {
    value: 'IMPORT EXPORT PAK RAHMAT CO. LIMITADA',
    label: 'IMPORT EXPORT PAK RAHMAT CO. LIMITADA',
  },
  {
    value: 'MARIA EUGENIA VALENZUELA MIRANDA',
    label: 'MARIA EUGENIA VALENZUELA MIRANDA',
  },
  {
    value: 'INVERSIONES SAN JORGE SPA',
    label: 'INVERSIONES SAN JORGE SPA',
  },
  {
    value: 'PEDRO CARO GUTIERREZ',
    label: 'PEDRO CARO GUTIERREZ',
  },
  {
    value: 'PAULO CESAR PALMA BUSTAMENTE',
    label: 'PAULO CESAR PALMA BUSTAMENTE',
  },
  {
    value: 'SERVICIOS AUTOMOTRICES JAVIER SEBASTIAN SALVADORES SCHEIHING EIRL',
    label: 'SERVICIOS AUTOMOTRICES JAVIER SEBASTIAN SALVADORES SCHEIHING EIRL',
  },
  {
    value: 'SOCIEDAD DE COMPRAVENTA Y ARRIENDO DE VEHICULOS Y MAQUINARIA RENTMONTT SPA',
    label: 'SOCIEDAD DE COMPRAVENTA Y ARRIENDO DE VEHICULOS Y MAQUINARIA RENTMONTT SPA',
  },
  {
    value: 'SOCIEDAD  DE INVERSIONES  RPM SPA',
    label: 'SOCIEDAD  DE INVERSIONES  RPM SPA',
  },
  {
    value: 'VENTA Y GESTION AUTOMOTRIZ SP MOTORS ONLINE SPA',
    label: 'VENTA Y GESTION AUTOMOTRIZ SP MOTORS ONLINE SPA',
  },

  {
    value: 'AUTOCLAM SPA',
    label: 'AUTOCLAM SPA',
  },
];

export default dealerOptions;
