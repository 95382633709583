import React, { FC } from 'react';
import { ApolloProvider } from '@apollo/client';
import selectApolloClient from 'apollo/config';

interface ProviderProps {
  children?: React.ReactNode | React.ReactNode[] | null;
}

const ApolloWrapperProvider: FC<ProviderProps> = ({ children }: ProviderProps) => {
  const apolloClient = selectApolloClient();
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

ApolloWrapperProvider.defaultProps = {
  children: undefined,
};

export default ApolloWrapperProvider;
