import { useMutation } from '@apollo/client';
import operations from 'apollo/operations';
import { PaymentMethod, PaymentMethodInput } from 'apollo/types';
import getOperationByPurchaseIntentType from 'dataHooks/utils/getConditionalOperationByPurchaseIntentType';
import { getPurchaseIntentType } from 'utils/getDataFromJwt';

const PurchaseOptionsDataHook = () => {
  const purchaseIntentType = getPurchaseIntentType();

  const [createPaymentMethod, { data, loading: createPaymentMethodLoading }] =
    useMutation<
      { createPaymentMethod: PaymentMethod },
      { paymentMethodInput: PaymentMethodInput }
    >(operations.POST_PAYMENT_METHOD);

  const refetchPurchaseItemInfoQuery =
    operations[
      getOperationByPurchaseIntentType.PurchaseItemSummaryInfo[purchaseIntentType]
    ];

  return {
    createPaymentMethod,
    data,
    refetchPurchaseItemInfoQuery,
    createPaymentMethodLoading,
  };
};
export default PurchaseOptionsDataHook;
