/* eslint-disable no-console */
import axios from 'axios';
import { CustomError } from 'apollo/types';
import config from 'config';

const { webhookUrl, logLevel } = config;

export interface LogFn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (message?: any, ...optionalParams: any[]): void;
}

export interface Logger {
  log: LogFn;
  warn: LogFn;
  error: LogFn;
}

export type LogLevel = 'log' | 'warn' | 'error';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NO_OP: LogFn = (message?: unknown, ...optionalParams: unknown[]) => {};

const axiosInstance = axios.create({
  baseURL: webhookUrl || '',
});

const slackWebhookLogger = (payload?: {
  errors: CustomError[];
  purchaseIntentId: string;
  currentPathName: string;
  customerEmail?: string | undefined;
  cache: Record<string, unknown>;
  optionalParams: unknown[];
}) => {
  const errorRenderer = (error: CustomError, index: number, numberOfErrors: number) => {
    const { codeMessage } = error;
    const customMessage = codeMessage
      ? `Error ${numberOfErrors > 1 ? `${index + 1}` : ''}: \`\`\`${codeMessage.code} - ${
          codeMessage.message || ''
        }\`\`\``
      : `Error ${numberOfErrors > 1 ? `${index + 1}` : ''}: \`\`\`${error.name} - ${
          error.message || ''
        }\`\`\``;
    return customMessage;
  };
  axiosInstance({
    url: '',
    method: 'post',
    headers: undefined,
    data: !payload
      ? {
          text: 'Error: unknown payload',
        }
      : {
          text: `${payload.purchaseIntentId} - ${payload.currentPathName}`,
          blocks: [
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `*purchaseIntentId:* \`${payload.purchaseIntentId}\``,
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `*path*: ${payload.currentPathName}`,
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `*customerEmail:* \`${payload.customerEmail || '-'}\``,
              },
            },
            ...payload.errors.map((error, i) => ({
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: errorRenderer(error, i, payload.errors.length),
              },
            })),
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `\`\`\` ${JSON.stringify(payload.cache, null, 2)} \`\`\``,
              },
            },
            {
              type: 'divider',
            },
          ],
        },
    withCredentials: false,
    transformRequest: [(data: unknown) => JSON.stringify(data)],
    validateStatus: (status) => status >= 200 && status < 400,
  });
};

export class ConsoleLogger implements Logger {
  readonly log: LogFn;

  readonly warn: LogFn;

  readonly error: LogFn;

  constructor(options?: { level?: LogLevel }) {
    const { level } = options || {};

    if (process.env.NODE_ENV === 'production') {
      this.error = slackWebhookLogger;
      this.warn = slackWebhookLogger;
      this.log = slackWebhookLogger;
    } else {
      this.error = console.error.bind(console);
      this.warn = console.warn.bind(console);
      this.log = console.log.bind(console);
    }

    if (level === 'error') {
      this.warn = NO_OP;
      this.log = NO_OP;
      return;
    }

    if (level === 'warn') {
      this.log = NO_OP;
    }
  }
}

const logger = new ConsoleLogger({ level: logLevel as LogLevel });

export default logger;
