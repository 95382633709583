import React, { Dispatch, FC, useEffect, useState } from 'react';
import { Modal, Row, Col } from 'antd';
import { Button } from '@cloudcar-app/cloudcar-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './ConfirmationModal.module.scss';

interface ConfirmationModalProps {
  showModalCondition: boolean;
  setShowModalCondition: Dispatch<React.SetStateAction<boolean>>;
  title: string;
  bodyText: React.ReactElement<HTMLParagraphElement>;
  visibilityCondition?: boolean;
  setVisibilityCondition?: Dispatch<React.SetStateAction<boolean>>;
  onOk?: () => void;
  onCancel?: () => void;
  okText?: string;
}

const ConfirmationModal: FC<ConfirmationModalProps> = (props: ConfirmationModalProps) => {
  const {
    showModalCondition,
    setShowModalCondition,
    title,
    bodyText,
    visibilityCondition,
    setVisibilityCondition,
    onOk,
    onCancel,
    okText,
  } = props;
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setShowModalCondition(false);
    if (setVisibilityCondition) setVisibilityCondition(false);
    if (onCancel) onCancel();
  };

  const handleOk = () => {
    setLoading(true);
    if (onOk) onOk();
  };

  useEffect(() => {
    setLoading(false);
  }, [showModalCondition]);

  return (
    <Modal
      visible={showModalCondition || visibilityCondition}
      footer={null}
      onCancel={handleCancel}
      className="confirmationModal"
      onOk={handleOk}
    >
      <Row justify="start" gutter={16}>
        <Col xs={2}>
          <ExclamationCircleOutlined className={styles.Icon} />
        </Col>
        <Col xs={22}>
          <h3 className={styles.ModalTitle}>{title}</h3>
        </Col>
      </Row>
      <Row justify="end" gutter={16} className={styles.DeleteText}>
        <Col xs={22}>
          <div className={styles.ModalText}>{bodyText}</div>
        </Col>
      </Row>
      <Row className={styles.ButtonRow} justify="end" gutter={8}>
        <Col>
          <Button onClick={handleCancel} mode="secondary">
            Cancelar
          </Button>
        </Col>
        <Col>
          <Button onClick={handleOk} loading={loading}>
            {okText || 'Continuar'}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

ConfirmationModal.defaultProps = {
  visibilityCondition: false,
  setVisibilityCondition: undefined,
  onOk: undefined,
  onCancel: undefined,
  okText: undefined,
};

export default ConfirmationModal;
