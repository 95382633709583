/* eslint-disable react/jsx-props-no-spreading */
import { FormInstance } from 'antd';
import React from 'react';
import TannerForm from './Tanner';

interface SpecificFormProps {
  createPurchaseIntentLoading: boolean;
  handleChangeRut: (rut: string) => string;
  form: FormInstance<unknown>;
  // legalEntity: boolean;
  // setLegalEntity: (value: boolean) => void;
}

const renderConcessionaireForm = (sharedProps: SpecificFormProps) => {
  return <TannerForm {...sharedProps} />;
};

export default renderConcessionaireForm;
