/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/plugin/localeData';
import { Button, DatePickerWrapped } from '@cloudcar-app/cloudcar-components';
import { Col, Modal, Row } from 'antd';
import styles from './PurchaseIntentRangePicker.module.scss';

dayjs.locale('es');

const { RangePicker } = DatePickerWrapped;

interface PurchaseIntentRangePickerProps {
  visible: boolean;
  csvListLoading: boolean;
  onClose: () => void;
  handleLitePurchaseIntentCsv: () => void;
  setStartDateString: React.Dispatch<React.SetStateAction<string>>;
  setEndDateString: React.Dispatch<React.SetStateAction<string>>;
}

const PurchaseIntentRangePicker: FC<PurchaseIntentRangePickerProps> = (
  props: PurchaseIntentRangePickerProps
) => {
  const {
    visible,
    onClose,
    handleLitePurchaseIntentCsv,
    csvListLoading,
    setStartDateString,
    setEndDateString,
  } = props;

  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('day'));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());

  const rangePresets: Record<string, [Dayjs, Dayjs]> = {
    Hoy: [dayjs().startOf('day'), dayjs()],
    'Últimos 7 días': [dayjs().subtract(7, 'day').startOf('day'), dayjs()],
    'Esta semana': [dayjs().startOf('week').startOf('day'), dayjs().endOf('week')],
    'Este mes': [dayjs().startOf('month').startOf('day'), dayjs().endOf('month')],
    'El mes anterior': [
      dayjs().subtract(1, 'month').startOf('month').startOf('day'),
      dayjs().subtract(1, 'month').endOf('month'),
    ],
    'Los últimos 3 meses': [
      dayjs().subtract(3, 'month').startOf('month').startOf('day'),
      dayjs().endOf('month'),
    ],
    'Los últimos 6 meses': [
      dayjs().subtract(6, 'month').startOf('month').startOf('day'),
      dayjs().endOf('month'),
    ],
    'Este año': [dayjs().startOf('year').startOf('day'), dayjs().endOf('year')],
    'El año anterior': [
      dayjs().subtract(1, 'year').startOf('year').startOf('day'),
      dayjs().subtract(1, 'year').endOf('year'),
    ],
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeRangePicker = (newValues: any) => {
    if (newValues && newValues[0] && newValues[1]) {
      const newStartDate = newValues[0].startOf('day');
      const newEndDate = newValues[1].endOf('day');
      setStartDateString(newStartDate.toISOString());
      setEndDateString(newEndDate.toISOString());
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  };

  return (
    <>
      <Modal
        open={visible}
        footer={null}
        title="Seleccione rango de fechas"
        className="confirmationModal"
        onCancel={onClose}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <RangePicker
              className={styles.DatePicker}
              format="DD-MM-YYYY"
              placeholder={['Fecha de inicio', 'Fecha de término']}
              size="middle"
              value={[startDate, endDate]}
              onChange={onChangeRangePicker}
              ranges={rangePresets}
              allowClear={false}
            />
          </Col>

          <Col xs={24}>
            <Button
              loading={csvListLoading}
              onClick={() => {
                handleLitePurchaseIntentCsv();
              }}
            >
              Descargar solicitudes
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default PurchaseIntentRangePicker;
