import React, { FC, useEffect, useState } from 'react';
import { Col, Form, FormInstance, Row } from 'antd';
import {
  DatePicker,
  Input,
  Select,
  useMaxBreakpoint,
} from '@cloudcar-app/cloudcar-components';
import { formatRut, validateRut } from '@abstract-cl/rut-ts';
import { RegistryRow } from 'apollo/types';
import MessageError from 'utils/message.errors';
import validateDate from 'utils/dateFormValidator';
import rutFormValidator from 'utils/rutFormValidator';
import getScreenSpacings from 'utils/getScreenSpacings';
import { useLazyQuery, useQuery } from '@apollo/client';
import operations from 'apollo/operations';
import styles from './PersonalInformationForm.module.scss';

interface SubFormProps {
  form: FormInstance;
  hide: boolean;
}

const PersonalInformationForm: FC<SubFormProps> = (props: SubFormProps) => {
  const { form, hide } = props;
  const maxScreen = useMaxBreakpoint();
  const screenSpacings = getScreenSpacings(maxScreen);
  const displayFormStyle = hide ? 'none' : 'flex';

  const { data, loading } = useQuery<{ registryRegions: RegistryRow[] }>(
    operations.GET_REGISTRY_REGIONS,
    {
      fetchPolicy: 'network-only',
    }
  );

  const [getRegistryCommunes, { data: communeData, loading: communeLoading }] =
    useLazyQuery<{ registryCommunes: RegistryRow[] }, { regionId: string }>(
      operations.GET_REGISTRY_COMMUNES
    );

  const [regionOptions, setRegionOptions] = useState<RegistryRow[]>([]);
  const [communeOptions, setCommuneOptions] = useState<RegistryRow[]>([]);
  const [selectedCommune, setSelectedCommune] = useState<number | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<number | null>(null);

  const handleRegistryRegionsData = () => {
    if (data && data.registryRegions) {
      setRegionOptions(data.registryRegions);
    }
  };

  const handleRegistryCommunesData = () => {
    if (communeData && communeData.registryCommunes) {
      setCommuneOptions(communeData.registryCommunes);
    }
  };

  const handleRegistryCommunesCall = async () => {
    if (selectedRegion) {
      await getRegistryCommunes({
        variables: {
          regionId: selectedRegion?.toString(),
        },
      });
    }
  };

  const handleChangeRut = (rut: string): string => {
    if (validateRut(rut)) {
      const formatted: string = formatRut(rut) || '';
      form.setFieldsValue({ rut: formatted });
      return rut;
    }
    form.setFieldsValue({
      rut: rut.split('.').join('').split('-').join(''),
    });
    return rut;
  };

  const disableBrowserAutocomleteForAddress = () => {
    const anyInvalidStringToDisableBrowserAutocomplete = 'any-invalid-value';
    const regionSelector = document.querySelector('#personalInformation_region');
    regionSelector?.setAttribute(
      'autocomplete',
      anyInvalidStringToDisableBrowserAutocomplete
    );
    const communeSelector = document.querySelector('#personalInformation_commune');
    communeSelector?.setAttribute(
      'autocomplete',
      anyInvalidStringToDisableBrowserAutocomplete
    );
  };

  const handleSelectedRegistryData = () => {
    if (selectedCommune) {
      form.setFieldsValue({
        regionId: selectedRegion,
        communeId: selectedCommune,
      });
    }
  };

  useEffect(() => {
    handleRegistryRegionsData();
  }, [data]);

  useEffect(() => {
    handleRegistryCommunesData();
  }, [communeData]);

  useEffect(() => {
    handleRegistryCommunesCall();
  }, [selectedRegion]);

  useEffect(() => {
    handleSelectedRegistryData();
  }, [selectedCommune]);

  useEffect(() => {
    disableBrowserAutocomleteForAddress();
  }, []);

  return (
    <Row
      justify="start"
      gutter={[screenSpacings.L, screenSpacings.L]}
      style={{ display: displayFormStyle }}
    >
      <Col xs={24} sm={8}>
        <Form.Item
          name="name"
          className={styles.FormInput}
          rules={[
            {
              required: true,
              message: MessageError.emptyFieldsMessages.name,
            },
            {
              whitespace: true,
              message: MessageError.emptyFieldsMessages.name,
            },
          ]}
        >
          <Input label="Nombre" placeholder="Nombre" required compact />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8}>
        <Form.Item
          name="firstSurname"
          className={styles.FormInput}
          rules={[
            {
              required: true,
              message: MessageError.emptyFieldsMessages.lastName,
            },
            {
              whitespace: true,
              message: MessageError.emptyFieldsMessages.lastName,
            },
          ]}
        >
          <Input
            label="Apellido paterno"
            placeholder="Apellido paterno"
            required
            compact
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8}>
        <Form.Item name="secondSurname" className={styles.FormInput}>
          <Input label="Apellido materno" placeholder="Apellido materno" compact />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          name="rut"
          className={styles.FormInput}
          rules={[
            { required: true, message: MessageError.emptyFieldsMessages.rut },
            {
              validator: rutFormValidator,
            },
          ]}
        >
          <Input
            label="Rut"
            placeholder="Rut"
            extra="*Escribe tu rut sin puntos ni guión."
            onChange={(e) => handleChangeRut(e.target.value)}
            required
            compact
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          name="birthday"
          className={styles.FormInput}
          rules={[
            {
              required: true,
              message: MessageError.emptyFieldsMessages.birthDate,
            },
            {
              validator: validateDate,
            },
          ]}
        >
          <DatePicker placeholder="Fecha de nacimiento" compact required />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          name="email"
          className={styles.FormInput}
          rules={[
            {
              type: 'email',
              message: MessageError.invalidFormatFieldsMessages.email,
            },
            { required: true, message: MessageError.emptyFieldsMessages.email },
          ]}
        >
          <Input label="Email" placeholder="Email" required compact />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          name="phone"
          className={styles.FormInput}
          rules={[
            {
              pattern: /^[9]\d{8}$/,
              message: MessageError.invalidFormatFieldsMessages.phone,
            },
            { required: true, message: MessageError.emptyFieldsMessages.phone },
            {
              whitespace: true,
              message: MessageError.emptyFieldsMessages.phone,
            },
          ]}
        >
          <Input
            addonBefore="+56"
            label="Celular"
            placeholder="Celular"
            required
            compact
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          name="region"
          className={styles.FormInput}
          rules={[
            {
              required: true,
              message: MessageError.emptyFieldsMessages.region,
            },
          ]}
        >
          <Select
            label="Región"
            placeholder="Región"
            disabled={loading}
            showSearch
            loading={loading}
            onChange={(value: string) => {
              setSelectedRegion(parseInt(value.split('#')[0], 10));
              form.setFieldsValue({ commune: null });
            }}
            required
            compact
            options={regionOptions.map((region) => ({
              label: region.name,
              value: `${region.id}#${region.name}`,
            }))}
            filterOption={(input, option) =>
              option
                ? String(option.label).toLowerCase().includes(input.toLowerCase())
                : false
            }
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          name="commune"
          className={styles.FormInput}
          rules={[
            {
              required: true,
              message: MessageError.emptyFieldsMessages.commune,
            },
          ]}
        >
          <Select
            label="Comuna"
            placeholder="Comuna"
            onChange={(value: string) =>
              setSelectedCommune(parseInt(value.split('#')[0], 10))
            }
            value={selectedCommune}
            required
            compact
            disabled={communeLoading}
            showSearch
            loading={communeLoading}
            options={communeOptions.map((commune) => ({
              label: commune.name,
              value: `${commune.id}#${commune.name}`,
            }))}
            filterOption={(input, option) =>
              option
                ? String(option.label).toLowerCase().includes(input.toLowerCase())
                : false
            }
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          name="address"
          className={styles.FormInput}
          rules={[
            {
              required: true,
              message: MessageError.emptyFieldsMessages.address,
            },
            {
              whitespace: true,
              message: MessageError.emptyFieldsMessages.address,
            },
          ]}
        >
          <Input label="Calle" placeholder="Calle" required compact />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          name="addressNumber"
          className={styles.FormInput}
          rules={[
            {
              required: true,
              message: MessageError.emptyFieldsMessages.addressNumber,
            },
          ]}
        >
          <Input type="text" label="Número" placeholder="Número" required compact />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default PersonalInformationForm;
