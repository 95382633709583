import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { stepList } from 'apollo/cache';
import { StepRenderer } from 'components/ui';
import getStepIndexByPathname from 'utils/getStepIndexByPathname';
import { isUserLogged } from 'utils/checkUserStatus';

interface StepRouteProps {
  exact: boolean;
  path: string;
  step?: number;
}

const StepRoute: FC<StepRouteProps> = (props: StepRouteProps) => {
  const { exact, path, step } = props;
  if (!isUserLogged()) {
    return <Redirect to="/login" />;
  }

  const steps = useReactiveVar(stepList);
  const currentStepIndex = getStepIndexByPathname(path, steps);

  return (
    <Route exact={exact} path={path}>
      <StepRenderer step={typeof step === 'number' ? step : currentStepIndex} />
    </Route>
  );
};

StepRoute.defaultProps = {
  step: undefined,
};

export default StepRoute;
