const MessageError = {
  emptyFieldsMessages: {
    concessionaire: 'Debes ingresar el concesionario!',
    phone: 'Debes ingresar tu celular!',
    email: 'Debes ingresar tu email!',
    birthDate: 'Debes ingresar tu fecha de nacimiento!',
    rut: 'Debes ingresar tu Rut!',
    address: 'Debes ingresar tu calle!',
    addressNumber: 'Debes ingresar el número de tu dirección!',
    lastName: 'Debes ingresar tu apellido paterno!',
    name: 'Debes ingresar tu nombre!',
    country: 'Debes seleccionar tu país!',
    region: 'Debes seleccionar tu región!',
    commune: 'Debes seleccionar tu comuna!',
    income: 'Debes ingresar tu renta líquida!',
    incomeType: 'Debes seleccionar el tipo de tu fuente principal de ingresos!',
    incomeVariability: 'Debes seleccionar el tipo de renta!',
    workSeniority: 'Debes seleccionar tu antigüedad laboral!',
    contractType: 'Debes seleccionar tu tipo de contrato!',
    gender: 'Debes seleccionar el género con el que te identificas!',
    profession: 'Debes ingresar tu profesión!',
    studies: 'Debes seleccionar tu nivel más alto de estudios alcanzados!',
    maritalStatus: 'Debes seleccionar tu estado civil!',
    verificationCode: 'Debes ingresar el código que recibiste en tu email!',
    installmentAmount: 'Debes ingresar el monto del pago!',
    brand: 'Debes ingresar la marca del vehículo!',
    model: 'Debes ingresar el modelo del vehículo!',
    year: 'Debes ingresar el año del vehículo!',
    version: 'Debes ingresar la versión del vehículo!',
    color: 'Debes ingresar el color del vehículo!',
    price: 'Debes ingresar el precio del vehículo!',
    cit: 'Debes ingresar el CIT (Código de informe técnico) del vehículo!',
    username: 'Debes ingresar tu nombre de usuario!',
    password: 'Debes ingresar tu contraseña!',
    dealer: 'Debes ingresar la sucursal!',
    vehicleType: 'Debes seleccionar el tipo de vehículo!',
    confirmPassword: 'Debes confirmar tu contraseña!',
  },
  invalidFormatFieldsMessages: {
    phone: 'Ingrese un celular chileno válido (Por ejemplo: 912345678)!',
    email: 'Debes ingresar un email válido!',
    rut: 'Ingrese un rut válido!',
    birthDate: 'Debes ser mayor de edad!',
    installmentAmount: 'Debes ingresar un monto válido!',
    year: 'Debe ser un valor numérico',
    price: 'Debe ser un valor numérico',
    passwordMinLength: 'La contraseña debe tener al menos 6 caracteres!',
    passwordNoMatch: 'Las contraseñas no coinciden!',
  },
  webpayMessages: {
    initTransaction:
      'Ha ocurrido un error al intentar iniciar la transacción. Por favor intenta nuevamente. Si el problema persiste contacta a nuestro equipo de soporte.',
    cancelled:
      'Has cancelado tu compra. No te preocupes, tus datos no se han perdido y puedes intentar nuevamente',
    knownError:
      'Ha ocurrido un error al realizar la transacción. Por favor intenta nuevamente. Si el problema persiste contacta a nuestro equipo de soporte.',
    unknownError:
      'Ha ocurrido un error inesperado. Si el problema persiste contacta a nuestro equipo de soporte',
  },
};

export default MessageError;
