import React, { FC } from 'react';
import { Modal, Row, Col } from 'antd';
import { Button } from '@cloudcar-app/cloudcar-components';
import { QuestionCircleOutlined } from '@ant-design/icons';
import styles from './DeleteModal.module.scss';

interface DeleteModalProps {
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  subject: string;
  subjectGender: 'M' | 'F';
  additionalMessage?: string;
}

const DeleteModal: FC<DeleteModalProps> = (props: DeleteModalProps) => {
  const { subject, subjectGender, additionalMessage, visible, handleOk, handleCancel } =
    props;

  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width="30%"
      bodyStyle={{ padding: '36px 36px 24px 36px' }}
      style={{ top: '30%', bottom: '50%' }}
    >
      <Row justify="start" gutter={16}>
        <Col xs={2}>
          <QuestionCircleOutlined className={styles.Icon} />
        </Col>
        <Col xs={22}>
          <h3 className={styles.DeleteModalTitle}>
            ¿Estas seguro que deseas eliminar est{subjectGender === 'M' ? 'e' : 'a'}{' '}
            {subject}?
          </h3>
        </Col>
      </Row>
      {additionalMessage && (
        <Row justify="end" gutter={16} className={styles.DeleteText}>
          <Col xs={22}>
            <p className={styles.DeleteModalText}>{additionalMessage}</p>
          </Col>
        </Row>
      )}
      <Row className={styles.ButtonRow} justify="end" gutter={8}>
        <Col>
          <Button onClick={handleCancel} mode="secondary">
            Cancelar
          </Button>
        </Col>
        <Col>
          <Button onClick={handleOk}>Eliminar</Button>
        </Col>
      </Row>
    </Modal>
  );
};

DeleteModal.defaultProps = {
  additionalMessage: undefined,
};

export default DeleteModal;
