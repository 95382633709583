/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance, RuleObject } from 'antd/lib/form';

const comparePasswords = (rule: RuleObject, value: string, form: FormInstance<any>) => {
  const passwordValue = form.getFieldValue('password') as string | undefined;
  if (value !== passwordValue) {
    return Promise.reject();
  }
  return Promise.resolve();
};

export default comparePasswords;
