import { logged } from 'apollo/cache';
import axios, { AxiosResponse } from 'axios';
import config from 'config';

const { apiRestUrl, apiOperationCostUrl } = config;

const axiosInstance = axios.create({
  baseURL: apiRestUrl || '',
});

const operationCostAxiosInstance = axios.create({
  baseURL: apiOperationCostUrl || '',
});

const refreshTokensRequest = async (): Promise<AxiosResponse> =>
  new Promise((resolve, reject) => {
    try {
      const data = {
        refreshToken: localStorage.getItem('RefreshToken'),
      };
      const result = axios.post(
        `${apiRestUrl as string}/financing-lite/refreshToken`,
        data
      );
      resolve(result);
    } catch (err) {
      reject(err);
    }
  });

const headerWithAuthorization = (): { [key: string]: string } => {
  return {
    authorization: `Bearer ${localStorage.getItem('IdToken') as string}`,
  };
};

export const logout = (): void => {
  logged(false);
  localStorage.clear();
  window.location.replace('/login');
};

const utils = {
  axiosInstance,
  operationCostAxiosInstance,
  logout,
  headerWithAuthorization,
  refreshTokensRequest,
};

export default utils;
