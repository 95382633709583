import { gql } from '@apollo/client';

const GET_PAYMENT_METHOD = gql`
  query paymentMethod {
    paymentMethod {
      paymentMethodId
      type
      carPrice
      # offersId
      currency
      createdAt
      financing {
        financingStatusDetails {
          motives
          observations
        }
        financingStatus
        financingInput {
          downPayment
          paymentsNumber
          loanType
        }
        financingData {
          FinancingAmount
          InterestAmount
          OperationalCost
          DealerCommission
          Cae
          InterestRate
          Fees {
            QuoteNumber
            QuoteAmount
            CapitalAmount
            CapitalBalance
            DueDate
            InterestAmount
            VfmgIndicator
          }
          Insurance {
            show
            id
            name
            amount
            required
          }
        }
      }
    }
  }
`;

const POST_PAYMENT_METHOD = gql`
  mutation createPaymentMethod($paymentMethodInput: PaymentMethodInput!) {
    createPaymentMethod(paymentMethodInput: $paymentMethodInput) {
      paymentMethodId
      carPrice
      type
    }
  }
`;

const paymentMethodOperations = {
  POST_PAYMENT_METHOD,
  GET_PAYMENT_METHOD,
};

export default paymentMethodOperations;
