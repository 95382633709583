import React, { FC } from 'react';
import { Row } from 'antd';
import { Player } from '@lottiefiles/react-lottie-player';
import { flatten } from 'lottie-colorify';
import styles from './FullPageAnimation.module.scss';

interface FullPageAnimationProps {
  animation: string | object;
  color?: string;
}

const FullPageAnimation: FC<FullPageAnimationProps> = (props: FullPageAnimationProps) => {
  const { animation, color } = props;
  return (
    <Row justify="center" className={styles.AnimationContainer}>
      <Player
        autoplay
        loop
        src={flatten(color || '#272638', animation) as string}
        className={styles.Animation}
      />
    </Row>
  );
};

FullPageAnimation.defaultProps = {
  color: undefined,
};

export default FullPageAnimation;
