import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Layout } from 'components/ui';
import { isUserLogged } from 'utils/checkUserStatus';
import { checkUserSuperAdminFromToken } from 'utils/getDataFromJwt';

interface AdminRouteProps {
  exact: boolean;
  path: string;
  component: FC;
}

const SuperAdminRoute: FC<AdminRouteProps> = (props: AdminRouteProps) => {
  const { exact, path, component: Component } = props;

  if (!isUserLogged()) {
    return <Redirect to="/login" />;
  }

  if (isUserLogged() && !checkUserSuperAdminFromToken()) {
    return <Redirect to="/" />;
  }

  return (
    <Route exact={exact} path={path}>
      <Layout>
        <Component />
      </Layout>
    </Route>
  );
};

export default SuperAdminRoute;
