interface IsClickableStepArguments {
  targetStepNumber: number;
  currentStep: number;
  numberOfSteps: number;
}

const isClickableStep = (args: IsClickableStepArguments): boolean => {
  const { targetStepNumber, currentStep, numberOfSteps } = args;
  const purchaseStep = numberOfSteps - 1;

  const isTargetStepBeforeCurrent = currentStep > targetStepNumber;
  const isCurrentStepBeforePurchase = currentStep < purchaseStep;
  return isTargetStepBeforeCurrent && isCurrentStepBeforePurchase;
};

export default isClickableStep;
