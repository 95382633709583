/* eslint-disable prettier/prettier */
import React, { FC, useState, useEffect } from 'react';
import _ from 'lodash';
import { Row, Col, Spin } from 'antd';
import { QuestionCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { Button, useMaxBreakpoint } from '@cloudcar-app/cloudcar-components';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import operations from 'apollo/operations';
import { currentSubStep } from 'apollo/cache';
import {
  LoanType,
  CreditSimulation,
  ProductInsurance,
  FinancingProducts,
  LitePurchaseIntent,
} from 'apollo/types';
import { CreditDetailsModal, CreditForm, SmartCreditModal } from 'components/credit';
import currencyFormatter from 'utils/currencyFormatter';
import useDebounce from 'utils/useDebounce';
import getScreenSpacings from 'utils/getScreenSpacings';
import { CheckWhite } from 'assets/icons';
import styles from './CreditPaymentMethod.module.scss';

interface CreditPaymentProps {
  price: number;
  setPaymentsNumber: React.Dispatch<React.SetStateAction<number>>;
  setCreditType: React.Dispatch<React.SetStateAction<LoanType | null>>;
  setDownPayment: React.Dispatch<React.SetStateAction<number>>;
  creditType: LoanType | null;
  downPayment: number;
  paymentsNumber: number;
  income: number;
  insuranceOptions: ProductInsurance[];
  setInsuranceOptions: React.Dispatch<React.SetStateAction<ProductInsurance[]>>;
  selectedInsurances?: ProductInsurance[];
  setSelectedInsurances: React.Dispatch<
    React.SetStateAction<ProductInsurance[] | undefined>
  >;
  selectedProduct?: FinancingProducts;
  setSelectedProduct: React.Dispatch<React.SetStateAction<FinancingProducts | undefined>>;
}

const CreditPaymentMethod: FC<CreditPaymentProps> = (props: CreditPaymentProps) => {
  const {
    price,
    setPaymentsNumber,
    setCreditType,
    setDownPayment,
    creditType,
    downPayment,
    paymentsNumber,
    income,
    insuranceOptions,
    setInsuranceOptions,
    selectedInsurances,
    setSelectedInsurances,
    selectedProduct,
    setSelectedProduct,
  } = props;

  const maxScreen = useMaxBreakpoint();
  const screenSpacings = getScreenSpacings(maxScreen);

  const [creditDetailsModalVisible, setCreditDetailsModalVisible] = useState(false);
  const [smartCreditModalVisible, setSmartCreditModalVisible] = useState(false);

  const [monthlyFee, setMonthlyFee] = useState<number | null>(null);
  const [cae, setCae] = useState<number>(0);
  const [interestRate, setInterestRate] = useState<number>(0);
  const [vfmg, setVfmg] = useState<number>(0);
  const [creditFinalCost, setCreditFinalCost] = useState<number>(0);
  const [financingAmount, setFinancingAmount] = useState<number>(0);

  const currentSubStepIndex = useReactiveVar(currentSubStep);

  const debouncedCreditType = useDebounce<LoanType | null>(creditType);
  const debouncedDownPayment = useDebounce<number>(downPayment);
  const debouncedPaymentsNumber = useDebounce<number>(paymentsNumber);
  const debouncedSelectedInsurance = useDebounce(selectedInsurances);

  const { data: litePurchaseIntentData } = useQuery<{
    litePurchaseIntent: LitePurchaseIntent;
  }>(operations.GET_BASE_LITE_PURCHASE_INTENT);

  const [
    getFinancingProducts,
    { data: financingProductsData, loading: financingProductsLoading },
  ] = useLazyQuery<{
    requestFinancingProducts: FinancingProducts[];
  }>(operations.GET_FINANCING_PRODUCTS);

  const [
    getFinancingSimulation,
    { data: financingSimulationData, loading: financingSimulationLoading },
  ] = useLazyQuery<{
    requestCreditSimulation: CreditSimulation;
  }>(operations.GET_FINANCING_SIMULATION, {
    fetchPolicy: 'no-cache',
  });

  const removeDuplicatesFromArray = (array: unknown[]) => Array.from(new Set(array));

  const isDebounceConditionSatisfied = () => {
    if (
      debouncedDownPayment &&
      debouncedPaymentsNumber &&
      debouncedCreditType &&
      selectedProduct &&
      debouncedSelectedInsurance &&
      selectedInsurances === debouncedSelectedInsurance &&
      downPayment === debouncedDownPayment &&
      paymentsNumber === debouncedPaymentsNumber &&
      creditType === debouncedCreditType
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getFinancingProducts({
      variables: {
        financingProductsInput: {
          income,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (isDebounceConditionSatisfied() && price && creditType && selectedProduct) {
      setMonthlyFee(null);
      getFinancingSimulation({
        variables: {
          financingInput: {
            income: 0,
            downPayment,
            paymentsNumber,
            loanType: creditType,
            productId: selectedProduct?.id,
            insurance: (selectedInsurances || []).map((insurance) => ({
              id: insurance.id,
              name: insurance.name,
            })),
            expenses: selectedProduct?.expenses.map((expense) =>
              _.omit(expense, '__typename')
            ),
          },
        },
      });
    }
  }, [
    debouncedDownPayment,
    debouncedPaymentsNumber,
    debouncedCreditType,
    debouncedSelectedInsurance,
  ]);

  useEffect(() => {
    if (
      selectedProduct &&
      selectedProduct.insurance &&
      !selectedInsurances &&
      litePurchaseIntentData?.litePurchaseIntent
    ) {
      const { legalEntity } = litePurchaseIntentData.litePurchaseIntent;
      const selectedProductInsurance = selectedProduct.insurance.filter(
        (insurance) => !legalEntity || (legalEntity && insurance.id !== 2)
      );
      setSelectedInsurances(selectedProductInsurance);
    }
  }, [selectedProduct, litePurchaseIntentData]);

  useEffect(() => {
    if (
      financingSimulationData &&
      financingSimulationData.requestCreditSimulation &&
      financingSimulationData.requestCreditSimulation.Fees[0].CapitalAmount !== monthlyFee
    ) {
      const solverReponse = financingSimulationData.requestCreditSimulation;
      setMonthlyFee(solverReponse.Fees[0].CapitalAmount);
      setCae(solverReponse.Cae);
      setFinancingAmount(solverReponse.FinancingAmount);
      setCreditFinalCost(
        solverReponse.Fees.reduce(
          (accumulatedAmount, fee) => accumulatedAmount + fee.CapitalAmount,
          0
        )
      );
      setInterestRate(solverReponse.InterestRate);
      if (
        creditType === LoanType.Ci &&
        solverReponse.Fees[solverReponse.Fees.length - 1].VfmgIndicator === 'SI'
      ) {
        setVfmg(solverReponse.Fees[solverReponse.Fees.length - 1].CapitalAmount);
      }
      setInsuranceOptions(solverReponse.Insurance);
    }
  }, [financingSimulationData]);

  return (
    <>
      <CreditDetailsModal
        visible={creditDetailsModalVisible && !!price}
        handleOk={() => setCreditDetailsModalVisible(false)}
        creditInfo={{
          car: '',
          price: price || 0,
          financingAmount,
          monthlyFee: monthlyFee || 0,
          paymentsNumber,
          creditType: creditType as LoanType,
          downPayment,
          interestRate,
          cae,
          DealerCommission:
            financingSimulationData?.requestCreditSimulation?.DealerCommission || 0,
          lastPaymentValue: vfmg,
          finalCost: creditFinalCost,
          insurance: (selectedInsurances || []).map((insurance) => ({
            ...insurance,
            amount: insuranceOptions.find(
              (insuranceData) => insuranceData.id === insurance.id
            )?.amount,
          })),
        }}
      />
      {smartCreditModalVisible && (
        <SmartCreditModal
          visible={smartCreditModalVisible}
          handleOk={() => setSmartCreditModalVisible(false)}
        />
      )}
      {currentSubStepIndex === -1 ? (
        <>
          <Row justify="start" align="middle" className={styles.InputLabel}>
            <h3>Tipo de crédito</h3>
            <Button
              className={styles.InfoButton}
              mode="text"
              icon={<QuestionCircleTwoTone className={styles.InfoCircleTwoTone} />}
              onClick={() => setSmartCreditModalVisible(true)}
            >
              ¿Cuál es la diferencia entre los créditos?
            </Button>
          </Row>
          {financingProductsLoading && (
            <Row justify="center" className={styles.SpinContainer}>
              <Spin indicator={<LoadingOutlined className={styles.SpinLarge} spin />} />
            </Row>
          )}

          {financingProductsData && financingProductsData.requestFinancingProducts && (
            <>
              <Row
                justify="start"
                gutter={[screenSpacings.XL, screenSpacings.XL]}
                className={styles.InputContainer}
              >
                {financingProductsData.requestFinancingProducts.map(
                  (product: FinancingProducts) => (
                    <Col xs={24} sm={12} md={8} key={product.id}>
                      <Row
                        justify="space-between"
                        align="middle"
                        className={[
                          styles.RadioButton,
                          selectedProduct && selectedProduct.id === product.id
                            ? styles.ActiveRadioButton
                            : '',
                        ].join(' ')}
                        onClick={() => {
                          setSelectedProduct(product);
                          const loanType =
                            product.vfmg.length && product.vfmg[0] > 0
                              ? LoanType.Ci
                              : LoanType.Cc;
                          setCreditType(loanType);
                        }}
                      >
                        <span className={styles.RadioText}>
                          <Col span={24}>
                            <Row
                              justify="start"
                              align="middle"
                              className={styles.ProductName}
                            >
                              {product.name}
                            </Row>
                            <Row
                              justify="start"
                              align="middle"
                              className={styles.ProductDescription}
                            >
                              Pie:{' '}
                              {removeDuplicatesFromArray(product.downpaymentPercentages)
                                .length > 1
                                ? `${product.downpaymentPercentages[0]}% a ${product.downpaymentPercentages[1]}%`
                                : `${product.downpaymentPercentages[0]}%`}
                            </Row>
                            <Row
                              justify="start"
                              align="middle"
                              className={styles.ProductDescription}
                            >
                              Nº de cuotas:{' '}
                              {removeDuplicatesFromArray(product.paymentsNumber).length >
                                1
                                ? `${product.paymentsNumber[0]} a ${product.paymentsNumber[1]}`
                                : `${product.paymentsNumber[0]}`}
                            </Row>
                          </Col>
                        </span>
                        <span className={styles.RadioCircleContainer}>
                          <span
                            className={[
                              styles.Circle,
                              selectedProduct && selectedProduct.id === product.id
                                ? styles.ActiveCircle
                                : styles.DisabledCircle,
                            ].join(' ')}
                          >
                            <img
                              src={CheckWhite}
                              alt="active"
                              className={styles.ActiveIcon}
                            />
                          </span>
                        </span>
                      </Row>
                    </Col>
                  )
                )}
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          <Row>
            {selectedProduct && (
              <CreditForm
                selectedInsurances={selectedInsurances}
                setSelectedInsurances={setSelectedInsurances}
                downPayment={downPayment}
                setDownPayment={setDownPayment}
                paymentsNumber={paymentsNumber}
                setPaymentsNumber={setPaymentsNumber}
                price={price}
                insuranceOptions={insuranceOptions}
                selectedProduct={selectedProduct}
              />
            )}
          </Row>
          <Row
            justify="space-between"
            align="middle"
            className={styles.PanelRowContainer}
          >
            <Col xs={24}>
              <Row className={styles.SummaryRow} justify="start">
                <h2 className={styles.SummaryTitle}>Resumen del crédito</h2>
              </Row>
              <Row className={styles.SummaryRow} justify="space-between" align="middle">
                <Col>
                  <p className={styles.SummarySubtitle}>CAE</p>
                </Col>
                <Col>
                  <p className={styles.SummarySubtitle}>Valor cuota</p>
                </Col>
              </Row>
              <Row className={styles.SummaryRow} justify="space-between" align="top">
                <Col>
                  {financingSimulationLoading ? (
                    <Spin indicator={<LoadingOutlined className={styles.Spin} spin />} />
                  ) : (
                    `${cae} %`
                  )}
                </Col>
                <Col>
                  {financingSimulationLoading ? (
                    <Spin
                      indicator={<LoadingOutlined className={styles.SpinLarge} spin />}
                    />
                  ) : (
                    <h4 className={styles.Price}>
                      {monthlyFee ? currencyFormatter.format(monthlyFee) : '-'}
                    </h4>
                  )}
                </Col>
              </Row>
              <Row className={styles.SummaryRow} justify="space-between" align="bottom">
                <Col>
                  <Button
                    className={styles.CreditDetailsButton}
                    disabled={financingSimulationLoading || !monthlyFee}
                    mode="text"
                    icon={<QuestionCircleTwoTone className={styles.InfoCircleTwoTone} />}
                    onClick={() => {
                      if (monthlyFee && !financingSimulationLoading) {
                        setCreditDetailsModalVisible(true);
                      }
                    }}
                  >
                    Ver detalles del crédito
                  </Button>
                </Col>
                <Col>
                  <p className={[styles.SummarySubtitle, styles.NoMargin].join(' ')}>
                    /mensual
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

CreditPaymentMethod.defaultProps = {
  selectedInsurances: undefined,
  selectedProduct: undefined,
};

export default CreditPaymentMethod;
