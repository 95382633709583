import React, { FC, ReactNode } from 'react';
import { Row, Col } from 'antd';
import { Button } from '@cloudcar-app/cloudcar-components';
import styles from './NextButton.module.scss';

interface NextButtonProps {
  headerContent?: ReactNode;
  loading?: boolean;
  onClick?: () => void;
  htmlType?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  text?: string;
  extraAction?: () => void;
  extraText?: string;
  extraDisabled?: boolean;
}

const NextButton: FC<NextButtonProps> = (props: NextButtonProps) => {
  const {
    onClick,
    htmlType,
    disabled,
    text,
    extraAction,
    extraText,
    extraDisabled,
    headerContent,
    loading,
  } = props;
  return (
    <Row justify="end" className={styles.ButtonContainer}>
      {headerContent && (
        <Col xs={24} className={styles.HeaderContent}>
          <p> {headerContent} </p>
        </Col>
      )}
      {extraAction && extraText && (
        <Button
          mode="outline"
          className={styles.ExtraButton}
          onClick={extraAction}
          disabled={extraDisabled}
        >
          {extraText}
        </Button>
      )}
      <Button
        loading={loading}
        className={styles.NextButton}
        htmlType={htmlType}
        onClick={onClick}
        disabled={disabled}
      >
        {text || 'Continuar'}
      </Button>
    </Row>
  );
};

NextButton.defaultProps = {
  headerContent: undefined,
  loading: false,
  onClick: undefined,
  htmlType: undefined,
  disabled: false,
  text: undefined,
  extraAction: undefined,
  extraText: undefined,
  extraDisabled: false,
};

export default NextButton;
