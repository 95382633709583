import { DateTime } from 'luxon';
import { RuleObject } from 'antd/lib/form';
import formatDateToValidJsStringFormat from './formatDateToValidJsStringFormat';

const ageValidator = (age: string): boolean => {
  return DateTime.fromISO(age).diffNow('years').years < -18;
};

const dateFormValidator = (rule: RuleObject, value: string) => {
  const formattedDate = formatDateToValidJsStringFormat(value);

  if (ageValidator(formattedDate)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Debes ser mayor de edad!'));
};

export default dateFormValidator;
