import React, { FC, useEffect } from 'react';
import { selectedFinancialName, logged } from 'apollo/cache';
import { useQuery, useReactiveVar } from '@apollo/client';
import { PurchaseFlowConfiguration } from 'apollo/types';
import operations from 'apollo/operations';

const Component: FC = () => {
  const loggedCache = useReactiveVar(logged);

  const { data, refetch } = useQuery<{
    purchaseFlowConfiguration: PurchaseFlowConfiguration;
  }>(operations.GET_PURCHASE_FLOW_CONFIGURATION, {
    skip: !localStorage.getItem('AccessToken'),
  });

  const setFinancingName = () => {
    if (data?.purchaseFlowConfiguration?.financingConfiguration?.config) {
      const financialEntities =
        data.purchaseFlowConfiguration.financingConfiguration.config;

      const selectedFinancial = financialEntities.find(
        (financialItem) => financialItem.selected
      );

      if (selectedFinancial) {
        selectedFinancialName(selectedFinancial.name);
      }
    }
  };

  const handlePurchaseFlowCall = () => {
    if (loggedCache) {
      refetch();
    }
  };

  useEffect(() => {
    handlePurchaseFlowCall();
  }, [loggedCache]);

  useEffect(() => {
    setFinancingName();
  }, [data]);

  return <></>;
};

export default Component;
