import { Breakpoints } from '@cloudcar-app/cloudcar-components';

const getScreenValue = <T>(maxScreen: number, largeValue: T, smallValue: T): T => {
  if (maxScreen <= Breakpoints.SM) {
    return smallValue;
  }
  if (maxScreen <= Breakpoints.MD) {
    return largeValue;
  }
  if (maxScreen <= Breakpoints.LG) {
    return smallValue;
  }

  return largeValue;
};

export default getScreenValue;
