import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'components/ui';
import { isUserLogged } from 'utils/checkUserStatus';

interface PublicRouteProps {
  exact: boolean;
  path: string;
  component: React.FC;
}

const PublicRoute = ({
  exact,
  path,
  component: Component,
}: PublicRouteProps): JSX.Element => {
  if (isUserLogged()) {
    return <Redirect to="/" />;
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={() => (
        <Layout>
          <Component />
        </Layout>
      )}
    />
  );
};

export default PublicRoute;
