import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row } from 'antd';
import { ProgressSteps } from '@cloudcar-app/cloudcar-components';
import { useReactiveVar } from '@apollo/client';
import { CreditStates, LocationState } from 'apollo/types';
import { stepList, currentSubStep, isAppLoading, loadingCredit } from 'apollo/cache';
import { ComponentContainer, Layout, LayoutAnimation } from 'components/ui';
import { CreditLoadingModal } from 'components/credit';
import getStepIndexByPathname from 'utils/getStepIndexByPathname';
import isClickableStep from 'utils/isClickableStep';
import styles from './StepRenderer.module.scss';

interface StepRendererProps {
  step: number;
}

interface StepComponent {
  component: FC<unknown>;
  type: string;
  title: string;
}

const StepRenderer: FC<StepRendererProps> = (props: StepRendererProps) => {
  const { step } = props;
  const history = useHistory();
  const location = useLocation<LocationState>();
  const steps = useReactiveVar(stepList);
  const [animationDirectionForward, setAnimationDirectionForward] = useState(true);
  const [skipAnimation, setSkipAnimation] = useState(false);
  const creditState = useReactiveVar(loadingCredit);
  const [showLoadingFinancingModal, setShowLoadingFinancingModal] = useState(false);

  const setUpAnimationBehavior = () => {
    if (location.state?.from?.pathname) {
      const previousStepIndex = getStepIndexByPathname(
        location.state?.from?.pathname,
        steps
      );
      if (previousStepIndex > step) {
        setAnimationDirectionForward(false);
      } else {
        setAnimationDirectionForward(true);
      }
    } else if (location.pathname === '/') {
      setSkipAnimation(true);
    } else {
      setAnimationDirectionForward(true);
    }
  };

  const renderStep = (wrappedStep: StepComponent) => {
    const { component: WrappedComponent, type, title } = wrappedStep;

    const content = (
      <ComponentContainer type={type}>
        <WrappedComponent />
      </ComponentContainer>
    );

    return (
      <LayoutAnimation
        skipAnimation={skipAnimation}
        animationDirectionForward={animationDirectionForward}
        title={title}
      >
        {content}
      </LayoutAnimation>
    );
  };

  const handleClickStep = (targetStepNumber: number) => {
    currentSubStep(-1);
    history.push({
      pathname: steps[targetStepNumber].route,
      state: {
        from: history.location,
      },
    });
  };

  const setUpFinancingModalsBehaviour = () => {
    setShowLoadingFinancingModal(creditState !== CreditStates.Draft);
  };

  useEffect(() => {
    setUpAnimationBehavior();
  }, [step]);

  useEffect(() => {
    setUpFinancingModalsBehaviour();
  }, [creditState]);

  useEffect(() => {
    isAppLoading(false);
  }, []);

  return (
    <Layout>
      <Row className={styles.StepsContainer}>
        <ProgressSteps
          steps={steps}
          currentStepNumber={step}
          numberOfSteps={steps.length}
          onClickStep={(targetStepNumber: number) => {
            if (
              isClickableStep({
                targetStepNumber,
                currentStep: step,
                numberOfSteps: steps.length,
              })
            ) {
              handleClickStep(targetStepNumber);
            }
          }}
        />
        {(steps[step].route === '/summary' ||
          steps[step].route === '/purchaseIntentDetails') &&
          showLoadingFinancingModal && <CreditLoadingModal />}
      </Row>

      <div className={styles.StepContainer}>
        {renderStep({
          component: steps[step].component,
          type: steps[step].type,
          title: steps[step].title,
        })}
      </div>
    </Layout>
  );
};

export default StepRenderer;
