/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '@cloudcar-app/cloudcar-components';
import { useReactiveVar } from '@apollo/client';
import { currentSubStep, stepList } from 'apollo/cache';
import { CustomSubStep } from 'apollo/types';
import getStepIndexByPathname from 'utils/getStepIndexByPathname';
import { BackArrow } from 'assets/icons';
import styles from './ComponentContainer.module.scss';

interface ComponentContainerProps {
  children?: React.ReactNode;
  type: string;
}

const ComponentContainer: FC<ComponentContainerProps> = (
  props: ComponentContainerProps
) => {
  const { children, type } = props;
  const history = useHistory();

  const steps = useReactiveVar(stepList);
  const currentStepIndex = getStepIndexByPathname(history.location.pathname, steps);
  const currentSubStepIndex = useReactiveVar(currentSubStep);

  const previous = () => {
    if (currentStepIndex > 0 && currentStepIndex < steps.length - 1) {
      if (steps[currentStepIndex]?.subSteps?.length && currentSubStepIndex > -1) {
        currentSubStep(currentSubStepIndex - 1);
        if (
          currentSubStepIndex === 0 &&
          steps[currentStepIndex].route === '/extraItems'
        ) {
          history.push({
            pathname: steps[currentStepIndex - 1].route,
            state: {
              from: history.location,
            },
          });
        }
      } else {
        if (steps[currentStepIndex - 1]?.subSteps?.length) {
          currentSubStep(
            (steps[currentStepIndex - 1].subSteps as CustomSubStep[]).length - 1
          );
        } else {
          currentSubStep(-1);
        }
        history.push({
          pathname: steps[currentStepIndex - 1].route,
          state: {
            from: history.location,
          },
        });
      }
    }
  };
  const { title } = steps[currentStepIndex];
  const subTitle = steps[currentStepIndex].subtitle;

  const setHeaderTitle = () => {
    if (steps[currentStepIndex].subSteps?.length && currentSubStepIndex > -1) {
      const subSteps = steps[currentStepIndex].subSteps as CustomSubStep[];
      return subSteps[currentSubStepIndex]?.title;
    }
    return title;
  };

  const setHeaderSubTitle = () => {
    if (steps[currentStepIndex].subSteps?.length && currentSubStepIndex > -1) {
      const subSteps = steps[currentStepIndex].subSteps as CustomSubStep[];
      return subSteps[currentSubStepIndex]?.subTitle;
    }
    return subTitle;
  };

  const subStepsLength = steps[currentStepIndex].subSteps
    ? steps[currentStepIndex].subSteps!.length
    : 0;
  const viewTitle =
    subStepsLength > 0 && currentSubStep() > -1
      ? steps[currentStepIndex].subSteps![currentSubStep()].viewTitle
      : '';

  return (
    <div className={styles.ComponentContainer}>
      {type !== 'summary' && (
        <PageHeader
          title={viewTitle || setHeaderTitle()}
          subTitle={setHeaderSubTitle()}
          onBack={previous}
          backIcon={
            currentStepIndex > 0 && currentStepIndex < steps.length - 1 ? (
              <img
                src={BackArrow}
                alt="Volver al paso anterior"
                className={styles.BackArrow}
              />
            ) : (
              false
            )
          }
        />
      )}
      {children}
    </div>
  );
};

ComponentContainer.defaultProps = {
  children: undefined,
};

export default ComponentContainer;
