import { message } from 'antd';
import { showErrorModal, errorData, errorList } from 'apollo/cache';
import { CustomError } from 'apollo/types';

const messageDuration = 4;

const ErrorHandler = (error: CustomError): void => {
  errorList([...errorList(), error]);
  if (error.type === 'FATAL') {
    const parsedError = {
      ...error,
      name: error.codeMessage ? error.codeMessage.code : error.name,
      message: error.codeMessage ? undefined : error.message,
    };
    showErrorModal(true);
    errorData(parsedError);
  } else {
    errorData(error);
    const { codeMessage } = error;

    const BannerMessage = codeMessage
      ? `Error - ${codeMessage.code}: ${codeMessage.message || ''}`
      : `Error: ${error.name} - ${error.message || ''}`;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.warning(BannerMessage, messageDuration, () => errorData(null));
  }
};

export default ErrorHandler;
