import { RuleObject } from 'antd/lib/form';

const currencyValueValidator = (rule: RuleObject, value: string): Promise<void> => {
  if (!Number.isNaN(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Ingrese un número!'));
};

export default currencyValueValidator;
