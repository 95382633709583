import React, { FC } from 'react';
import Styles from './Styles';
import Car from './Car';
import Concessionaire from './Concessionaire';
import PaymentMethod from './PaymentMethod';

const StateManager: FC = () => {
  return (
    <>
      <Concessionaire />
      <Styles />
      <Car />
      <PaymentMethod />
    </>
  );
};

export default StateManager;
