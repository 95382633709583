import { gql } from '@apollo/client';

const GET_USER = gql`
  query getUser($cognitoId: String!) {
    getUser(cognitoId: $cognitoId) {
      username
      name
      email
      role
      concessionaireId
      firstName
      dealer
    }
  }
`;

const INVITE_USER = gql`
  mutation inviteUser($inviteUserInput: InviteUserInput!) {
    inviteUser(inviteUserInput: $inviteUserInput)
  }
`;

const GET_ALL_GUEST_USERS = gql`
  query GetAllGuestUsers($concessionaireName: String!) {
    listGuestUser(concessionaireName: $concessionaireName) {
      dealer
      email
      ExpirationDate
      concessionaireName
      signupToken
    }
  }
`;

const GET_ALL_USERS = gql`
  query GetAllUsers {
    listUser {
      name
      email
      username
      dealer
    }
  }
`;

const DELETE_SIGN_UP_EMAIL = gql`
  mutation DeleteSignUpInvitation($deleteSignUpInput: String!) {
    deleteSignUpInvitation(deleteSignUpInput: $deleteSignUpInput)
  }
`;

const userOperations = {
  DELETE_SIGN_UP_EMAIL,
  GET_ALL_USERS,
  GET_ALL_GUEST_USERS,
  INVITE_USER,
  GET_USER,
};

export default userOperations;
