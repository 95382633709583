import React, { FC } from 'react';
import { Modal, Row } from 'antd';
import {
  Breakpoints,
  Scrollable,
  useMaxBreakpoint,
} from '@cloudcar-app/cloudcar-components';
import styles from './SmartCreditModal.module.scss';

interface SmartCreditModalProps {
  visible: boolean;
  handleOk: () => void;
}

const SmartCreditModal: FC<SmartCreditModalProps> = (props: SmartCreditModalProps) => {
  const { visible, handleOk } = props;
  const maxCurrentScreen = useMaxBreakpoint();

  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleOk}
      footer={null}
      width={maxCurrentScreen < Breakpoints.LG ? '80%' : '60%'}
    >
      <Scrollable scrollY className="CloudCarModal">
        <Row justify="center">
          <h1 className={styles.ModalTitle}>¿Cuál es la diferencia?</h1>
        </Row>
        <Row justify="start" className={styles.ModalText}>
          <h2>Crédito convencional</h2>
        </Row>
        <p className={styles.ModalInternalParagraph}>
          Este producto se caracteriza por tener las siguientes condiciones:
        </p>
        <h3 className={styles.ModalSubtitle}>Condiciones:</h3>
        <ul className={styles.List}>
          <li>
            <p className={styles.ModalParagraph}>
              Es un crédito de cuotas iguales las cuales serán canceladas mensual y
              consecutivamente.
            </p>
          </li>
          <li>
            <p className={styles.ModalParagraph}>
              En un plazo determinado de 12 a 60 meses.
            </p>
          </li>
          <li>
            <p className={styles.ModalParagraph}>
              Es un producto destinado para el financiamiento de vehículos nuevo y usados.
            </p>
          </li>
          <li>
            <p className={styles.ModalParagraph}>
              Para este producto el crédito deberá contar con un pie mínimo del 20%.
            </p>
          </li>
        </ul>
        <Row justify="start" className={styles.LastModalText}>
          <h2>Crédito inteligente</h2>
        </Row>
        <p className={styles.ModalInternalParagraph}>
          Este producto se caracteriza por tener las siguientes condiciones:
        </p>
        <h3 className={styles.ModalSubtitle}>Condiciones:</h3>
        <ul className={styles.List}>
          <li>
            <p className={styles.ModalParagraph}>
              Financiamiento de vehículos livianos nuevos o semi nuevos (hasta tres años).
            </p>
          </li>
          <li>
            <p className={styles.ModalParagraph}>
              Plazo del crédito es de 24 meses más un eventual V.F.M.G(valor futuro mínimo
              garantizado) o 36 meses más el eventual V.F.M.G par este plazo.
            </p>
          </li>
          <li>
            <p className={styles.ModalParagraph}>Pie del 20% o 30%.</p>
          </li>
          <li>
            <p className={styles.ModalParagraph}>
              El V.F.M.G o cuotón final se podrá optar por las siguientes alternativas:
              Renovar o quedarse con el vehículo.
            </p>
          </li>
        </ul>
      </Scrollable>
    </Modal>
  );
};

export default SmartCreditModal;
