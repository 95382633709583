import { StepInfo } from 'apollo/types';
import PurchaseIntentForm from 'views/PurchaseIntentForm';
import PurchaseOptions from 'views/PurchaseOptions';
import PersonalInformation from 'views/PersonalInformation';
import PurchaseIntentDetails from 'views/PurchaseIntentDetails';

const carPurchaseSteps = [
  {
    title: 'Información del vehículo',
    subtitle: '',
    type: 'main',
    route: '/newPurchaseIntent',
    component: PurchaseIntentForm,
  },
  {
    queryName: 'paymentMethod',
    title: 'Tipo de compra',
    subtitle: 'Selecciona el tipo de compra',
    type: 'main',
    route: '/purchaseOptions',
    component: PurchaseOptions,
  },
  {
    queryName: 'customer',
    title: 'Datos personales',
    subtitle: 'Rellena todos los campos',
    type: 'main',
    route: '/personalInformation',
    component: PersonalInformation,
  },
  {
    title: 'Resumen',
    subtitle: '',
    type: 'main',
    route: '/purchaseIntentDetails',
    component: PurchaseIntentDetails,
  },
];

const steps: StepInfo[] = carPurchaseSteps;

export default steps;
