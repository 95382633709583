import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'antd';
import {
  currencyFormatter,
  CustomerSummaryTile,
  PageHeader,
  Tile,
  useMaxBreakpoint,
} from '@cloudcar-app/cloudcar-components';
import { useQuery, useReactiveVar } from '@apollo/client';
import { selectedFinancialName, selectedPurchaseIntent } from 'apollo/cache';
import { LitePurchaseIntent, LoanType } from 'apollo/types';
import operations from 'apollo/operations';
import dateFormatter from 'utils/dateFormatter';
import getScreenSpacings from 'utils/getScreenSpacings';

const PurchaseIntentDetails: FC = () => {
  const history = useHistory();
  const maxScreen = useMaxBreakpoint();
  const spacings = getScreenSpacings(maxScreen);
  const currentPurchaseIntent = useReactiveVar(selectedPurchaseIntent);
  const selectedFinancialNameValue = useReactiveVar(selectedFinancialName);
  const [currentPurchaseIntentData, setCurrentPurchaseIntentData] =
    useState<LitePurchaseIntent | null>(null);

  const { data, loading } = useQuery<{ unitedLitePurchaseIntent: LitePurchaseIntent }>(
    operations.GET_LITE_PURCHASE_INTENT,
    {
      skip: !localStorage.getItem('AccessToken'),
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (!currentPurchaseIntent && (!data || !data.unitedLitePurchaseIntent) && !loading) {
      history.push('/');
    }
    if (currentPurchaseIntent) {
      setCurrentPurchaseIntentData(currentPurchaseIntent);
    } else if (data && data.unitedLitePurchaseIntent) {
      setCurrentPurchaseIntentData(data.unitedLitePurchaseIntent);
    }
  }, [data, loading, currentPurchaseIntent]);

  if (currentPurchaseIntentData) {
    const { car, paymentMethod, customer } = currentPurchaseIntentData;

    return (
      <>
        <Row justify="space-between" align="middle">
          <PageHeader
            title={`Detalle de solicitud - ${
              currentPurchaseIntentData.createdAt
                ? dateFormatter(currentPurchaseIntentData.createdAt)
                : ''
            }`}
            onBack={() => {
              history.push({
                pathname: '/',
                state: {
                  from: history.location,
                },
              });
            }}
          />
        </Row>
        <div className="PurchaseSummaryContainer">
          <Row gutter={spacings.XXL}>
            <Col xs={24} lg={16}>
              {car && (
                <Tile title="Detalle del vehículo" removeShadow>
                  <Row justify="space-between">
                    <Col>
                      <p className="PurchaseSummaryCardInfo">Marca</p>
                    </Col>
                    <Col>
                      <p className="PurchaseSummaryCardInfo">{car.brand}</p>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>
                      <p className="PurchaseSummaryCardInfo">Modelo</p>
                    </Col>
                    <Col>
                      <p className="PurchaseSummaryCardInfo">{car.model}</p>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>
                      <p className="PurchaseSummaryCardInfo">Año</p>
                    </Col>
                    <Col>
                      <p className="PurchaseSummaryCardInfo">{car.year}</p>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>
                      <p className="PurchaseSummaryCardInfo">Versión</p>
                    </Col>
                    <Col>
                      <p className="PurchaseSummaryCardInfo">{car.version}</p>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>
                      <p className="PurchaseSummaryCardInfo">Precio de venta</p>
                    </Col>
                    <Col>
                      <p className="PurchaseSummaryCardInfo">
                        {currencyFormatter().format(car.price || 0)}
                      </p>
                    </Col>
                  </Row>
                </Tile>
              )}
              {paymentMethod && paymentMethod.financing && (
                <Tile title="Detalle del financiamiento" removeShadow>
                  {paymentMethod.financing && (
                    <>
                      <Row justify="space-between">
                        <Col>
                          <p className="PurchaseSummaryCardInfo">Financiera</p>
                        </Col>
                        <Col>
                          <p className="PurchaseSummaryCardInfo">
                            {selectedFinancialNameValue}
                          </p>
                        </Col>
                      </Row>
                      <Row justify="space-between">
                        <Col>
                          <p className="PurchaseSummaryCardInfo">Tipo de crédito</p>
                        </Col>
                        <Col>
                          <p className="PurchaseSummaryCardInfo">
                            {paymentMethod.financing.financingInput.loanType ===
                            LoanType.Ci
                              ? 'Inteligente'
                              : 'Convencional'}
                          </p>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row justify="space-between">
                    <Col>
                      <p className="PurchaseSummaryCardInfo">Pie</p>
                    </Col>
                    <Col>
                      <p className="PurchaseSummaryCardInfo">
                        {currencyFormatter().format(
                          paymentMethod.financing.financingInput.downPayment
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>
                      <p className="PurchaseSummaryCardInfo">Número de cuotas</p>
                    </Col>
                    <Col>
                      <p className="PurchaseSummaryCardInfo">
                        {paymentMethod.financing.financingInput.paymentsNumber}
                      </p>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>
                      <p className="PurchaseSummaryCardInfo">Monto a financiar</p>
                    </Col>
                    <Col>
                      <p className="PurchaseSummaryCardInfo">
                        {currencyFormatter().format(
                          paymentMethod.financing.financingData.FinancingAmount
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>
                      <p className="PurchaseSummaryCardInfo">Tasa</p>
                    </Col>
                    <Col>
                      <p className="PurchaseSummaryCardInfo">
                        {paymentMethod.financing.financingData.InterestRate}%
                      </p>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>
                      <p className="PurchaseSummaryCardInfo">CAE</p>
                    </Col>
                    <Col>
                      <p className="PurchaseSummaryCardInfo">
                        {paymentMethod.financing.financingData.Cae}%
                      </p>
                    </Col>
                  </Row>
                  {paymentMethod.financing.financingData?.DealerCommission && (
                    <Row justify="space-between">
                      <Col>
                        <p className="PurchaseSummaryCardInfo">Comisión del dealer</p>
                      </Col>
                      <Col>
                        <p className="PurchaseSummaryCardInfo">
                          {currencyFormatter().format(
                            paymentMethod.financing.financingData.DealerCommission
                          )}
                        </p>
                      </Col>
                    </Row>
                  )}
                  <Row
                    justify="space-between"
                    className={
                      paymentMethod.financing.financingInput.loanType === LoanType.Cc
                        ? 'PurchaseSummaryLastCardInfo'
                        : ''
                    }
                  >
                    <Col>
                      <p className="PurchaseSummaryCardInfo">Valor cuota</p>
                    </Col>
                    <Col>
                      <p className="PurchaseSummaryCardInfo">
                        {currencyFormatter().format(
                          paymentMethod.financing.financingData.Fees[0].CapitalAmount
                        )}
                      </p>
                    </Col>
                  </Row>
                  {paymentMethod.financing.financingInput.loanType === LoanType.Ci && (
                    <Row justify="space-between" className="PurchaseSummaryLastCardInfo">
                      <Col>
                        <p className="PurchaseSummaryCardInfo">
                          Valor última cuota (VFMG)
                        </p>
                      </Col>
                      <Col>
                        <p className="PurchaseSummaryCardInfo">
                          {paymentMethod.financing.financingData.Fees[
                            paymentMethod.financing.financingData.Fees.length - 1
                          ].VfmgIndicator === 'SI'
                            ? currencyFormatter().format(
                                paymentMethod.financing.financingData.Fees[
                                  paymentMethod.financing.financingData.Fees.length - 1
                                ].CapitalAmount
                              )
                            : '$ -'}
                          {}
                        </p>
                      </Col>
                    </Row>
                  )}
                  <hr className="PurchaseSummaryDivider" />
                  <Row justify="space-between" className="PurchaseSummaryLastCardInfo">
                    <Col>
                      <p className="PurchaseSummaryCardSubtitle SummaryTotal">
                        Costo total del crédito
                      </p>
                    </Col>
                    <Col>
                      <p className="PurchaseSummaryCardSubtitle SummaryTotal">
                        {currencyFormatter().format(
                          paymentMethod.financing.financingData.Fees.reduce(
                            (accumulatedAmount, fee) =>
                              accumulatedAmount + fee.CapitalAmount,
                            0
                          )
                        )}
                      </p>
                    </Col>
                  </Row>
                </Tile>
              )}
            </Col>
            <Col xs={24} lg={8}>
              {customer && (
                <CustomerSummaryTile
                  title="Detalle del cliente"
                  hideTileBackground
                  showEmail
                  showPhone
                  showAddress
                  data={
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    {
                      purchaseIntentId: 'purchaseIntentId',
                      ...customer,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any
                  }
                />
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
  return <></>;
};

export default PurchaseIntentDetails;
