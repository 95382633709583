import React, { FC, useEffect, useState } from 'react';
import { Alert } from 'antd';
import {
  CheckCircleOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { PaymentMethod, FinancingStatus, CreditStates } from 'apollo/types';
import { useQuery, useReactiveVar } from '@apollo/client';
import operations from 'apollo/operations';
import { loadingCredit } from 'apollo/cache';
import styles from './CreditLoadingModal.module.scss';

const creditMessages = {
  [CreditStates.Loading]:
    'Estamos validando el crédito, te notificaremos cuando esté listo.',
  [CreditStates.Approved]: 'El crédito fue aprobado!',
  [CreditStates.Rejected]:
    'Los parametros del crédito se encuentran en revisión, nuestro equipo se pondrá en contacto contigo en las próximas horas.',
};

const CreditLoadingdModal: FC = () => {
  const creditState = useReactiveVar(loadingCredit);

  const [message, setMessage] = useState(creditMessages[CreditStates.Loading]);
  // const [financingDetails, setFinancingDetails] = useState<string[] | null>(null);

  const { data: queryPaymentMethodData } = useQuery<{
    paymentMethod: PaymentMethod;
  }>(operations.GET_PAYMENT_METHOD, {
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
  });

  useEffect(() => {
    if (
      queryPaymentMethodData &&
      queryPaymentMethodData.paymentMethod &&
      queryPaymentMethodData.paymentMethod.financing
    ) {
      const creditResponse =
        queryPaymentMethodData.paymentMethod.financing.financingStatus;
      if (creditResponse === FinancingStatus.Approved) {
        loadingCredit(CreditStates.Approved);
        setMessage(creditMessages[CreditStates.Approved]);
        // stopPolling();
      } else if (creditResponse === FinancingStatus.Rejected) {
        setMessage(creditMessages[CreditStates.Rejected]);
        loadingCredit(CreditStates.Rejected);
        // stopPolling();
      }
    }
  }, [queryPaymentMethodData]);

  // useEffect(() => {
  //   if (
  //     queryPaymentMethodData?.paymentMethod?.financing?.financingStatusDetails?.motives
  //   ) {
  //     setFinancingDetails(
  //       queryPaymentMethodData.paymentMethod.financing.financingStatusDetails.motives
  //     );
  //   }
  // }, [queryPaymentMethodData]);

  const getAlertIcon = () => {
    return (
      <div className={styles.AlertIcon}>
        {creditState === CreditStates.Loading && <LoadingOutlined />}
        {creditState === CreditStates.Approved && (
          <span className={styles.SuccessIcon}>
            <CheckCircleOutlined />
          </span>
        )}
        {creditState === CreditStates.Rejected && (
          <span className={styles.RejectedIcon}>
            <ExclamationCircleOutlined />
          </span>
        )}
      </div>
    );
  };
  return (
    <div className={styles.AlertContainer}>
      <Alert
        icon={getAlertIcon()}
        showIcon
        message={message}
        className={
          styles[`${creditState.charAt(0)}${creditState.toLowerCase().slice(1)}Modal`]
        }
      />
      {/* {financingDetails && creditState === CreditStates.Approved && (
        <Alert
          type="warning"
          message={
            <div>
              <h3> Detalles </h3>
              {financingDetails.map((detail) => (
                <div key={detail}>{detail}</div>
              ))}
            </div>
          }
        />
      )} */}
    </div>
  );
};

export default CreditLoadingdModal;
