import { gql } from '@apollo/client';

const GET_PURCHASE_FLOW_CONFIGURATION = gql`
  query purchaseFlowConfiguration {
    purchaseFlowConfiguration {
      concessionaireId
      concessionaireName
      financingConfiguration {
        enabled
        config {
          selected
          name
        }
      }
    }
  }
`;

const GET_CONCESSIONAIRE_STYLE = gql`
  query concessionaireStyle {
    concessionaireStyle {
      primaryColor
      concessionaireLogoUrl
      concessionaireIsoLogoUrl
    }
  }
`;

const configurationOperations = {
  GET_PURCHASE_FLOW_CONFIGURATION,
  GET_CONCESSIONAIRE_STYLE,
};

export default configurationOperations;
