/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/require-await */
import React, { FC, useState, useEffect } from 'react';
import { Dropdown, Menu, message, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { MoreOutlined } from '@ant-design/icons';
import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';
import operations from 'apollo/operations';
import { GuestUser } from 'apollo/types';
import { DeleteModal } from 'components/modals';
import getDifferenceBetweenDatesInDays from 'utils/getDifferenceBetweenDatesInDays';
import styles from './InvitationTable.module.scss';

interface InvitationTableProps {
  loading: boolean;
  data: GuestUser[];
  refetchUsers: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      listGuestUser: GuestUser[];
    }>
  >;
}

const InvitationTable: FC<InvitationTableProps> = (props: InvitationTableProps) => {
  const { loading, data, refetchUsers } = props;
  const [deletionUser, setDeletionUser] = useState<GuestUser | null>(null);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);

  const [deleteSignUpEmail, { data: deleteEmailResponse }] = useMutation<
    { responseDelete: string },
    { deleteSignUpInput: string }
  >(operations.DELETE_SIGN_UP_EMAIL);

  const handleDeleteSignUpEmail = async (user: GuestUser) => {
    const { host } = window.location;
    const concessionaire = host.split('.')[0];

    await deleteSignUpEmail({
      variables: {
        deleteSignUpInput: user.signupToken,
      },
    });
    await refetchUsers({
      concessionaireName: concessionaire === 'financing' ? 'CloudCar' : 'Tanner',
    });
    setShowModalDelete(false);
  };

  const sendDeleteMessage = () => {
    if (deleteEmailResponse) {
      message.warning(`Se eliminó la invitación de ${deletionUser?.email || ''}`, 3);
    }
  };

  const renderExpirationDate = (user: GuestUser) => {
    const expirationDate = user.ExpirationDate * 1000;

    const differenceInDays = getDifferenceBetweenDatesInDays(expirationDate);

    return (
      <span className={styles.Span}>
        {differenceInDays === 1 ? `${differenceInDays} día` : `${differenceInDays} días`}
      </span>
    );
  };

  useEffect(() => {
    sendDeleteMessage();
  }, [deleteEmailResponse]);

  const actionColumn = (user: GuestUser) => (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item
            key="delete"
            onClick={() => {
              setDeletionUser(user);
              setShowModalDelete(true);
            }}
          >
            <span>Eliminar</span>
          </Menu.Item>
        </Menu>
      }
      placement="bottomRight"
      arrow
    >
      <MoreOutlined style={{ fontSize: '24px' }} />
    </Dropdown>
  );

  const columns: ColumnsType<GuestUser> = [
    {
      title: <h3>Email</h3>,
      render: (user: GuestUser) => <span className={styles.Span}>{user.email}</span>,
      sorter: (a: GuestUser, b: GuestUser) => {
        const firstUserEmail = a.email ? a.email : 'Usuario sin mail';
        const secondUserEmail = b.email ? b.email : 'Usuario sin mail';
        return firstUserEmail.localeCompare(secondUserEmail);
      },
    },
    {
      title: <h3>Dealer</h3>,
      render: (user: GuestUser) => <span className={styles.Span}>{user.dealer}</span>,
      sorter: (a: GuestUser, b: GuestUser) => {
        const firstUserDealer = a.dealer ? a.dealer : 'Usuario sin dealer';
        const secondUserDealer = b.dealer ? b.dealer : 'Usuario sin dealer';
        return firstUserDealer.localeCompare(secondUserDealer);
      },
    },
    {
      title: 'Expiración',
      render: (user: GuestUser) => renderExpirationDate(user),
      sorter: (a: GuestUser, b: GuestUser) => a.ExpirationDate - b.ExpirationDate,
    },
    {
      align: 'right',
      render: (user: GuestUser) => actionColumn(user),
    },
  ];

  return (
    <Row className={styles.TableContainer}>
      <DeleteModal
        visible={showModalDelete}
        handleOk={() => (deletionUser ? handleDeleteSignUpEmail(deletionUser) : null)}
        handleCancel={() => setShowModalDelete(false)}
        subject="invitación"
        subjectGender="F"
        additionalMessage="El correo de invitación ya fue enviado. De todas formas, el usuario no podrá crearse una cuenta."
      />
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey={(user: GuestUser) => user.email}
      />
    </Row>
  );
};

export default InvitationTable;
