/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, message } from 'antd';
import { Button, Input } from '@cloudcar-app/cloudcar-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { concessionaireLogoUrl } from 'apollo/cache';
import apiService from 'utils/apiService';
import MessageError from 'utils/message.errors';
import comparePasswords from 'utils/comparePasswordsValidator';
import styles from './RecoverPassword.module.scss';

interface RecoverPasswordParams {
  password: string;
}

const RecoverPassword: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [requested, setRequested] = useState(false);
  const { search } = useLocation();
  const confirmationCode = new URLSearchParams(search).get('code') as string;
  const username = new URLSearchParams(search).get('username') as string;

  const logoUrl = useReactiveVar(concessionaireLogoUrl);

  const sendForm = async (values: RecoverPasswordParams) => {
    try {
      setRequested(true);
      const data = {
        username,
        password: values.password,
        confirmationCode,
      };
      await apiService.confirmForgotPasswordRequest(data);
      setRequested(false);
      message.loading('Contraseña restablecida exitosamente.', 3);
      history.push('/');
    } catch (err) {
      setRequested(false);
      message.loading('Error', 1);
    }
  };

  const renderPageTitle = () => {
    return 'Cotizador Tanner en línea!';
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <Row className={styles.Container}>
      <div className={styles.Background} />
      <Col xs={0} sm={8} className={styles.FullHeight}>
        <Row justify="center" align="middle" className={styles.FullSize}>
          <Col xs={16}>
            {logoUrl && (
              <Row justify="center" align="middle" className={styles.MarginBottom}>
                <img src={logoUrl} alt="logo" width="100" />
              </Row>
            )}
            <Row justify="center" align="middle">
              <h1 className={styles.CenteredText}>{renderPageTitle()}</h1>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={16} className={styles.FullHeight}>
        <Row justify="center" align="middle" className={styles.FullHeight}>
          <Col>
            <Form form={form} name="recoverPassword" onFinish={sendForm}>
              \
              <Row justify="center" align="middle" className={styles.RecoverPassword}>
                <h1 className={styles.Title}>Restablece tu contraseña</h1>
                <Col xs={24} className={styles.MarginTop}>
                  <Form.Item
                    name="password"
                    className={styles.FormItem}
                    rules={[
                      {
                        required: true,
                        message: MessageError.emptyFieldsMessages.password,
                      },
                      {
                        whitespace: true,
                        message: MessageError.emptyFieldsMessages.password,
                      },
                      {
                        min: 6,
                        message:
                          MessageError.invalidFormatFieldsMessages.passwordMinLength,
                      },
                    ]}
                  >
                    <Input password label="Contraseña" placeholder="*********" required />
                  </Form.Item>
                </Col>
                <Col xs={24} className={styles.MarginTop}>
                  <Form.Item
                    name="confirmPassword"
                    className={styles.FormItem}
                    rules={[
                      {
                        required: true,
                        message: MessageError.emptyFieldsMessages.confirmPassword,
                      },
                      {
                        whitespace: true,
                        message: MessageError.emptyFieldsMessages.confirmPassword,
                      },
                      {
                        validator: (rule, value) =>
                          comparePasswords(rule, value as string, form),
                        message: MessageError.invalidFormatFieldsMessages.passwordNoMatch,
                      },
                    ]}
                  >
                    <Input
                      password
                      label="Confirmar contraseña"
                      placeholder="*********"
                      required
                    />
                  </Form.Item>
                </Col>
                <Row className={styles.ButtonContainer} justify="center">
                  <Button
                    htmlType="submit"
                    size="large"
                    loading={requested}
                    disabled={requested}
                  >
                    Cambiar contraseña
                  </Button>
                </Row>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RecoverPassword;
