/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-restricted-globals */
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  Button,
  PageHeader,
  Pagination,
  Select,
} from '@cloudcar-app/cloudcar-components';
import { formatRut, validateRut } from '@abstract-cl/rut-ts';
import { useLazyQuery } from '@apollo/client';
import {
  Customer,
  LitePurchaseIntent,
  LitePurchaseIntentConnection,
  LitePurchaseIntentCursor,
  LitePurchaseIntentCursorInput,
  PaymentMethod,
} from 'apollo/types';
import operations from 'apollo/operations';
import { customerRut, selectedPurchaseIntent } from 'apollo/cache';
import dateFormatter from 'utils/dateFormatter';
import {
  checkUserSuperAdminFromToken,
  getDealersIdFromIdToken,
} from 'utils/getDataFromJwt';
import { PurchaseIntentRangePicker } from 'components/purchaseIntentList';
import dayjs from 'dayjs';
import styles from './PurchaseIntentList.module.scss';

enum CreditStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  DRAFT = 'DRAFT',
}

const PurchaseIntentList: FC = () => {
  const history = useHistory();
  const [nextCursor, setNextCursor] = useState<
    LitePurchaseIntentCursor | undefined | null
  >(undefined);

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(-1);
  const [cursorList, setCursorList] = useState<LitePurchaseIntentCursor[]>([]);
  const [hasPrevPage, setHasPrevPage] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [showRangePickerModal, setShowRangePickerModal] = useState(false);

  const [startDateString, setStartDateString] = useState<string>(
    dayjs().startOf('day').toISOString()
  );

  const [endDateString, setEndDateString] = useState<string>(dayjs().toISOString());

  const [litePurchaseIntentList, setLitePurchaseIntentList] = useState<
    null | LitePurchaseIntent[]
  >(null);

  const [filterCreditStatus, setFilterCreditStatus] = useState<null | CreditStatus>(null);

  const [showLitePurchaseIntentDownloadButton, setShowLitePurchaseIntentDownloadButton] =
    useState(false);

  const [showUserButton, setShowUserButton] = useState(false);

  const [
    listPurchaseIntentInCsv,
    { data: listLitePurchaseIntentToCsvData, loading: csvLitePurchaseIntentLoading },
  ] = useLazyQuery<{
    listLitePurchaseIntentToCsv: string;
  }>(operations.LIST_LITE_PURCHASE_INTENT_TO_CSV, {
    fetchPolicy: 'cache-and-network',
  });

  const [listPurchaseIntent, { data: liteData, loading: liteLoading }] = useLazyQuery<{
    paginateLitePurchaseIntent: LitePurchaseIntentConnection;
  }>(operations.GET_ALL_LITE_PURCHASE_INTENT);

  const handleNextPage = () => {
    let litePurchaseIntentCursorInput: LitePurchaseIntentCursorInput = {};
    if (nextCursor) {
      litePurchaseIntentCursorInput = {
        ...litePurchaseIntentCursorInput,
        createdAt: nextCursor.createdAt,
        purchaseIntentId: nextCursor.purchaseIntentId,
      };
    }

    listPurchaseIntent({
      variables: {
        first: pageSize,
        after: litePurchaseIntentCursorInput,
      },
    });
    setCurrentPage(currentPage + 1);
  };

  const handleLitePurchaseIntentCsv = async () => {
    await listPurchaseIntentInCsv({
      variables: {
        dateRange: [startDateString, endDateString],
      },
    });
  };

  const handlePrevPage = () => {
    let litePurchaseIntentCursorInput: LitePurchaseIntentCursorInput = {};
    if (currentPage > 1) {
      litePurchaseIntentCursorInput = {
        ...litePurchaseIntentCursorInput,
        createdAt: cursorList[currentPage - 2].createdAt,
        purchaseIntentId: cursorList[currentPage - 2].purchaseIntentId,
      };
    }

    // if (startDate && endDate) {
    //   litePurchaseIntentCursorInput = {
    //     ...litePurchaseIntentCursorInput,
    //     startDate,
    //     endDate,
    //   };
    // }
    listPurchaseIntent({
      variables: {
        first: pageSize,
        after: litePurchaseIntentCursorInput,
      },
    });
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    listPurchaseIntent({
      variables: {
        first: pageSize,
        after: {},
      },
    });
  }, []);

  const handleLitePurchaseIntentData = () => {
    if (liteData && liteData.paginateLitePurchaseIntent) {
      const litePurchaseIntents = liteData.paginateLitePurchaseIntent.edges.map(
        (edge) => edge.node
      );

      setLitePurchaseIntentList(litePurchaseIntents);
      setNextCursor(liteData.paginateLitePurchaseIntent.pageInfo.endCursor);
      setHasNextPage(liteData.paginateLitePurchaseIntent.pageInfo.hasNextPage);
      let page = currentPage;
      if (currentPage === -1) {
        setCurrentPage(0);
        page = 0;
      }
      setHasPrevPage(page > 0);
      if (
        liteData.paginateLitePurchaseIntent.pageInfo.endCursor &&
        page + 1 > cursorList.length
      ) {
        setCursorList([
          ...cursorList,
          liteData.paginateLitePurchaseIntent.pageInfo.endCursor,
        ]);
      }
    }
  };

  useEffect(() => {
    const litePurchaseIntentCursorInput: LitePurchaseIntentCursorInput = {};

    listPurchaseIntent({
      variables: {
        first: pageSize,
        after: litePurchaseIntentCursorInput,
      },
    });
    setCurrentPage(0);
    setCursorList([]);
  }, [pageSize]);

  useEffect(() => {
    handleLitePurchaseIntentData();
  }, [liteData, liteLoading]);

  const setLitePurchaseIntentCsvDataFunction = () => {
    if (
      listLitePurchaseIntentToCsvData &&
      listLitePurchaseIntentToCsvData.listLitePurchaseIntentToCsv
    ) {
      const encodedUri = encodeURI(
        `data:text/csv;charset=utf-8,${listLitePurchaseIntentToCsvData.listLitePurchaseIntentToCsv}`
      );
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      document.body.appendChild(link);
      link.click();
    }
  };
  const checkIfUserIsAllowedToDownloadPurchaseIntent = () => {
    if (checkUserSuperAdminFromToken()) {
      setShowLitePurchaseIntentDownloadButton(true);
    }
  };

  const checkIfUserIsAllowedToInviteToAllDealers = () => {
    const dealer = getDealersIdFromIdToken();

    if (dealer === 'Cloudcar') {
      setShowUserButton(true);
    }

    return false;
  };

  useEffect(() => {
    checkIfUserIsAllowedToInviteToAllDealers();
  }, []);

  useEffect(() => {
    setLitePurchaseIntentCsvDataFunction();
  }, [listLitePurchaseIntentToCsvData]);

  const handleFilterStatus = () => {
    if (litePurchaseIntentList && filterCreditStatus) {
      const filteresList = litePurchaseIntentList.filter((item) => {
        if (
          !['APPROVED', 'REJECTED'].includes(
            item.paymentMethod?.financing?.financingStatus as string
          ) &&
          filterCreditStatus === CreditStatus.DRAFT
        ) {
          return true;
        }

        if (
          ['APPROVED', 'REJECTED'].includes(
            item.paymentMethod?.financing?.financingStatus as string
          ) &&
          filterCreditStatus ===
            (item.paymentMethod?.financing?.financingStatus as string)
        ) {
          return true;
        }

        return false;
      });
      setLitePurchaseIntentList(filteresList);
    }
  };

  useEffect(() => {
    handleFilterStatus();
  }, [filterCreditStatus]);

  useEffect(() => {
    checkIfUserIsAllowedToDownloadPurchaseIntent();
  }, []);

  const getCustomerFullName = (customer?: Customer | null) => {
    if (customer) {
      return `${customer.name} ${customer.firstSurname}`;
    }
    return 'Solicitud incompleta';
  };

  const getCreditStatus = (
    customer?: Customer | null,
    paymentMethod?: PaymentMethod | null
  ) => {
    if (customer && customer.financingInfo && paymentMethod?.financing?.financingStatus) {
      if (paymentMethod?.financing?.financingStatus === 'APPROVED') {
        return 'Aprobado';
      }
      if (paymentMethod?.financing?.financingStatus === 'REJECTED') {
        return 'Rechazado';
      }
      return 'En evaluación';
    }
    return '-';
  };

  const getColumns = () => {
    const columns: ColumnsType<LitePurchaseIntent> = [
      {
        title: 'Fecha',
        dataIndex: 'createdAt',
        render: (createdAt: string) => <p>{dateFormatter(createdAt)}</p>,
        sorter: (a: LitePurchaseIntent, b: LitePurchaseIntent) =>
          (a.createdAt || '').toString().localeCompare(b.createdAt || ''),
        defaultSortOrder: 'descend',
      },
      {
        title: 'Rut cliente',
        dataIndex: 'customerRut',
        render: (clientRut: string) =>
          validateRut(clientRut) ? formatRut(clientRut) : clientRut,
        sorter: (a: LitePurchaseIntent, b: LitePurchaseIntent) =>
          (a.customerRut as string).localeCompare(b.customerRut as string),
      },
      {
        title: 'Nombre cliente',
        dataIndex: 'customer',
        render: (customer: Customer) => <p>{getCustomerFullName(customer)}</p>,
        sorter: (a: LitePurchaseIntent, b: LitePurchaseIntent) =>
          getCustomerFullName(a.customer).localeCompare(getCustomerFullName(b.customer)),
      },
      {
        title: 'Evaluación de crédito',
        render: (litePurchaseIntent: LitePurchaseIntent) => (
          <p>
            {getCreditStatus(
              litePurchaseIntent.customer,
              litePurchaseIntent.paymentMethod
            )}
          </p>
        ),
        sorter: (a: LitePurchaseIntent, b: LitePurchaseIntent) =>
          getCreditStatus(a.customer, a.paymentMethod).localeCompare(
            getCreditStatus(b.customer, b.paymentMethod)
          ),
      },
      {
        title: 'Ejecutivo',
        render: (litePurchaseIntent: LitePurchaseIntent) => (
          <p>
            {litePurchaseIntent.userInformation?.name}{' '}
            {litePurchaseIntent.userInformation?.firstName}
          </p>
        ),
        sorter: (a: LitePurchaseIntent, b: LitePurchaseIntent) =>
          (a.userInformation?.name || '').localeCompare(b.userInformation?.name || ''),
      },
      {
        title: 'Dealer',
        render: (litePurchaseIntent: LitePurchaseIntent) => (
          <p>{litePurchaseIntent.userInformation?.dealer}</p>
        ),
        sorter: (a: LitePurchaseIntent, b: LitePurchaseIntent) =>
          (a.userInformation?.dealer || '').localeCompare(
            b.userInformation?.dealer || ''
          ),
      },
      {
        align: 'right',
        render: (record: LitePurchaseIntent) => (
          <Button
            mode="text"
            onClick={() => {
              customerRut(record.customerRut);
              localStorage.setItem('purchaseIntentId', record.purchaseIntentId);
              selectedPurchaseIntent(record);
              history.push({
                pathname: '/purchaseIntentDetails',
                state: {
                  from: history.location,
                },
              });
            }}
          >
            Ver detalles
          </Button>
        ),
      },
    ];

    if (checkUserSuperAdminFromToken()) {
      return columns;
    }

    return columns.filter(
      (column) => column.title !== 'Dealer' && column.title !== 'Ejecutivo'
    );
  };

  const removeStorage = () => {
    localStorage.removeItem('purchaseIntentId');
    localStorage.removeItem('dealer');
  };

  return (
    <>
      <Row justify="space-between" align="middle">
        <PageHeader title="Historial de solicitudes" />
        <Row>
          {showUserButton && (
            <Button
              style={{ marginRight: '12px' }}
              onClick={() => history.push('/users')}
            >
              Usuarios
            </Button>
          )}
          {showRangePickerModal && (
            <PurchaseIntentRangePicker
              csvListLoading={csvLitePurchaseIntentLoading}
              handleLitePurchaseIntentCsv={handleLitePurchaseIntentCsv}
              visible={showRangePickerModal}
              onClose={() => {
                setShowRangePickerModal(false);
              }}
              setStartDateString={setStartDateString}
              setEndDateString={setEndDateString}
            />
          )}
          {showLitePurchaseIntentDownloadButton && (
            <Button
              style={{ marginRight: '12px' }}
              onClick={() => setShowRangePickerModal(true)}
            >
              Descargar solicitudes
            </Button>
          )}

          <Button
            onClick={() => {
              removeStorage();
              history.push({
                pathname: '/newPurchaseIntent',
                state: {
                  from: history.location,
                },
              });
            }}
          >
            Nueva solicitud
          </Button>
        </Row>
      </Row>
      <Row style={{ marginTop: '24px' }}>
        <Col>
          <p> Filtrar solicitudes</p>
          <Select
            style={{ minWidth: '180px' }}
            showArrow
            isFilter
            allowClear
            onClear={() => setFilterCreditStatus(null)}
            placeholder="seleccionar estado"
            value={filterCreditStatus}
            onChange={(options: CreditStatus) => {
              handleLitePurchaseIntentData();
              setFilterCreditStatus(options);
            }}
            options={[
              {
                value: 'APPROVED',
                label: 'Aprobado',
              },
              {
                value: 'REJECTED',
                label: 'Rechazado',
              },
              {
                value: 'DRAFT',
                label: 'otro',
              },
            ]}
            compact
          />
        </Col>
      </Row>

      <Row justify="center" className={styles.TableContainer}>
        <Table
          loading={liteLoading}
          columns={getColumns()}
          dataSource={litePurchaseIntentList || []}
          rowKey={(record) => record.purchaseIntentId}
          pagination={false}
        />
      </Row>
      <Row justify="end" className={styles.PaginationContainer}>
        <Pagination
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
          onNextPage={handleNextPage}
          onPrevPage={handlePrevPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageSizeOptions={[10, 20, 50, 100]}
          showSizeChanger
        />
      </Row>
    </>
  );
};

export default PurchaseIntentList;
