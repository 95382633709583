import { InMemoryCache, makeVar } from '@apollo/client';
import { CustomError, StepInfo, CreditStates, LitePurchaseIntent } from 'apollo/types';
import steps from 'utils/stepsInfo';

export const isMobileLayoutCache = makeVar<boolean>(false);
export const isAppLoading = makeVar<boolean>(false);
export const isStylesConfigurationLoading = makeVar<boolean>(false);

export const purchaseIntentToken = makeVar<string | null>(null);
export const selectedPurchaseIntent = makeVar<LitePurchaseIntent | null>(null);

export const concessionaireLogoUrl = makeVar<string | null>(null);
export const concessionaireUrl = makeVar<string>('');

export const stepList = makeVar<StepInfo[]>(steps);
export const currentSubStep = makeVar<number>(-1);

export const logged = makeVar<boolean | string>(false);
export const showLoginForm = makeVar<boolean>(false);

export const showErrorModal = makeVar<boolean>(false);
export const errorData = makeVar<CustomError | null>(null);
export const errorList = makeVar<CustomError[]>([]);

export const selectedPaymentMethod = makeVar<string | null>(null);
export const loadingCredit = makeVar<CreditStates>(CreditStates.Draft);

export const listPrice = makeVar<number | null>(null);
export const listPriceWithOffers = makeVar<number | null>(null);
export const finalPriceToPay = makeVar<number | null>(null);

export const customerRut = makeVar<string | null>(null);
export const selectedFinancialName = makeVar<string | null>(null);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        showErrorModal: {
          read() {
            return showErrorModal();
          },
        },
        errorData: {
          read() {
            return errorData();
          },
        },
      },
    },
  },
});

export default cache;
