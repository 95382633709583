import React, { FC, useEffect, useState } from 'react';
import hash from 'object-hash';
import { useReactiveVar } from '@apollo/client';
import { CustomError } from 'apollo/types';
import { selectedPaymentMethod, listPrice, errorList, logged } from 'apollo/cache';
import useDebounce from 'utils/useDebounce';
import logger from 'utils/logger';
import { getPurchaseIntentIdFromIdToken } from 'utils/getDataFromJwt';

const ErrorLogger: FC = () => {
  const errors = useReactiveVar(errorList);
  const [errorsToLog, setErrorsToLog] = useState<CustomError[]>([]);
  const debouncedErrors = useDebounce<CustomError[]>(errorsToLog);

  const filterErrors = () => {
    const uniqueErrors: CustomError[] = [];
    const uniqueErrorHashes: string[] = [];
    errorsToLog.forEach((error) => {
      const errorHash = hash(error);
      if (!uniqueErrorHashes.includes(errorHash)) {
        uniqueErrors.push(error);
        uniqueErrorHashes.push(errorHash);
      }
    });
    errors.forEach((error) => {
      const errorHash = hash(error);
      if (!uniqueErrorHashes.includes(errorHash)) {
        uniqueErrors.push(error);
        uniqueErrorHashes.push(errorHash);
      }
    });
    setErrorsToLog(uniqueErrors);
  };

  const logDebouncedErrors = () => {
    if (debouncedErrors.length > 0) {
      const payload = {
        purchaseIntentId: getPurchaseIntentIdFromIdToken(),
        currentPathName: window.location.href,
        errors: debouncedErrors,
        customerEmail: logged() || undefined,
        cache: {
          selectedPaymentMethod: selectedPaymentMethod(),
          listPrice: listPrice(),
        },
      };
      logger.error(payload);
      setErrorsToLog([]);
    }
  };

  useEffect(() => {
    filterErrors();
  }, [errors]);

  useEffect(() => {
    logDebouncedErrors();
  }, [debouncedErrors]);

  return <></>;
};

export default ErrorLogger;
