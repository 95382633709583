const operations: { [key: string]: { [key: string]: string } } = {
  Purchase: {
    RESERVATION: 'GET_RESERVATION_PURCHASE',
    CAR: 'GET_PURCHASE',
    CONTINUERESERVATION: 'GET_PURCHASE',
    USEDCAR: 'GET_USED_PURCHASE',
  },
  PurchaseItemInfo: {
    RESERVATION: 'GET_RESERVATION_INFO',
    CAR: 'GET_CAR_INFO',
    CONTINUERESERVATION: 'GET_CAR_INFO',
    USEDCAR: 'GET_USED_CAR_INFO',
  },
  PurchaseItemSummaryInfo: {
    USEDCAR: 'GET_USED_CAR_SUMMARY_INFO',
    CAR: 'GET_CAR_SUMMARY_INFO',
    CONTINUERESERVATION: 'GET_CAR_SUMMARY_INFO',
    RESERVATION: 'GET_RESERVATION_SUMMARY_INFO',
  },
  StepsValidation: {
    RESERVATION: 'GET_RESERVATION_PURCHASE_STEP_VALIDATION',
    CAR: 'GET_PURCHASE_STEP_VALIDATION',
    CONTINUERESERVATION: 'GET_PURCHASE_STEP_VALIDATION',
    USEDCAR: 'GET_USED_PURCHASE_STEP_VALIDATION',
  },
};

export default operations;
