/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/display-name */
import React, { FC, useState } from 'react';
import { Dropdown, Menu, Row, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { MoreOutlined } from '@ant-design/icons';
import {
  ApolloQueryResult,
  MutationFunction,
  OperationVariables,
  useReactiveVar,
} from '@apollo/client';
import operations from 'apollo/operations';
import { User } from 'apollo/types';
import styles from './UsersTable.module.scss';

interface UsersTableProps {
  loading: boolean;
  data: User[];
  refetchUsers: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      listUser: User[];
    }>
  >;
}

const UsersTable: FC<UsersTableProps> = (props: UsersTableProps) => {
  const { loading, data, refetchUsers } = props;
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [userData, setUserData] = useState<User | null>(null);

  const columns: ColumnsType<User> = [
    {
      title: <h3>Nombre</h3>,
      render: (user: User) => <span className={styles.Span}>{user.name}</span>,
      sorter: (a: User, b: User) => {
        const firstUserName = a.name ? a.name : 'Usuario sin nombre';
        const secondUserName = b.name ? b.name : 'Usuario sin nombre';
        return firstUserName.localeCompare(secondUserName);
      },
    },
    {
      title: <h3>Email</h3>,
      render: (user: User) => <span className={styles.Span}>{user.email}</span>,
      sorter: (a: User, b: User) => {
        const firstUserEmail = a.email ? a.email : 'Usuario sin mail';
        const secondUserEmail = b.email ? b.email : 'Usuario sin mail';
        return firstUserEmail.localeCompare(secondUserEmail);
      },
    },
    {
      title: <h3>Sucursal</h3>,
      render: (user: User) => <span className={styles.Span}>{user.dealer}</span>,
    },
  ];

  return (
    <>
      <Row className={styles.TableContainer}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          rowKey={(user: User) => user.username}
        />
      </Row>
    </>
  );
};

export default UsersTable;
