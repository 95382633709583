/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import hash from 'object-hash';
import { formatRut, validateRut } from '@abstract-cl/rut-ts';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  CreateLitePurchaseIntentInput,
  LitePurchaseIntent,
  PaymentMethod,
  PaymentMethodInput,
  PurchaseIntentFormValues,
  AllowedCurrency,
  AllowedPaymentMethodType,
} from 'apollo/types';
import operations from 'apollo/operations';
import { stepList, selectedPaymentMethod, listPrice, customerRut } from 'apollo/cache';
import getStepIndexByPathname from 'utils/getStepIndexByPathname';
import renderConcessionaireForm from './dynamicForms';

const DynamicForm: FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const steps = useReactiveVar(stepList);
  // const [legalEntity, setLegalEntity] = useState(false);
  const currentStepIndex = getStepIndexByPathname(history.location.pathname, steps);

  const [
    createLitePurchaseIntent,
    { data: purchaseIntentData, loading: createPurchaseIntentLoading },
  ] = useMutation<
    { createLitePurchaseIntent: LitePurchaseIntent },
    { createLitePurchaseIntentInput: CreateLitePurchaseIntentInput }
  >(operations.CREATE_LITE_PURCHASE_INTENT);

  const [createPaymentMethod, { data: paymentMethodData }] = useMutation<
    { createPaymentMethod: PaymentMethod },
    { paymentMethodInput: PaymentMethodInput }
  >(operations.POST_PAYMENT_METHOD);

  const handleChangeRut = (rut: string): string => {
    if (validateRut(rut)) {
      const formatted: string = formatRut(rut) || '';
      form.setFieldsValue({ rut: formatted });
      return rut;
    }
    form.setFieldsValue({
      rut: rut.split('.').join('').split('-').join(''),
    });
    return rut;
  };

  const onFinish = async ({
    brand,
    model,
    year,
    version,
    color,
    price,
    cit,
    rut,
    vehicleType,
  }: PurchaseIntentFormValues) => {
    const carMinInfoId = hash({
      brand,
      model,
      year,
      version,
      color,
    });
    const createLitePurchaseIntentInput = {
      vehicleType: vehicleType || '',
      carMinInfoId,
      brand: brand?.split('#')[1] || '',
      model: model?.split('#')[1] || '',
      year,
      version: version?.split('#')[1] || 'No aplica',
      brandId: (form.getFieldValue('brandId') as string).toString() || '',
      modelId: (form.getFieldValue('modelId') as string).toString() || '',
      versionId: (form.getFieldValue('versionId') as string).toString() || '',
      legalEntity: false,
      color: color || '',
      price,
      cit: cit || '',
      customerRut: rut,
    };
    customerRut(rut);
    await createLitePurchaseIntent({
      variables: {
        createLitePurchaseIntentInput,
      },
    });
    listPrice(price);
    selectedPaymentMethod(AllowedPaymentMethodType.WithFinancing);
  };

  const createPaymentMethodFunction = async () => {
    await createPaymentMethod({
      variables: {
        paymentMethodInput: {
          currency: AllowedCurrency.Clp,
          type: AllowedPaymentMethodType.WithFinancing,
        },
      },
    });
  };

  useEffect(() => {
    if (purchaseIntentData && purchaseIntentData.createLitePurchaseIntent) {
      localStorage.setItem(
        'purchaseIntentId',
        purchaseIntentData.createLitePurchaseIntent.purchaseIntentId
      );
      createPaymentMethodFunction();
    }
  }, [purchaseIntentData]);

  useEffect(() => {
    if (paymentMethodData && paymentMethodData.createPaymentMethod) {
      history.push({
        pathname: steps[currentStepIndex + 1].route,
        state: {
          from: history.location,
        },
      });
    }
  }, [paymentMethodData]);

  return (
    <Form form={form} name="carInformation" onFinish={onFinish} scrollToFirstError>
      {renderConcessionaireForm({
        createPurchaseIntentLoading,
        handleChangeRut,
        // setLegalEntity,
        // legalEntity,
        form,
      })}
    </Form>
  );
};

export default DynamicForm;
