/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Spin } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { stepList, currentSubStep, loadingCredit, customerRut } from 'apollo/cache';
import {
  Customer,
  CustomerInput,
  CustomerForm,
  PaymentMethod,
  AllowedPaymentMethodType,
  FinancingStatus,
  CreditStates,
} from 'apollo/types';
import operations from 'apollo/operations';
import { NextButton } from 'components/ui';
import { FinancialInformationForm, PersonalInformationForm } from 'components/customer';
import getStepIndexByPathname from 'utils/getStepIndexByPathname';
import formatDateToValidJsStringFormat from 'utils/formatDateToValidJsStringFormat';

dayjs.extend(utc);

const PersonalInformation: FC = (): JSX.Element => {
  const history = useHistory();

  const [form] = Form.useForm();
  const [loadingInjection, setLoadingInjection] = useState(false);
  const [filledPersonalInformation, setFilledPersonalInformation] = useState(false);
  const steps = useReactiveVar(stepList);
  const currentStepIndex = getStepIndexByPathname(history.location.pathname, steps);
  const currentSubStepIndex = useReactiveVar(currentSubStep);
  const customerRutCache = useReactiveVar(customerRut);

  const [createCustomer, { loading }] = useMutation<
    { createCustomer: Customer },
    { customerInput: CustomerInput }
  >(operations.POST_PERSONAL_INFORMATION);

  const { data: customerInfo, loading: customerLoading } = useQuery<{
    customer: Customer;
  }>(operations.GET_CUSTOMER_INFO, {
    fetchPolicy: 'network-only',
  });

  const { data: paymentMethodData } = useQuery<{
    paymentMethod: PaymentMethod;
  }>(operations.GET_PAYMENT_METHOD, {
    fetchPolicy: 'network-only',
  });

  const [
    requestFinancingPreApproval,
    { data: financingStatus, loading: financingStatusLoading },
  ] = useMutation<{
    requestPreApprovedCredit: PaymentMethod;
  }>(operations.REQUEST_FINANCING_PRE_APPROVAL, {
    fetchPolicy: 'network-only',
  });

  const next = () => {
    const isPaymentMethodCash =
      paymentMethodData &&
      paymentMethodData.paymentMethod &&
      paymentMethodData.paymentMethod.type === AllowedPaymentMethodType.Cash;
    const isPaymentMethodFinancing =
      paymentMethodData &&
      paymentMethodData.paymentMethod &&
      paymentMethodData.paymentMethod.type === AllowedPaymentMethodType.WithFinancing;
    if (isPaymentMethodCash) {
      history.push({
        pathname: steps[currentStepIndex + 1].route,
        state: {
          from: history.location,
        },
      });
    } else if (
      isPaymentMethodFinancing &&
      customerInfo &&
      customerInfo.customer &&
      customerInfo.customer.financingInfo
    ) {
      currentSubStep(-1);
      history.push({
        pathname: steps[currentStepIndex + 1].route,
        state: {
          from: history.location,
        },
      });
    }
  };

  const handleCustomerRut = () => {
    if (customerRutCache) {
      form.setFieldsValue({
        rut: customerRutCache,
      });
    }
  };

  const onFinish = async (values: CustomerForm) => {
    const birthdayISO = formatDateToValidJsStringFormat(values.birthday);

    let customerInput: CustomerInput = {
      name: values.name.trim(),
      firstSurname: values.firstSurname.trim(),
      secondSurname: values.secondSurname?.trim(),
      rut: values.rut,
      email: values.email,
      phone: values.phone,
      address: values.address.trim(),
      addressNumber: values.addressNumber,
      commune: values.commune?.split('#')[1] || '',
      region: values.region?.split('#')[1] || '',
      regionId: (form.getFieldValue('regionId') as string).toString() || '',
      communeId: (form.getFieldValue('communeId') as string).toString() || '',
      birthday: new Date(birthdayISO.slice(0, birthdayISO.indexOf('T'))).toISOString(),
    };

    const isPaymentMethodFinancing =
      paymentMethodData &&
      paymentMethodData.paymentMethod &&
      paymentMethodData.paymentMethod.type === AllowedPaymentMethodType.WithFinancing;

    if (isPaymentMethodFinancing && filledPersonalInformation) {
      const unformattedValue = values.income?.toString().replace(/\$\s?|(\.*)/g, '');
      const numberIncome = Number(unformattedValue);
      customerInput = {
        ...customerInput,
        financingInfo: {
          income: {
            Type: values.incomeType as string,
            Value: numberIncome,
            Old: '',
          },
          profile: {
            Gender: '',
            RelationStatus: '',
            Studies: '',
            ContractType: values.contractType as string,
            IncomeType: values.incomeVariability as string,
            Profession: values.profession || '',
          },
        },
      };
    } else {
      setFilledPersonalInformation(true);
      currentSubStep(currentSubStepIndex + 1);
    }

    await createCustomer({
      variables: {
        customerInput,
      },
      refetchQueries: [
        {
          query: operations.GET_PAYMENT_METHOD,
        },
        {
          query: operations.GET_CUSTOMER_INFO,
        },
      ],
    });

    if (isPaymentMethodFinancing && filledPersonalInformation) {
      await requestFinancingPreApproval();
    }
  };

  useEffect(() => {
    if (
      paymentMethodData &&
      paymentMethodData.paymentMethod &&
      paymentMethodData.paymentMethod.type === AllowedPaymentMethodType.WithFinancing
    ) {
      const newActiveSteps = [...steps];
      newActiveSteps[currentStepIndex] = {
        ...steps[currentStepIndex],
        subSteps: [
          {
            viewTitle: 'Datos personales para la financiera',
            title: 'Datos para el financiamiento',
            subTitle: 'Rellena todos los campos',
          },
        ],
      };
      stepList(newActiveSteps);
    } else if (paymentMethodData && paymentMethodData.paymentMethod) {
      currentSubStep(-1);
      const newActiveSteps = [...steps];
      newActiveSteps[currentStepIndex] = {
        ...steps[currentStepIndex],
        subSteps: undefined,
      };
      stepList(newActiveSteps);
    }
  }, [paymentMethodData]);

  useEffect(() => {
    if (
      financingStatus &&
      financingStatus.requestPreApprovedCredit &&
      financingStatus.requestPreApprovedCredit.financing &&
      financingStatus.requestPreApprovedCredit.financing.financingStatus ===
        FinancingStatus.Draft
    ) {
      setLoadingInjection(true);
      loadingCredit(CreditStates.Loading);
    }
  }, [financingStatus, financingStatusLoading]);

  useEffect(() => {
    const isPaymentMethodCash =
      paymentMethodData &&
      paymentMethodData.paymentMethod &&
      paymentMethodData.paymentMethod.type === AllowedPaymentMethodType.Cash;
    const isPaymentMethodFinancing =
      paymentMethodData &&
      paymentMethodData.paymentMethod &&
      paymentMethodData.paymentMethod.type === AllowedPaymentMethodType.WithFinancing;
    const isCreditRequested =
      filledPersonalInformation &&
      customerInfo &&
      customerInfo.customer &&
      customerInfo.customer.financingInfo &&
      loadingInjection;

    if (
      (customerInfo && isPaymentMethodCash) ||
      (isPaymentMethodFinancing && isCreditRequested)
    ) {
      next();
    }
  }, [customerInfo, paymentMethodData, loadingInjection]);

  useEffect(() => {
    if (currentSubStepIndex === -1) {
      setFilledPersonalInformation(false);
    } else {
      setFilledPersonalInformation(true);
    }
  }, [currentSubStepIndex]);

  useEffect(() => {
    handleCustomerRut();
  }, [customerRutCache]);

  return (
    <Spin spinning={customerLoading}>
      <Form
        form={form}
        name="personalInformation"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={{
          country: 'Chile',
          birthday: '',
        }}
      >
        <PersonalInformationForm form={form} hide={filledPersonalInformation} />
        {paymentMethodData &&
          paymentMethodData.paymentMethod &&
          paymentMethodData.paymentMethod.type ===
            AllowedPaymentMethodType.WithFinancing &&
          filledPersonalInformation && <FinancialInformationForm form={form} />}
        <NextButton
          htmlType="submit"
          loading={loading || financingStatusLoading}
          disabled={loading || financingStatusLoading}
        />
      </Form>
    </Spin>
  );
};

export default PersonalInformation;
