import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { stepList } from 'apollo/cache';
import PublicRoute from 'routes/PublicRoute';
import AdminRoute from 'routes/AdminRoute';
import StepRoute from 'routes/StepRoute';
import Login from 'views/Login';
import SignUp from 'views/SignUp';
import RecoverPassword from 'views/RecoverPassword';
import PurchaseIntentList from 'views/PurchaseIntentList';
import InviteUser from 'views/InviteUser';
import Users from 'views/Users';
import SuperAdminRoute from './SuperAdminRoute';

const Routes = (): JSX.Element => {
  const steps = useReactiveVar(stepList);
  return (
    <Switch>
      <PublicRoute exact path="/login" component={Login} />
      <PublicRoute exact path="/signup/:token" component={SignUp} />
      <PublicRoute exact path="/recoverPassword" component={RecoverPassword} />
      <AdminRoute exact path="/" component={PurchaseIntentList} />
      <SuperAdminRoute exact path="/inviteUser" component={InviteUser} />
      <SuperAdminRoute exact path="/users" component={Users} />
      {steps.map((step) => (
        <StepRoute key={step.route} exact path={step.route} />
      ))}
      <Redirect to="/login" />
    </Switch>
  );
};

export default Routes;
