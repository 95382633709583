import jwtDecode from 'jwt-decode';
import { PurchaseIntentTypes } from 'apollo/types';

interface JwtToken {
  'cognito:username'?: string;
  purchaseIntentId: string;
  'custom:dealer'?: string;
  type: PurchaseIntentTypes;
  'custom:role'?: string;
}

export const testJwtToken =
  'eyJraWQiOiJQeHU0VUlcL3I0YVA2N1BvOERaTTRpbmFmSFZcL25WQ2w2U0ZVOG55UlJMVTQ9IiwiYWxnIjoiUlMyNTYifQ' +
  '.eyJzdWIiOiI5ZGU1OTk1OS05ZDI1LTRkYzItYTUwZS0zOTk2MTRjZTJkNjAiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicHV' +
  'yY2hhc2VJbnRlbnRJZCI6ImMzMzZlZGI5LWJjY2ItNDY1Mi1hNzIyLWM5NWIwODIwMTAzZiIsImlzcyI6Imh0dHBzOlwvXC9jb' +
  '2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX0lkYXNXamJZcSIsImNvZ25pdG86dXNlcm5hbWUiO' +
  'iJEaXRlYyIsInR5cGUiOiJDQVIiLCJvcmlnaW5fanRpIjoiMWQ0YTQ4MzItOGRmOC00Mzk3LWJiZTQtNDc1MWYxZWEwYzQ2IiwiYXV' +
  'kIjoiN2xvbXJiYnZ1cTBzaDkzNW9paGYxbjUwdXIiLCJldmVudF9pZCI6IjEyNzFjMDk3LTBjMDAtNDRjOS1iMWI4LWEzNmNkMTExNm' +
  'U3YiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjQ3MDA1NTgwLCJuYW1lIjoiRGl0ZWMiLCJleHAiOjE2NDcwOTE5ODAsImlh' +
  'dCI6MTY0NzAwNTU4MCwianRpIjoiNmI1Y2VmZGQtMWM2OS00MmNiLWExMzUtZmRmNjFhOTBkZjVjIn0.OXtnjBF_To1IrYBk3N2YL_xudFJ' +
  '2XeijKptoNnnAAH_Td8Rsxkx_2fSAjkkKeXVHn__k7quCAjDKbTWSMTzTyyPxZ8ZPxrrhVybL0UMINOjVJvEo2lt4QlkKrWfY6fDphMpbbOiC' +
  '9hnLYXEdSVtHSfrn7D7VtC0AId60UInzHLXljBsBLjDRE2z_5VzAX7kc_E_uPdLlg-SwZwhyE33u5_A-G6jQrIKKHLmSV-OL1NwFV1lgbZjMMG' +
  'j4oPvssS0F0qM-7REdCC45YSx9-DpysTIg_7_pvtn4Hjc9kO0SGm644VFxLRO9iiYKBONofb07K-2_SPrO7HHOcN0sSVowRw';

export const getPurchaseIntentIdFromIdToken = (): string => {
  const token = localStorage.getItem('IdToken');
  if (token) {
    const decodedToken: JwtToken = jwtDecode(token);
    return decodedToken['cognito:username'] as string;
  }
  return '';
};

export const getDealersIdFromIdToken = (): string => {
  const token = localStorage.getItem('IdToken');
  if (token) {
    const decodedToken: JwtToken = jwtDecode(token);
    return decodedToken['custom:dealer'] as string;
  }
  return '';
};

export const checkUserSuperAdminFromToken = (): boolean => {
  const token = localStorage.getItem('IdToken');
  if (token) {
    const decodedToken: JwtToken = jwtDecode(token);
    return decodedToken['custom:role'] === 'super-admin';
  }
  return false;
};

export const getPurchaseIntentType = (): string => {
  const queryParams = new URLSearchParams(window.location.search);
  let token = localStorage.getItem('AccessToken') as string;
  if (queryParams.get('token')) {
    token = queryParams.get('token') as string;
  }
  if (token) {
    const decodedToken: JwtToken = jwtDecode(token);
    return decodedToken.type;
  }
  const decodedToken: JwtToken = jwtDecode(testJwtToken);
  return decodedToken.type;
};
