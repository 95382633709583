/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, message, Modal } from 'antd';
import { Button, Input } from '@cloudcar-app/cloudcar-components';
import { useHistory } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { concessionaireLogoUrl, logged } from 'apollo/cache';
import apiService from 'utils/apiService';
import MessageError from 'utils/message.errors';
import rutFormValidator from 'utils/rutFormValidator';
import { validateRut, formatRut } from '@abstract-cl/rut-ts';
import styles from './Login.module.scss';

interface LoginValues {
  username: string;
  password: string;
}

interface CognitoTokens {
  IdToken: string;
  RefreshToken: string;
  AccessToken: string;
}

const Login: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [forgotPasswordForm] = Form.useForm();
  const [requested, setRequested] = useState(false);
  const [forgotPasswordRequested, setForgotPasswordRequested] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const logoUrl = useReactiveVar(concessionaireLogoUrl);

  const handleChangeRut = (rut: string): string => {
    if (validateRut(rut)) {
      const formatted: string = formatRut(rut) || '';
      form.setFieldsValue({ username: formatted });
      return rut;
    }
    form.setFieldsValue({
      username: rut.split('.').join('').split('-').join(''),
    });
    return rut;
  };

  const handleForgotPasswordChangeRut = (rut: string): string => {
    if (validateRut(rut)) {
      const formatted: string = formatRut(rut) || '';
      forgotPasswordForm.setFieldsValue({ forgotPasswordUsername: formatted });
      return rut;
    }
    forgotPasswordForm.setFieldsValue({
      forgotPasswordUsername: rut.split('.').join('').split('-').join(''),
    });
    return rut;
  };

  const forgotPasswordSubmit = async (values: { forgotPasswordUsername: string }) => {
    try {
      setForgotPasswordRequested(true);
      await apiService.forgotPasswordRequest(values.forgotPasswordUsername);
      message.loading(
        'Te hemos enviado un correo con las instrucciones para restablecer tu contraseña',
        3
      );
      setShowForgotPasswordModal(false);
      setForgotPasswordRequested(false);
    } catch (err) {
      setForgotPasswordRequested(false);
      message.loading('Error', 1);
    }
  };

  const renderPageTitle = () => {
    return 'Cotizador Tanner en línea!';
  };

  const sendForm = async (values: LoginValues) => {
    try {
      const { host } = window.location;
      const concessionaire = host.split('.')[0];

      setRequested(true);
      const data = {
        username: values.username,
        password: values.password,
        concessionaireName: concessionaire === 'financing' ? 'CloudCar' : 'Tanner',
      };
      await apiService.loginRequest(data).then((response) => {
        const tokens = response.response as CognitoTokens;
        localStorage.setItem('RefreshToken', tokens.RefreshToken);
        localStorage.setItem('AccessToken', tokens.AccessToken);
        localStorage.setItem('IdToken', tokens.IdToken);
        logged(true);
        setRequested(false);
        history.push('/');
      });
    } catch (err) {
      setRequested(false);
      message.loading('Error', 1);
    }
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <>
      <Modal
        visible={showForgotPasswordModal}
        onOk={() => {
          setShowForgotPasswordModal(false);
        }}
        onCancel={() => {
          setShowForgotPasswordModal(false);
        }}
        footer={null}
        width="30%"
      >
        <Form form={forgotPasswordForm} name="login" onFinish={forgotPasswordSubmit}>
          <Row justify="center" align="middle">
            <Col md={24}>
              <Form.Item
                name="forgotPasswordUsername"
                className={styles.FormItem}
                rules={[
                  {
                    required: true,
                    message: MessageError.emptyFieldsMessages.rut,
                  },
                  {
                    validator: rutFormValidator,
                  },
                  {
                    whitespace: true,
                    message: MessageError.emptyFieldsMessages.rut,
                  },
                ]}
              >
                <Input
                  label="Rut"
                  placeholder="11.111.111-1"
                  extra="*Escribe tu rut sin puntos ni guión."
                  onChange={(e) => handleForgotPasswordChangeRut(e.target.value)}
                  required
                />
              </Form.Item>
            </Col>
            <Row justify="center">
              <Button
                className={styles.ForgotPasswordButtonContainer}
                htmlType="submit"
                size="large"
                loading={forgotPasswordRequested}
                disabled={forgotPasswordRequested}
              >
                Restablece tu contraseña
              </Button>
            </Row>
          </Row>
        </Form>
      </Modal>
      <Row className={styles.Container}>
        <div className={styles.Background} />
        <Col xs={0} sm={8} className={styles.FullHeight}>
          <Row justify="center" align="middle" className={styles.FullSize}>
            <Col xs={16}>
              {logoUrl && (
                <Row justify="center" align="middle" className={styles.MarginBottom}>
                  <img src={logoUrl} alt="logo" width="100" />
                </Row>
              )}
              <Row justify="center" align="middle">
                <h1 className={styles.CenteredText}>{renderPageTitle()}</h1>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={16} className={styles.FullHeight}>
          <Row justify="center" align="middle" className={styles.FullHeight}>
            <Col>
              <Form form={form} name="login" onFinish={sendForm}>
                <Row justify="center" align="middle" className={styles.Login}>
                  <h1 className={styles.Title}> Inicio de sesión</h1>
                  <Col md={24}>
                    <Form.Item
                      name="username"
                      className={styles.FormItem}
                      rules={[
                        {
                          required: true,
                          message: MessageError.emptyFieldsMessages.rut,
                        },
                        {
                          validator: rutFormValidator,
                        },
                        {
                          whitespace: true,
                          message: MessageError.emptyFieldsMessages.rut,
                        },
                      ]}
                    >
                      <Input
                        label="Rut"
                        placeholder="11.111.111-1"
                        extra="*Escribe tu rut sin puntos ni guión."
                        onChange={(e) => handleChangeRut(e.target.value)}
                        required
                      />
                    </Form.Item>
                  </Col>
                  <Col md={24} className={styles.MarginTop}>
                    <Form.Item
                      name="password"
                      className={styles.FormItem}
                      rules={[
                        {
                          required: true,
                          message: MessageError.emptyFieldsMessages.password,
                        },
                        {
                          whitespace: true,
                          message: MessageError.emptyFieldsMessages.password,
                        },
                      ]}
                    >
                      <Input password label="Contraseña" placeholder="contraseña" />
                    </Form.Item>
                  </Col>
                  <Row className={styles.ButtonContainer} justify="center">
                    <Button
                      htmlType="submit"
                      size="large"
                      loading={requested}
                      disabled={requested}
                    >
                      Iniciar sesión
                    </Button>
                  </Row>
                  <Row className={styles.ForgotPasswordContainer} justify="center">
                    <a
                      href="#"
                      onClick={() => {
                        setShowForgotPasswordModal(true);
                      }}
                    >
                      Restablece tu contraseña
                    </a>
                  </Row>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Login;
