/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect } from 'react';
import { Row, Col, Form, message } from 'antd';
import { formatRut, validateRut } from '@abstract-cl/rut-ts';
import { Button, Input, Select } from '@cloudcar-app/cloudcar-components';
import { useHistory } from 'react-router-dom';
import { useMutation, useReactiveVar } from '@apollo/client';
import { concessionaireLogoUrl } from 'apollo/cache';
import MessageError from 'utils/message.errors';
import rutFormValidator from 'utils/rutFormValidator';
import { InviteUserInput } from 'apollo/types';
import operations from 'apollo/operations';
import { getDealersIdFromIdToken } from 'utils/getDataFromJwt';
import dealerOptions from 'utils/dealers';
import styles from './InviteUser.module.scss';

interface InviteUserFormValues {
  rut: string;
  email: string;
  name: string;
  dealer: string;
  firstName: string;
  concessionaireName: string;
}

const InviteUser: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const logoUrl = useReactiveVar(concessionaireLogoUrl);

  const [inviteUser, { data, loading }] = useMutation<
    { inviteUser: string },
    { inviteUserInput: InviteUserInput }
  >(operations.INVITE_USER);

  const handleChangeRut = (rut: string): string => {
    if (validateRut(rut)) {
      const formatted: string = formatRut(rut) || '';
      form.setFieldsValue({ rut: formatted });
      return rut;
    }
    form.setFieldsValue({
      rut: rut.split('.').join('').split('-').join(''),
    });
    return rut;
  };

  const checkIfUserIsAllowedToInviteToAllDealers = () => {
    const dealer = getDealersIdFromIdToken();

    if (dealer === 'Cloudcar') {
      return true;
    }

    return false;
  };

  const sendForm = async (values: InviteUserFormValues) => {
    const { host } = window.location;
    const concessionaire = host.split('.')[0];

    await inviteUser({
      variables: {
        inviteUserInput: {
          concessionaireName: concessionaire === 'financing' ? 'CloudCar' : 'Tanner',
          dealer: checkIfUserIsAllowedToInviteToAllDealers()
            ? values.dealer
            : localStorage.getItem('dealer') || '',
          name: values.name,
          email: values.email,
          firstName: values.firstName,
          rut: values.rut,
        },
      },
      refetchQueries: [
        {
          query: operations.GET_ALL_GUEST_USERS,
          variables: {
            concessionaireName: concessionaire === 'financing' ? 'CloudCar' : 'Tanner',
          },
        },
      ],
    });
  };

  useEffect(() => {
    if (data && data.inviteUser) {
      message.success('Usuario invitado correctamente', 2);
      history.push('/');
    }
  }, [data]);

  const renderPageTitle = () => {
    return 'Cotizador Tanner en línea!';
  };

  return (
    <Row className={styles.Container}>
      <div className={styles.Background} />
      <Col xs={0} sm={8} className={styles.FullHeight}>
        <Row justify="center" align="middle" className={styles.FullSize}>
          <Col xs={16}>
            {logoUrl && (
              <Row justify="center" align="middle" className={styles.MarginBottom}>
                <img src={logoUrl} alt="" width="100" />
              </Row>
            )}
            <Row justify="center" align="middle">
              <h1 className={styles.CenteredText}>{renderPageTitle()}</h1>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={16} className={styles.FullHeight}>
        <Row justify="start" align="middle" className={styles.FullHeight}>
          <Col>
            <Form form={form} name="login" onFinish={sendForm}>
              <Row justify="start" align="middle" className={styles.SignUp} gutter={12}>
                <Col xs={24}>
                  <Row justify="center" align="middle" gutter={12}>
                    <h1 className={styles.Title}>Invitar usuario</h1>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="name"
                    className={styles.FormItem}
                    rules={[
                      {
                        required: true,
                        message: MessageError.emptyFieldsMessages.name,
                      },
                      {
                        whitespace: true,
                        message: MessageError.emptyFieldsMessages.name,
                      },
                    ]}
                  >
                    <Input label="Nombre" placeholder="Nombre" required />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="firstName"
                    className={styles.FormItem}
                    rules={[
                      {
                        required: true,
                        message: MessageError.emptyFieldsMessages.lastName,
                      },
                      {
                        whitespace: true,
                        message: MessageError.emptyFieldsMessages.lastName,
                      },
                    ]}
                  >
                    <Input label="Apellido" placeholder="Apellido" required />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="rut"
                    className={styles.FormItem}
                    rules={[
                      { required: true, message: MessageError.emptyFieldsMessages.rut },
                      {
                        whitespace: true,
                        message: MessageError.emptyFieldsMessages.rut,
                      },
                      {
                        validator: rutFormValidator,
                      },
                    ]}
                  >
                    <Input
                      label="Rut"
                      placeholder="Rut"
                      onChange={(e) => handleChangeRut(e.target.value)}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="email"
                    className={styles.FormItem}
                    rules={[
                      {
                        required: true,
                        message: MessageError.emptyFieldsMessages.email,
                      },
                      {
                        whitespace: true,
                        message: MessageError.emptyFieldsMessages.email,
                      },
                    ]}
                  >
                    <Input label="Email" placeholder="Email@example.com" required />
                  </Form.Item>
                </Col>
                {checkIfUserIsAllowedToInviteToAllDealers() && (
                  <Col xs={12}>
                    <Form.Item
                      name="dealer"
                      className={styles.FormItem}
                      rules={[
                        {
                          required: true,
                          message: MessageError.emptyFieldsMessages.dealer,
                        },
                        {
                          whitespace: true,
                          message: MessageError.emptyFieldsMessages.dealer,
                        },
                      ]}
                    >
                      <Select
                        label="Dealer"
                        placeholder="Dealer"
                        showSearch
                        options={dealerOptions}
                        required
                        filterOption={(input, option) =>
                          option
                            ? String(option.label)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                            : false
                        }
                      />
                    </Form.Item>
                  </Col>
                )}
                <Row className={styles.ButtonContainer} justify="center">
                  <Button
                    htmlType="submit"
                    size="large"
                    loading={loading}
                    disabled={loading}
                  >
                    Enviar invitación
                  </Button>
                </Row>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default InviteUser;
