/* eslint-disable @typescript-eslint/no-explicit-any */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export enum AllowedComponent {
  Button = 'BUTTON',
  Card = 'CARD',
  Widget = 'WIDGET'
}

export enum AllowedCurrency {
  Clp = 'CLP',
  Usd = 'USD'
}

export enum AllowedPaymentMethodType {
  Cash = 'CASH',
  WithFinancing = 'WITH_FINANCING'
}

export type Car = {
  __typename?: 'Car';
  brand?: Maybe<Scalars['String']['output']>;
  brandId?: Maybe<Scalars['String']['output']>;
  carId: Scalars['String']['output'];
  carMinInfoId?: Maybe<Scalars['String']['output']>;
  cit?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modelId?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  vehicleType?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  versionId?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type Concessionaire = {
  __typename?: 'Concessionaire';
  address: Scalars['String']['output'];
  apiKey: Scalars['String']['output'];
  businessName?: Maybe<Scalars['String']['output']>;
  commerceCode: Scalars['String']['output'];
  commune?: Maybe<Scalars['String']['output']>;
  concessionaireId: Scalars['String']['output'];
  concessionaireType: ConcessionaireType;
  contactMail: Scalars['String']['output'];
  contactName: Scalars['String']['output'];
  contactPhone: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  integration?: Maybe<Integration>;
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  rut: Scalars['String']['output'];
  siteUrl: Scalars['String']['output'];
  termsConditionsFile?: Maybe<ConcessionaireTermsConditionsFile>;
};

export type ConcessionaireStyle = {
  __typename?: 'ConcessionaireStyle';
  buttonTextColor?: Maybe<Scalars['String']['output']>;
  componentType: AllowedComponent;
  concessionaireId: Scalars['String']['output'];
  concessionaireIsoLogoUrl?: Maybe<Scalars['String']['output']>;
  concessionaireLogoUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  offerTextColor?: Maybe<Scalars['String']['output']>;
  primaryColor?: Maybe<Scalars['String']['output']>;
  showRemainingQuantity: Scalars['Boolean']['output'];
  textColor?: Maybe<Scalars['String']['output']>;
};

export type ConcessionaireTermsConditionsFile = {
  __typename?: 'ConcessionaireTermsConditionsFile';
  encoding?: Maybe<Scalars['String']['output']>;
  fileId: Scalars['String']['output'];
  filename?: Maybe<Scalars['String']['output']>;
  mimetype?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum ConcessionaireType {
  Car = 'CAR',
  Usedcar = 'USEDCAR'
}

export type CreateCarInput = {
  brand?: InputMaybe<Scalars['String']['input']>;
  brandId?: InputMaybe<Scalars['String']['input']>;
  carMinInfoId?: InputMaybe<Scalars['String']['input']>;
  cit?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  vehicleType?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  versionId?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateLitePurchaseIntentInput = {
  brand: Scalars['String']['input'];
  brandId: Scalars['String']['input'];
  carMinInfoId: Scalars['String']['input'];
  cit?: InputMaybe<Scalars['String']['input']>;
  color: Scalars['String']['input'];
  customerRut: Scalars['String']['input'];
  legalEntity?: InputMaybe<Scalars['Boolean']['input']>;
  model: Scalars['String']['input'];
  modelId: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  vehicleType?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type Credentials = {
  __typename?: 'Credentials';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CreditRequest = {
  __typename?: 'CreditRequest';
  createdAt: Scalars['String']['output'];
  creditRequestId: Scalars['String']['output'];
  purchaseIntentId: Scalars['String']['output'];
};

export type CreditSimulation = {
  __typename?: 'CreditSimulation';
  Cae: Scalars['Float']['output'];
  DealerCommission?: Maybe<Scalars['Int']['output']>;
  Fees: Array<Fees>;
  FinancingAmount: Scalars['Int']['output'];
  Insurance: Array<ProductInsurance>;
  InterestAmount: Scalars['Int']['output'];
  InterestRate: Scalars['Float']['output'];
  OperationalCost: Scalars['Int']['output'];
};

export type Customer = {
  __typename?: 'Customer';
  address: Scalars['String']['output'];
  addressNumber: Scalars['String']['output'];
  birthday: Scalars['String']['output'];
  commune: Scalars['String']['output'];
  communeId: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  financingInfo?: Maybe<FinancingInfo>;
  firstSurname: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  region: Scalars['String']['output'];
  regionId: Scalars['String']['output'];
  rut: Scalars['String']['output'];
  secondSurname?: Maybe<Scalars['String']['output']>;
};

export type CustomerInput = {
  address: Scalars['String']['input'];
  addressNumber: Scalars['String']['input'];
  birthday: Scalars['String']['input'];
  commune: Scalars['String']['input'];
  communeId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  financingInfo?: InputMaybe<FinancingInfoInput>;
  firstSurname: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  region: Scalars['String']['input'];
  regionId: Scalars['String']['input'];
  rut: Scalars['String']['input'];
  secondSurname?: InputMaybe<Scalars['String']['input']>;
};

export type Fees = {
  __typename?: 'Fees';
  CapitalAmount: Scalars['Int']['output'];
  CapitalBalance: Scalars['Int']['output'];
  DueDate: Scalars['String']['output'];
  InterestAmount: Scalars['Int']['output'];
  QuoteAmount?: Maybe<Scalars['Int']['output']>;
  QuoteNumber: Scalars['Int']['output'];
  VfmgIndicator: Scalars['String']['output'];
};

export type Financial = {
  __typename?: 'Financial';
  credentials?: Maybe<Array<Credentials>>;
  customName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
};

export type Financing = {
  __typename?: 'Financing';
  financingData: CreditSimulation;
  financingInput: FinancingSimulationInput;
  financingStatus: FinancingStatus;
  financingStatusDetails?: Maybe<FinancingStatusDetails>;
};

export type FinancingConfiguration = {
  __typename?: 'FinancingConfiguration';
  config: Array<Financial>;
  enabled: Scalars['Boolean']['output'];
};

export type FinancingInfo = {
  __typename?: 'FinancingInfo';
  income: Income;
  profile: Profile;
};

export type FinancingInfoInput = {
  income: IncomeInput;
  profile: ProfileInput;
};

export type FinancingInput = {
  downPayment: Scalars['Int']['input'];
  expenses?: InputMaybe<Array<ProductExpenseInput>>;
  income?: InputMaybe<Scalars['Int']['input']>;
  insurance?: InputMaybe<Array<ProductInsuranceInput>>;
  loanType: LoanType;
  paymentsNumber: Scalars['Int']['input'];
  productId?: InputMaybe<Scalars['Int']['input']>;
};

export type FinancingProducts = {
  __typename?: 'FinancingProducts';
  downpaymentPercentages: Array<Scalars['Int']['output']>;
  expenses: Array<ProductExpense>;
  id: Scalars['Int']['output'];
  insurance: Array<ProductInsurance>;
  name: Scalars['String']['output'];
  paymentsNumber: Array<Scalars['Int']['output']>;
  vfmg: Array<Scalars['Int']['output']>;
};

export type FinancingProductsInput = {
  income?: InputMaybe<Scalars['Int']['input']>;
};

export type FinancingSimulationInput = {
  __typename?: 'FinancingSimulationInput';
  downPayment: Scalars['Int']['output'];
  expenses?: Maybe<Array<ProductExpense>>;
  income?: Maybe<Scalars['Int']['output']>;
  insurance?: Maybe<Array<ProductInsurance>>;
  loanType: LoanType;
  paymentsNumber: Scalars['Int']['output'];
  productId?: Maybe<Scalars['Int']['output']>;
};

export enum FinancingStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Rejected = 'REJECTED'
}

export type FinancingStatusDetails = {
  __typename?: 'FinancingStatusDetails';
  motives?: Maybe<Array<Scalars['String']['output']>>;
  observations?: Maybe<Scalars['String']['output']>;
};

export type GuestUser = {
  __typename?: 'GuestUser';
  ExpirationDate: Scalars['Int']['output'];
  concessionaireName: Scalars['String']['output'];
  dealer: Scalars['String']['output'];
  email: Scalars['String']['output'];
  signupToken: Scalars['String']['output'];
};

export type Income = {
  __typename?: 'Income';
  Old: Scalars['String']['output'];
  Type: Scalars['String']['output'];
  Value: Scalars['Float']['output'];
};

export type IncomeInput = {
  Old: Scalars['String']['input'];
  Type: Scalars['String']['input'];
  Value: Scalars['Float']['input'];
};

export enum InsuranceTypes {
  Disgraceins = 'DISGRACEINS',
  Protectedfeeins = 'PROTECTEDFEEINS',
  Safetravelins = 'SAFETRAVELINS',
  Trimaxins = 'TRIMAXINS'
}

export type Integration = {
  __typename?: 'Integration';
  config?: Maybe<IntegrationConfig>;
  enabled: Scalars['Boolean']['output'];
};

export type IntegrationConfig = {
  __typename?: 'IntegrationConfig';
  concessionaireAssignCarMethod: IntegrationMethods;
  concessionaireAssignCarUrl: Scalars['String']['output'];
  concessionaireIntegrationBasePath: Scalars['String']['output'];
  concessionaireModifyStatusMethod: IntegrationMethods;
  concessionaireModifyStatusUrl: Scalars['String']['output'];
};

export enum IntegrationMethods {
  Get = 'GET',
  Options = 'OPTIONS',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT'
}

export type InviteUserInput = {
  concessionaireName: Scalars['String']['input'];
  dealer: Scalars['String']['input'];
  email: Scalars['String']['input'];
  extraConcessionaireName?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rut: Scalars['String']['input'];
};

export type LitePurchaseIntent = {
  __typename?: 'LitePurchaseIntent';
  car?: Maybe<Car>;
  createdAt: Scalars['String']['output'];
  customer?: Maybe<Customer>;
  customerRut?: Maybe<Scalars['String']['output']>;
  legalEntity?: Maybe<Scalars['Boolean']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  purchaseIntent?: Maybe<PurchaseIntent>;
  purchaseIntentId: Scalars['String']['output'];
  sub?: Maybe<Scalars['String']['output']>;
  userInformation?: Maybe<UserInformation>;
};

export type LitePurchaseIntentConnection = {
  __typename?: 'LitePurchaseIntentConnection';
  edges: Array<LitePurchaseIntentEdge>;
  pageInfo: LitePurchaseIntentPageInfo;
};

export type LitePurchaseIntentCursor = {
  __typename?: 'LitePurchaseIntentCursor';
  createdAt: Scalars['String']['output'];
  purchaseIntentId: Scalars['String']['output'];
};

export type LitePurchaseIntentCursorInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  purchaseIntentId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type LitePurchaseIntentEdge = {
  __typename?: 'LitePurchaseIntentEdge';
  cursor: LitePurchaseIntentCursor;
  node: LitePurchaseIntent;
};

export type LitePurchaseIntentPageInfo = {
  __typename?: 'LitePurchaseIntentPageInfo';
  endCursor?: Maybe<LitePurchaseIntentCursor>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<LitePurchaseIntentCursor>;
};

export enum LoanType {
  Cc = 'CC',
  Ci = 'CI'
}

export type Mutation = {
  __typename?: 'Mutation';
  createCar: Car;
  createCustomer: Customer;
  createLitePurchaseIntent: LitePurchaseIntent;
  createPaymentMethod?: Maybe<PaymentMethod>;
  deleteSignUpInvitation: Scalars['String']['output'];
  inviteUser: Scalars['String']['output'];
  requestPreApprovedCredit?: Maybe<PaymentMethod>;
};


export type MutationCreateCarArgs = {
  createCarInput?: InputMaybe<CreateCarInput>;
};


export type MutationCreateCustomerArgs = {
  customerInput?: InputMaybe<CustomerInput>;
};


export type MutationCreateLitePurchaseIntentArgs = {
  createLitePurchaseIntentInput: CreateLitePurchaseIntentInput;
};


export type MutationCreatePaymentMethodArgs = {
  paymentMethodInput: PaymentMethodInput;
};


export type MutationDeleteSignUpInvitationArgs = {
  deleteSignUpInput: Scalars['String']['input'];
};


export type MutationInviteUserArgs = {
  inviteUserInput?: InputMaybe<InviteUserInput>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  carPrice: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  currency: AllowedCurrency;
  financing?: Maybe<Financing>;
  paymentMethodId: Scalars['String']['output'];
  type: AllowedPaymentMethodType;
};

export type PaymentMethodInput = {
  currency: AllowedCurrency;
  financing?: InputMaybe<FinancingInput>;
  type: AllowedPaymentMethodType;
};

export type ProductExpense = {
  __typename?: 'ProductExpense';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ProductExpenseInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type ProductInsurance = {
  __typename?: 'ProductInsurance';
  amount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
  show?: Maybe<Scalars['Boolean']['output']>;
};

export type ProductInsuranceInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  required?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Profile = {
  __typename?: 'Profile';
  ContractType: Scalars['String']['output'];
  Gender: Scalars['String']['output'];
  IncomeType: Scalars['String']['output'];
  Profession: Scalars['String']['output'];
  RelationStatus: Scalars['String']['output'];
  Studies: Scalars['String']['output'];
};

export type ProfileInput = {
  ContractType: Scalars['String']['input'];
  Gender: Scalars['String']['input'];
  IncomeType: Scalars['String']['input'];
  Profession: Scalars['String']['input'];
  RelationStatus: Scalars['String']['input'];
  Studies: Scalars['String']['input'];
};

export type PurchaseFlowConfiguration = {
  __typename?: 'PurchaseFlowConfiguration';
  analyticsApiKey?: Maybe<Scalars['String']['output']>;
  concessionaireId: Scalars['String']['output'];
  concessionaireName: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  failureUrl?: Maybe<Scalars['String']['output']>;
  financingConfiguration: FinancingConfiguration;
  successUrl?: Maybe<Scalars['String']['output']>;
};

export type PurchaseIntent = {
  __typename?: 'PurchaseIntent';
  carMinInfoId?: Maybe<Scalars['String']['output']>;
  concessionaireId?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  car?: Maybe<Car>;
  concessionaire?: Maybe<Concessionaire>;
  concessionaireStyle?: Maybe<ConcessionaireStyle>;
  customer?: Maybe<Customer>;
  getUser: User;
  listGuestUser: Array<GuestUser>;
  listLitePurchaseIntent: Array<LitePurchaseIntent>;
  listLitePurchaseIntentToCsv: Scalars['String']['output'];
  listUser: Array<User>;
  litePurchaseIntent: LitePurchaseIntent;
  paginateLitePurchaseIntent?: Maybe<LitePurchaseIntentConnection>;
  paymentMethod?: Maybe<PaymentMethod>;
  purchaseFlowConfiguration?: Maybe<PurchaseFlowConfiguration>;
  registryBrands: Array<RegistryRow>;
  registryCommunes: Array<RegistryRow>;
  registryModels: Array<RegistryRow>;
  registryRegions: Array<RegistryRow>;
  registryVersions: Array<RegistryRow>;
  requestCreditSimulation?: Maybe<CreditSimulation>;
  requestFinancingProducts: Array<FinancingProducts>;
  unitedLitePurchaseIntent: LitePurchaseIntent;
};


export type QueryConcessionaireArgs = {
  concessionaireId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUserArgs = {
  cognitoId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListGuestUserArgs = {
  concessionaireName: Scalars['String']['input'];
};


export type QueryListLitePurchaseIntentToCsvArgs = {
  dateRange: Array<Scalars['String']['input']>;
};


export type QueryPaginateLitePurchaseIntentArgs = {
  after: LitePurchaseIntentCursorInput;
  first: Scalars['Int']['input'];
};


export type QueryRegistryCommunesArgs = {
  regionId: Scalars['String']['input'];
};


export type QueryRegistryModelsArgs = {
  brandId: Scalars['String']['input'];
};


export type QueryRegistryVersionsArgs = {
  modelId: Scalars['String']['input'];
};


export type QueryRequestCreditSimulationArgs = {
  financingInput: FinancingInput;
};


export type QueryRequestFinancingProductsArgs = {
  financingProductsInput: FinancingProductsInput;
};

export type RegistryRow = {
  __typename?: 'RegistryRow';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  cognitoId: Scalars['String']['output'];
  concessionaireId: Scalars['String']['output'];
  dealer: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  role: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type UserInformation = {
  __typename?: 'UserInformation';
  dealer?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sub?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};
