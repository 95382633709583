/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, message } from 'antd';
import { formatRut, validateRut } from '@abstract-cl/rut-ts';
import { Button, Input } from '@cloudcar-app/cloudcar-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { concessionaireLogoUrl, isAppLoading } from 'apollo/cache';
import apiService from 'utils/apiService';
import MessageError from 'utils/message.errors';
import rutFormValidator from 'utils/rutFormValidator';
import comparePasswords from 'utils/comparePasswordsValidator';
import styles from './SignUp.module.scss';

interface SignUpValues {
  rut: string;
  password: string;
  email: string;
  name: string;
  signupToken: string;
  dealer: string;
  firstName: string;
}

const SignUp: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [requested, setRequested] = useState(false);
  const logoUrl = useReactiveVar(concessionaireLogoUrl);
  const { pathname, search } = useLocation();
  const urlName = new URLSearchParams(search).get('name')?.replace('%', ' ');
  const urlEmail = new URLSearchParams(search).get('email');
  const urlRut = new URLSearchParams(search).get('rut');
  const urlDealer = new URLSearchParams(search).get('dealer');
  const urlfirstName = new URLSearchParams(search).get('firstName');

  const handleChangeRut = (rut: string): string => {
    if (validateRut(rut)) {
      const formatted: string = formatRut(rut) || '';
      form.setFieldsValue({ rut: formatted });
      return rut;
    }
    form.setFieldsValue({
      rut: rut.split('.').join('').split('-').join(''),
    });
    return rut;
  };

  const sendForm = async (values: SignUpValues) => {
    try {
      setRequested(true);
      const signupToken = pathname.split('/').pop() as string;

      const params = {
        ...values,
        signupToken,
      };

      await apiService.signUpRequest(params);
      isAppLoading(true);
      setRequested(false);
      message.success('Registro exitoso!', 2);
      history.push('/');
    } catch (err) {
      setRequested(false);
      message.loading('Error', 1);
    }
  };

  const renderPageTitle = () => {
    return 'Cotizador Tanner en línea!';
  };

  useEffect(() => {
    form.setFieldsValue({
      name: urlName || '',
      email: urlEmail || '',
      dealer: urlDealer || '',
      firstName: urlfirstName || '',
      rut: formatRut(urlRut) || '',
    });
  }, [urlName, urlEmail]);

  return (
    <Row className={styles.Container}>
      <div className={styles.Background} />
      <Col xs={0} sm={8} className={styles.FullHeight}>
        <Row justify="center" align="middle" className={styles.FullSize}>
          <Col xs={16}>
            {logoUrl && (
              <Row justify="center" align="middle" className={styles.MarginBottom}>
                <img src={logoUrl} alt="logo" width="100" />
              </Row>
            )}
            <Row justify="center" align="middle">
              <h1 className={styles.CenteredText}>{renderPageTitle()}</h1>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={16} className={styles.FullHeight}>
        <Row justify="start" align="middle" className={styles.FullHeight}>
          <Col>
            <Form form={form} name="login" onFinish={sendForm}>
              <Row justify="start" align="middle" className={styles.SignUp} gutter={12}>
                <Col xs={24}>
                  <Row justify="center" align="middle" gutter={12}>
                    <h1 className={styles.Title}>Registro</h1>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="name"
                    className={styles.FormItem}
                    rules={[
                      {
                        required: true,
                        message: MessageError.emptyFieldsMessages.name,
                      },
                      {
                        whitespace: true,
                        message: MessageError.emptyFieldsMessages.name,
                      },
                    ]}
                  >
                    <Input label="Nombre" placeholder="Nombre" required />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="firstName"
                    className={styles.FormItem}
                    rules={[
                      {
                        required: true,
                        message: MessageError.emptyFieldsMessages.lastName,
                      },
                      {
                        whitespace: true,
                        message: MessageError.emptyFieldsMessages.lastName,
                      },
                    ]}
                  >
                    <Input label="Apellido" placeholder="Apellido" required />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="rut"
                    className={styles.FormItem}
                    rules={[
                      { required: true, message: MessageError.emptyFieldsMessages.rut },
                      {
                        whitespace: true,
                        message: MessageError.emptyFieldsMessages.rut,
                      },
                      {
                        validator: rutFormValidator,
                      },
                    ]}
                  >
                    <Input
                      label="Rut"
                      placeholder="Rut"
                      onChange={(e) => handleChangeRut(e.target.value)}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="email"
                    className={styles.FormItem}
                    rules={[
                      {
                        required: true,
                        message: MessageError.emptyFieldsMessages.email,
                      },
                      {
                        whitespace: true,
                        message: MessageError.emptyFieldsMessages.email,
                      },
                    ]}
                  >
                    <Input label="Email" placeholder="Email@example.com" required />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="dealer"
                    className={styles.FormItem}
                    rules={[
                      {
                        required: true,
                        message: MessageError.emptyFieldsMessages.dealer,
                      },
                      {
                        whitespace: true,
                        message: MessageError.emptyFieldsMessages.dealer,
                      },
                    ]}
                  >
                    <Input label="Sucursal" placeholder="Sucursal" required disabled />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="password"
                    className={styles.FormItem}
                    rules={[
                      {
                        required: true,
                        message: MessageError.emptyFieldsMessages.password,
                      },
                      {
                        whitespace: true,
                        message: MessageError.emptyFieldsMessages.password,
                      },
                      {
                        min: 6,
                        message:
                          MessageError.invalidFormatFieldsMessages.passwordMinLength,
                      },
                    ]}
                  >
                    <Input password label="Contraseña" placeholder="*********" required />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="confirmPassword"
                    className={styles.FormItem}
                    rules={[
                      {
                        required: true,
                        message: MessageError.emptyFieldsMessages.confirmPassword,
                      },
                      {
                        whitespace: true,
                        message: MessageError.emptyFieldsMessages.confirmPassword,
                      },
                      {
                        validator: (rule, value) =>
                          comparePasswords(rule, value as string, form),
                        message: MessageError.invalidFormatFieldsMessages.passwordNoMatch,
                      },
                    ]}
                  >
                    <Input
                      password
                      label="Confirmar contraseña"
                      placeholder="*********"
                      required
                    />
                  </Form.Item>
                </Col>
                <Row className={styles.ButtonContainer} justify="center">
                  <Button
                    htmlType="submit"
                    size="large"
                    loading={requested}
                    disabled={requested}
                  >
                    Registrarse
                  </Button>
                </Row>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SignUp;
