import React, { FC, useEffect } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Car } from 'apollo/types';
import operations from 'apollo/operations';
import { listPrice, purchaseIntentToken } from 'apollo/cache';

const Component: FC = () => {
  const tokenHasBeenSet = useReactiveVar(purchaseIntentToken);

  const { data, refetch } = useQuery<{ car: Car }>(operations.GET_CAR, {
    fetchPolicy: 'network-only',
    skip: !localStorage.getItem('AccessToken'),
  });

  useEffect(() => {
    if (data && data.car) {
      listPrice(data.car.price);
    }
  }, [data]);

  useEffect(() => {
    if (tokenHasBeenSet) {
      refetch();
    }
  }, [tokenHasBeenSet]);

  return <></>;
};

export default Component;
