import { StepInfo } from 'apollo/types';
import steps from './stepsInfo';

const getStepIndexByPathname = (
  pathname: string,
  stepList: StepInfo[] = steps
): number => {
  if (pathname === '/') return 0;
  return stepList.findIndex((possibleStep) => possibleStep.route === pathname);
};

export default getStepIndexByPathname;
