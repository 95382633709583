const formatDateToValidJsStringFormat = (date: string): string => {
  const dateParts = date.split('-');
  const firstDateSection = dateParts[0];

  let formattedDate: string;

  if (firstDateSection.length === 2) {
    formattedDate = new Date(
      `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`
    ).toISOString();
  } else {
    formattedDate = new Date(date).toISOString();
  }
  return formattedDate;
};

export default formatDateToValidJsStringFormat;
