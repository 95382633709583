/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DocumentNode } from 'graphql';
import carOperations from './car';
import configurationOperations from './configuration';
import customerOperations from './customer';
import financingOperations from './financing';
import navigationOperations from './navigation';
import paymentMethodOperations from './paymentMethod';
import userOperations from './user';
import purchaseIntentOperations from './purchaseIntent';

const operations: { [key: string]: DocumentNode } = {
  ...carOperations,
  ...configurationOperations,
  ...customerOperations,
  ...financingOperations,
  ...navigationOperations,
  ...paymentMethodOperations,
  ...userOperations,
  ...purchaseIntentOperations,
};

export default operations;
