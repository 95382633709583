import getScreenValue from './getScreenValue';

const smallSpacings = {
  XS: 4,
  S: 8,
  M: 12,
  L: 12,
  XL: 16,
  XXL: 24,
};

const largeSpacings = {
  XS: 4,
  S: 8,
  M: 12,
  L: 16,
  XL: 24,
  XXL: 36,
};

const getScreenSpacings = (maxScreen: number) => {
  return getScreenValue(maxScreen, largeSpacings, smallSpacings);
};

export default getScreenSpacings;
