/* eslint-disable prettier/prettier */
import React, { FC } from 'react';
import { Modal, Row, Col, Popover } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import {
  Breakpoints,
  Scrollable,
  useMaxBreakpoint,
} from '@cloudcar-app/cloudcar-components';
import { useReactiveVar } from '@apollo/client';
import { LoanType, ProductInsurance } from 'apollo/types';
import { listPrice, selectedFinancialName } from 'apollo/cache';
import currencyFormatter from 'utils/currencyFormatter';
import styles from './CreditDetailsModal.module.scss';

interface CreditDetailsModalProps {
  visible: boolean;
  handleOk: () => void;
  creditInfo: {
    car?: string;
    price?: number;
    financingAmount?: number;
    monthlyFee?: number;
    paymentsNumber?: number;
    creditType?: LoanType;
    downPayment?: number;
    interestRate?: number;
    cae?: number;
    lastPaymentValue?: number;
    DealerCommission?: number;
    finalCost?: number;
    insurance?: ProductInsurance[];
  };
}

const CreditDetailsModal: FC<CreditDetailsModalProps> = (
  props: CreditDetailsModalProps
) => {
  const { visible, handleOk, creditInfo } = props;
  const maxCurrentScreen = useMaxBreakpoint();

  const selectedFinancialNameCache = useReactiveVar(selectedFinancialName);
  const purchaseItemListPrice = useReactiveVar(listPrice);

  const vfmgDescription =
    'Valor Futuro Mínimo Garantizado y corresponde al valor que tendrá tu auto dentro de dos o tres años, según sea el plazo del crédito.';

  const caeDescription =
    'El CAE o Carga Anual Equivalente, es el indicador que, expresado en forma de porcentaje, revela el costo de un crédito en un período anual, cualquiera sea el plazo pactado para el pago de la obligación. La Carga Anual Equivalente incluye el capital, tasa de interés, el plazo del Crédito de Consumo, todos los Gastos o Cargos Propios del Crédito y los Gastos o Cargos por Productos o Servicios Voluntariamente Contratados, si los hubiere, conforme lo definido en el “Reglamento sobre información al consumidor de créditos de consumo”.';

  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleOk}
      footer={null}
      width={maxCurrentScreen < Breakpoints.LG ? '80%' : '40%'}
    >
      <Scrollable scrollY className="CloudCarModal">
        <Row justify="center">
          <h1 className={styles.ModalTitle}>Detalles del crédito</h1>
        </Row>
        <div className={styles.CreditInfoContainer}>
          {creditInfo.car && (
            <Row justify="space-between">
              <Col className={styles.CreditInfo}>Vehículo</Col>
              <Col className={styles.CreditInfo}>{creditInfo.car}</Col>
            </Row>
          )}
          {purchaseItemListPrice && (
            <Row justify="space-between">
              <Col className={styles.CreditInfo}>Precio de lista</Col>
              <Col className={styles.CreditInfo}>
                {currencyFormatter.format(purchaseItemListPrice)}
              </Col>
            </Row>
          )}
          <hr className={styles.Separator} />
          <Row justify="space-between">
            <Col className={styles.CreditInfo}>Financiera</Col>
            <Col className={styles.CreditInfo}>{selectedFinancialNameCache}</Col>
          </Row>
          {creditInfo.creditType && (
            <Row justify="space-between">
              <Col className={styles.CreditInfo}>Tipo de crédito</Col>
              <Col className={styles.CreditInfo}>
                {creditInfo.creditType === LoanType.Ci
                  ? 'Crédito Inteligente'
                  : 'Crédito Convencional'}
              </Col>
            </Row>
          )}
          {creditInfo.downPayment && (
            <Row justify="space-between">
              <Col className={styles.CreditInfo}>Pie</Col>
              <Col className={styles.CreditInfo}>
                {currencyFormatter.format(creditInfo.downPayment)}
              </Col>
            </Row>
          )}
          {creditInfo.paymentsNumber && (
            <Row justify="space-between">
              <Col className={styles.CreditInfo}>Número de cuotas</Col>
              <Col className={styles.CreditInfo}>{creditInfo.paymentsNumber}</Col>
            </Row>
          )}
          {creditInfo.financingAmount && (
            <Row justify="space-between">
              <Col className={styles.CreditInfo}>Monto a financiar</Col>
              <Col className={styles.CreditInfo}>
                {currencyFormatter.format(creditInfo.financingAmount)}
              </Col>
            </Row>
          )}
          <hr className={styles.Separator} />
          {!!creditInfo.interestRate && (
            <Row justify="space-between">
              <Col className={styles.CreditInfo}>Tasa</Col>
              <Col className={styles.CreditInfo}>{`${creditInfo.interestRate} %`}</Col>
            </Row>
          )}
          {!!creditInfo.cae && (
            <Row justify="space-between">
              <Col className={styles.CreditInfo}>
                <Row align="middle">
                  CAE
                  <Popover
                    overlayClassName={styles.Popover}
                    content={caeDescription}
                    className={styles.Popover}
                  >
                    <QuestionCircleTwoTone className={styles.InfoCircleTwoTone} />
                  </Popover>
                </Row>
              </Col>
              <Col className={styles.CreditInfo}>{`${creditInfo.cae} %`}</Col>
            </Row>
          )}
          {creditInfo.monthlyFee && (
            <Row justify="space-between">
              <Col className={styles.CreditInfo}>Valor cuota</Col>
              <Col className={styles.CreditInfo}>
                {currencyFormatter.format(creditInfo.monthlyFee)}
              </Col>
            </Row>
          )}
          {creditInfo.creditType === LoanType.Ci && !!creditInfo.lastPaymentValue && (
            <Row justify="space-between">
              <Col className={styles.CreditInfo}>
                <Row align="middle">
                  Valor última cuota (VFMG)
                  <Popover
                    overlayClassName={styles.Popover}
                    content={vfmgDescription}
                    className={styles.Popover}
                  >
                    <QuestionCircleTwoTone className={styles.InfoCircleTwoTone} />
                  </Popover>
                </Row>
              </Col>
              <Col className={styles.CreditInfo}>
                {currencyFormatter.format(creditInfo.lastPaymentValue)}
              </Col>
            </Row>
          )}
          {creditInfo.finalCost && (
            <Row justify="space-between">
              <Col className={styles.CreditInfo}>Costo total del crédito</Col>
              <Col className={styles.CreditInfo}>
                {currencyFormatter.format(creditInfo.finalCost)}
              </Col>
            </Row>
          )}
          {creditInfo.DealerCommission && (
            <Row justify="space-between">
              <Col className={styles.CreditInfo}>Comisión del Dealer</Col>
              <Col className={styles.CreditInfo}>
                {currencyFormatter.format(creditInfo.DealerCommission)}
              </Col>
            </Row>
          )}
          {creditInfo.insurance &&
            creditInfo.insurance.length > 0 &&
            creditInfo.insurance.find((insurance) => insurance.show) && (
              <>
                <hr className={styles.Separator} />
                <h3 className={styles.ModalSubtitle}>Seguros para el financiamiento</h3>
                <Row>
                  {creditInfo.insurance
                    .filter((insurance) => insurance.show)
                    .map((insurance: ProductInsurance, index: number) => (
                      <Col xs={24} key={insurance.id}>
                        <Row justify="space-between">
                          <Col
                            className={[
                              styles.CreditInfo,
                              index === (creditInfo.insurance || []).length - 1 &&
                              styles.NoMarginBottom,
                            ].join(' ')}
                          >
                            {insurance.name}
                          </Col>
                          <Col
                            className={[
                              styles.CreditInfo,
                              index === (creditInfo.insurance || []).length - 1 &&
                              styles.NoMarginBottom,
                            ].join(' ')}
                          >
                            {currencyFormatter.format(insurance.amount || 0)}
                          </Col>
                        </Row>
                      </Col>
                    ))}
                </Row>
              </>
            )}
        </div>
      </Scrollable>
    </Modal>
  );
};

export default CreditDetailsModal;
