import React, { FC, useEffect } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import operations from 'apollo/operations';
import { CreditStates, Customer, FinancingStatus, PaymentMethod } from 'apollo/types';
import {
  loadingCredit,
  selectedPaymentMethod,
  selectedPurchaseIntent,
} from 'apollo/cache';

const PurchaseOptions: FC = () => {
  const selectedPurchaseIntentCache = useReactiveVar(selectedPurchaseIntent);
  const { data: paymentMethodData, refetch: refetchPaymentMethod } = useQuery<{
    paymentMethod: PaymentMethod;
  }>(operations.GET_PAYMENT_METHOD, {
    fetchPolicy: 'network-only',
    skip: !localStorage.getItem('AccessToken'),
  });

  const { data: customerData, refetch: refetchCustomer } = useQuery<{
    customer: Customer;
  }>(operations.GET_CUSTOMER_INFO, {
    fetchPolicy: 'network-only',
    skip: !localStorage.getItem('AccessToken'),
  });

  useEffect(() => {
    if (selectedPurchaseIntentCache) {
      refetchPaymentMethod();
      refetchCustomer();
    }
  }, [selectedPurchaseIntentCache]);

  const setPaymentMethod = () => {
    if (paymentMethodData && paymentMethodData.paymentMethod) {
      selectedPaymentMethod(paymentMethodData.paymentMethod.type);
    }
  };

  const handleFinancingStatus = (paymentMethod: PaymentMethod) => {
    if (customerData && customerData.customer && customerData.customer.financingInfo) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const creditResponse = paymentMethod.financing!.financingStatus;
      if (creditResponse === FinancingStatus.Approved) {
        loadingCredit(CreditStates.Approved);
      } else if (creditResponse === FinancingStatus.Rejected) {
        loadingCredit(CreditStates.Rejected);
      } else {
        loadingCredit(CreditStates.Loading);
      }
    }
  };

  useEffect(() => {
    setPaymentMethod();
    if (
      paymentMethodData &&
      paymentMethodData.paymentMethod &&
      paymentMethodData.paymentMethod.financing
    ) {
      handleFinancingStatus(paymentMethodData.paymentMethod);
    }
  }, [paymentMethodData, customerData]);

  return <></>;
};

export default PurchaseOptions;
