import React, { FC, ReactElement } from 'react';
import TweenOne from 'rc-tween-one';
import { useHistory } from 'react-router-dom';

interface AnimationProps {
  skipAnimation: boolean;
  animationDirectionForward: boolean;
  children: ReactElement;
  title: string;
}

const LayoutAnimation: FC<AnimationProps> = (props: AnimationProps) => {
  const { skipAnimation, animationDirectionForward, title, children } = props;
  const history = useHistory();

  const animationCleanup = () => {
    const component = document.querySelector(
      '[class*="ComponentContainer_ComponentContainer"]'
    );
    const container = document.querySelector('[class*="StepRenderer_StepContainer"]');
    if (component && container) {
      container.removeChild(container.children[0]);
      container.appendChild(component);
    }
  };

  if (
    history.location.pathname === '/termsAndConditions' ||
    history.location.pathname === '/'
  ) {
    return children;
  }
  return (
    <TweenOne
      paused={skipAnimation}
      moment={skipAnimation ? 10000 : undefined}
      animation={{
        y: animationDirectionForward ? -500 : 50,
        opacity: animationDirectionForward ? 1 : 0.5,
        duration: animationDirectionForward ? 600 : 450,
        onComplete: () => animationCleanup(),
      }}
    >
      <div
        key={title}
        style={{
          transform: `translateY(${animationDirectionForward ? '500px' : '-50px'})`,
        }}
      >
        {children}
      </div>
    </TweenOne>
  );
};

export default LayoutAnimation;
