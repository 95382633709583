import React, { FC } from 'react';
import { Modal, Row } from 'antd';
import {
  Scrollable,
  useMaxBreakpoint,
  Breakpoints,
} from '@cloudcar-app/cloudcar-components';
import styles from './CreditFinancingConditions.module.scss';

interface CreditFinancingConditionsDetailModalProps {
  visible: boolean;
  handleOk: () => void;
}
const CreditFinancingConditionsDetailModal: FC<
  CreditFinancingConditionsDetailModalProps
> = (props: CreditFinancingConditionsDetailModalProps) => {
  const { visible, handleOk } = props;
  const maxCurrentScreen = useMaxBreakpoint();
  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleOk}
      footer={null}
      width={maxCurrentScreen < Breakpoints.LG ? '80%' : '60%'}
    >
      <Scrollable scrollY className="CloudCarModal">
        <Row justify="center">
          <h1 className={styles.ModalTitle}>Términos y condiciones del financiamiento</h1>
        </Row>
        <Row justify="start" className={styles.ModalText}>
          <h2>
            Toda solicitud de evaluación tendrá una vigencia de 7 días. Pre-aprobaciones
            sujetas a validación de documentación de respaldo.
          </h2>
        </Row>
      </Scrollable>
    </Modal>
  );
};

export default CreditFinancingConditionsDetailModal;
