import { gql } from '@apollo/client';

const GET_PURCHASE_STEP_VALIDATION = gql`
  query stepsValidation {
    paymentMethod {
      type
    }
    customer {
      rut
    }
    delivery {
      rut
    }
    termsAndConditions {
      accepted
    }
    purchase {
      purchaseId
    }
  }
`;

const GET_ERROR = gql`
  query GetError {
    showErrorModal @client
    errorData @client
  }
`;

const navigationOperations = {
  GET_ERROR,
  GET_PURCHASE_STEP_VALIDATION,
};

export default navigationOperations;
