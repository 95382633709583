/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import {
  ApolloClient,
  from,
  HttpLink,
  NormalizedCacheObject,
  Observable,
} from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { SchemaLink } from '@apollo/client/link/schema';
import { ServerError } from '@apollo/client/link/utils';
import { addMocksToSchema } from '@graphql-tools/mock';
import config from 'config';
import schema from 'apollo/schema';
import cache from 'apollo/cache';
import { CustomError, CodeMessage } from 'apollo/types';
import ErrorHandler from 'error/ErrorHandler';
import utils from 'utils/apiService/utils';

const { apiUrl } = config;
const { refreshTokensRequest, logout } = utils;

interface RefreshTokenResponse {
  data: {
    IdToken: string;
    AccessToken: string;
  };
}

const onErrorRefreshRequest = ({ operation, forward }: ErrorResponse) =>
  new Observable((observer) => {
    refreshTokensRequest()
      .then((response: RefreshTokenResponse) => {
        const purchaseIntentId = localStorage.getItem('purchaseIntentId');
        localStorage.setItem('IdToken', response.data.IdToken);
        localStorage.setItem('AccessToken', response.data.AccessToken);
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            authorization: `Bearer ${response.data.IdToken}` || null,
            // eslint-disable-next-line prettier/prettier
            customer_purchase_intent_id: purchaseIntentId || '',
          },
        }));
      })
      .then(() => {
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };
        forward(operation).subscribe(subscriber);
      })
      .catch((error) => {
        logout();
        observer.error(error);
      });
  });

const errorLink = onError(
  ({ operation, forward, graphQLErrors, networkError }: ErrorResponse): any => {
    if (graphQLErrors) {
      // eslint-disable-next-line consistent-return, no-unreachable-loop
      for (const gqlError of graphQLErrors) {
        if (
          gqlError.extensions &&
          typeof gqlError.extensions.name === 'string' &&
          gqlError.extensions.name.includes('NotAuthorizedException')
        ) {
          return onErrorRefreshRequest({ operation, forward });
        }
        const wrappedError: CustomError = {
          codeMessage:
            gqlError.extensions && gqlError.extensions.codeMessage
              ? (gqlError.extensions.codeMessage as CodeMessage)
              : undefined,
          type:
            gqlError.extensions && gqlError.extensions.type
              ? `${gqlError.extensions.type as string}`
              : 'FATAL',
          name:
            gqlError.extensions && gqlError.extensions.name
              ? `${gqlError.extensions.name as string}`
              : 'UNKNOWN',
          message: `${gqlError.message}${
            gqlError.extensions && gqlError.extensions.name
              ? ` - ${gqlError.extensions.name as string}`
              : ''
          }`,
        };
        ErrorHandler(wrappedError);
        return Observable.of(operation);
      }
      return Observable.of(operation);
    }
    if (
      networkError &&
      'statusCode' in networkError &&
      (networkError as ServerError).statusCode === 401
    ) {
      return onErrorRefreshRequest({ operation, forward });
    }
    if (networkError) {
      const wrappedError: CustomError = {
        type: 'FATAL',
        name: 'NETWORK',
        message: networkError.message,
      };
      ErrorHandler(wrappedError);
      return Observable.of(operation);
    }
    return forward(operation);
  }
);

const authLink = setContext((operation, { headers }) => {
  const token = localStorage.getItem('IdToken');
  const purchaseIntentId = localStorage.getItem('purchaseIntentId');

  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      // eslint-disable-next-line prettier/prettier
      customer_purchase_intent_id: purchaseIntentId || '',
    },
  };
});

const schemaWithMocks = addMocksToSchema({
  schema,
});

const schemaLink = new SchemaLink({ schema: schemaWithMocks });

const uri = new HttpLink({ uri: apiUrl });

const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, uri]),
  cache,
});

export const mockClient = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  cache,
  uri: apiUrl,
  link: from([errorLink, schemaLink]),
});

const selectApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  return apolloClient;
};

export default selectApolloClient;
