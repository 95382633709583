import { gql } from '@apollo/client';

const GET_BASE_LITE_PURCHASE_INTENT = gql`
  query litePurchaseIntent {
    litePurchaseIntent {
      purchaseIntentId
      customerRut
      legalEntity
      purchaseIntent {
        carMinInfoId
        concessionaireId
        price
      }
      createdAt
    }
  }
`;

const LIST_LITE_PURCHASE_INTENT = gql`
  query listLitePurchaseIntent {
    listLitePurchaseIntent {
      purchaseIntentId
      customerRut
      purchaseIntent {
        carMinInfoId
        concessionaireId
        price
      }
      createdAt
      car {
        carId
        carMinInfoId
        createdAt
        brand
        model
        year
        version
        color
        price
        cit
      }
      paymentMethod {
        paymentMethodId
        createdAt
        type
        carPrice
        currency
        financing {
          financingStatus
          financingStatusDetails {
            motives
            observations
          }
          financingInput {
            downPayment
            paymentsNumber
            loanType
          }
          financingData {
            DealerCommission
            FinancingAmount
            InterestAmount
            OperationalCost
            Cae
            InterestRate
            Fees {
              QuoteNumber
              QuoteAmount
              CapitalAmount
              CapitalBalance
              DueDate
              InterestAmount
              VfmgIndicator
            }
            Insurance {
              id
              show
              name
              amount
              required
            }
          }
        }
      }
      userInformation {
        dealer
        username
        name
        firstName
      }
      customer {
        createdAt
        name
        firstSurname
        secondSurname
        rut
        phone
        email
        birthday
        address
        addressNumber
        commune
        region
        country
        financingInfo {
          income {
            Type
            Value
            Old
          }
          profile {
            Gender
            RelationStatus
            Studies
            ContractType
            IncomeType
            Profession
          }
        }
      }
    }
  }
`;

const GET_LITE_PURCHASE_INTENT = gql`
  query unitedLitePurchaseIntent {
    unitedLitePurchaseIntent {
      purchaseIntentId
      customerRut
      userInformation {
        dealer
        username
        name
        firstName
      }
      purchaseIntent {
        carMinInfoId
        concessionaireId
        price
      }
      createdAt
      car {
        carId
        carMinInfoId
        createdAt
        brand
        model
        year
        version
        color
        price
        cit
      }
      paymentMethod {
        paymentMethodId
        createdAt
        type
        carPrice
        currency
        financing {
          financingStatus
          financingStatusDetails {
            motives
            observations
          }
          financingInput {
            downPayment
            paymentsNumber
            loanType
          }
          financingData {
            DealerCommission
            FinancingAmount
            InterestAmount
            OperationalCost
            Cae
            InterestRate
            Fees {
              QuoteNumber
              QuoteAmount
              CapitalAmount
              CapitalBalance
              DueDate
              InterestAmount
              VfmgIndicator
            }
            Insurance {
              id
              show
              name
              amount
              required
            }
          }
        }
      }
      customer {
        createdAt
        name
        firstSurname
        secondSurname
        rut
        phone
        email
        birthday
        address
        addressNumber
        commune
        region
        country
        financingInfo {
          income {
            Type
            Value
            Old
          }
          profile {
            Gender
            RelationStatus
            Studies
            ContractType
            IncomeType
            Profession
          }
        }
      }
    }
  }
`;

const CREATE_LITE_PURCHASE_INTENT = gql`
  mutation createLitePurchaseIntent(
    $createLitePurchaseIntentInput: CreateLitePurchaseIntentInput!
  ) {
    createLitePurchaseIntent(
      createLitePurchaseIntentInput: $createLitePurchaseIntentInput
    ) {
      customerRut
      purchaseIntentId
      purchaseIntent {
        carMinInfoId
        concessionaireId
        price
      }
      createdAt
      car {
        carId
        carMinInfoId
        createdAt
        brand
        model
        year
        version
        color
        price
        cit
      }
      paymentMethod {
        paymentMethodId
        createdAt
        type
        carPrice
        currency
        financing {
          financingStatus
          financingStatusDetails {
            motives
            observations
          }
          financingInput {
            downPayment
            paymentsNumber
            loanType
          }
          financingData {
            FinancingAmount
            InterestAmount
            DealerCommission
            OperationalCost
            Cae
            InterestRate
            Fees {
              QuoteNumber
              QuoteAmount
              CapitalAmount
              CapitalBalance
              DueDate
              InterestAmount
              VfmgIndicator
            }
            Insurance {
              id
              show
              name
              amount
              required
            }
          }
        }
      }
      customer {
        createdAt
        name
        firstSurname
        secondSurname
        rut
        phone
        email
        birthday
        address
        addressNumber
        commune
        region
        country
        financingInfo {
          income {
            Type
            Value
            Old
          }
          profile {
            Gender
            RelationStatus
            Studies
            ContractType
            IncomeType
            Profession
          }
        }
      }
    }
  }
`;

const LIST_LITE_PURCHASE_INTENT_TO_CSV = gql`
  query listLitePurchaseIntentToCsv($dateRange: [String!]!) {
    listLitePurchaseIntentToCsv(dateRange: $dateRange)
  }
`;

const GET_ALL_LITE_PURCHASE_INTENT = gql`
  query paginateLitePurchaseIntent($first: Int!, $after: LitePurchaseIntentCursorInput!) {
    paginateLitePurchaseIntent(first: $first, after: $after) {
      edges {
        node {
          purchaseIntentId
          customerRut
          purchaseIntent {
            carMinInfoId
            concessionaireId
            price
          }
          createdAt
          car {
            carMinInfoId
            createdAt
            brand
            model
            year
            version
            color
            price
            cit
          }
          paymentMethod {
            createdAt
            type
            carPrice
            currency
            financing {
              financingStatus
              financingStatusDetails {
                motives
                observations
              }
              financingInput {
                downPayment
                paymentsNumber
                loanType
              }
              financingData {
                DealerCommission
                FinancingAmount
                InterestAmount
                OperationalCost
                Cae
                InterestRate
                Fees {
                  QuoteNumber
                  QuoteAmount
                  CapitalAmount
                  CapitalBalance
                  DueDate
                  InterestAmount
                  VfmgIndicator
                }
                Insurance {
                  id
                  show
                  name
                  amount
                  required
                }
              }
            }
          }
          userInformation {
            dealer
            username
            name
            firstName
          }
          customer {
            createdAt
            name
            firstSurname
            secondSurname
            rut
            phone
            email
            birthday
            address
            addressNumber
            commune
            region
            country
            financingInfo {
              income {
                Type
                Value
                Old
              }
              profile {
                Gender
                RelationStatus
                Studies
                ContractType
                IncomeType
                Profession
              }
            }
          }
        }
        cursor {
          purchaseIntentId
        }
      }
      pageInfo {
        startCursor {
          purchaseIntentId
          createdAt
        }
        endCursor {
          purchaseIntentId
          createdAt
        }
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const purchaseIntentOperations = {
  GET_BASE_LITE_PURCHASE_INTENT,
  GET_ALL_LITE_PURCHASE_INTENT,
  CREATE_LITE_PURCHASE_INTENT,
  LIST_LITE_PURCHASE_INTENT,
  GET_LITE_PURCHASE_INTENT,
  LIST_LITE_PURCHASE_INTENT_TO_CSV,
};

export default purchaseIntentOperations;
