import { useEffect } from 'react';
import { useReactiveVar, useQuery } from '@apollo/client';
import {
  concessionaireLogoUrl,
  isStylesConfigurationLoading,
  logged as loggedCache,
} from 'apollo/cache';
import hex2rgb from 'utils/hex2rgb';
import { ConcessionaireStyle } from 'apollo/types';
import operations from 'apollo/operations';

const Styles = () => {
  const logged = useReactiveVar(loggedCache);

  const { data } = useQuery<{
    concessionaireStyle: ConcessionaireStyle;
  }>(operations.GET_CONCESSIONAIRE_STYLE, {
    skip: !logged,
  });

  // const concessionaireStyles = (concessionaireKey: string) => {
  //   const styles: {
  //     [key: string]: {
  //       primaryColor: string;
  //     };
  //   } = {
  //     tanner: {
  //       primaryColor: '#025cb8',
  //     },
  //   };
  //   if (Array.from(Object.keys(styles)).includes(concessionaireKey)) {
  //     return styles[concessionaireKey];
  //   }
  //   return {
  //     primaryColor: '#025cb8',
  //   };
  // };

  const setApplicationColorsAndFavicon = ({ primaryColor }: { primaryColor: string }) => {
    const { body } = document;
    const rgbprimary = hex2rgb(primaryColor);
    body?.style.setProperty(
      '--primary',
      `rgba(${rgbprimary[0]}, ${rgbprimary[1]}, ${rgbprimary[2]}, 1)`
    );
    body?.style.setProperty(
      '--primary-opacity',
      `rgba(${rgbprimary[0]}, ${rgbprimary[1]}, ${rgbprimary[2]}, 0.5)`
    );
    body?.style.setProperty(
      '--pale-primary',
      `rgba(${rgbprimary[0]}, ${rgbprimary[1]}, ${rgbprimary[2]}, 0.07)`
    );
    body?.style.setProperty(
      '--dark-primary',
      `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), ${primaryColor}`
    );
    body?.style.setProperty(
      '--primary-focus-outline',
      `rgba(${rgbprimary[0]}, ${rgbprimary[1]}, ${rgbprimary[2]}, 0.3)`
    );
    const link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
    if (data && data.concessionaireStyle.concessionaireLogoUrl) {
      if (link) {
        link.href = data.concessionaireStyle.concessionaireLogoUrl;
        concessionaireLogoUrl(data.concessionaireStyle.concessionaireLogoUrl);
      }
    }
  };

  useEffect(() => {
    if (data?.concessionaireStyle?.primaryColor) {
      setApplicationColorsAndFavicon({
        primaryColor: data?.concessionaireStyle.primaryColor,
      });
      isStylesConfigurationLoading(false);
    }
  }, [data]);

  return null;
};

export default Styles;
